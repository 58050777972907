<template>
    <div class="ehd-page">
        <template v-if="isLoggedIn == true">
            <template v-if="Object.keys(EHDDetails).length > 0">
                <div class="ehd-message">
                    <p>
                        Hey {{ userData.firstname }}, now that we know what you
                        like, we have some ideas for you. Not to forget,
                        {{ EHDDetails.designer }} is one of our star interior
                        designers specialising in
                        {{ EHDDetails.theme }} Designs.
                    </p>

                    <!-- <button class="download-button" @click="openPDFView">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14.325"
                            height="13.665"
                            viewBox="0 0 14.325 13.665"
                        >
                            <path
                                data-name="Path 2443"
                                d="M72.354 9.985a.471.471 0 0 1 .471.471v1.7a2.733 2.733 0 0 1-2.733 2.733h-8.859a2.733 2.733 0 0 1-2.733-2.737v-1.7a.471.471 0 0 1 .942 0v1.7a1.791 1.791 0 0 0 1.791 1.791h8.859a1.791 1.791 0 0 0 1.791-1.791v-1.7a.471.471 0 0 1 .471-.471zM65.662 1.22a.471.471 0 0 1 .47.44v7.806l2.035-1.9a.471.471 0 0 1 .642 0l.024.024a.471.471 0 0 1 0 .642l-.024.024-2.824 2.636-.026.023-.007.006-.017.013-.012.008-.013.008-.012.007-.019.01h-.008l-.017.008-.012.005-.016.006h-.014L65.8 11h-.017l-.047.01h-.074a.474.474 0 0 1-.072-.005h-.022a.471.471 0 0 1-.416-.117l-2.827-2.632a.471.471 0 1 1 .643-.689l2.223 2.075V1.691a.471.471 0 0 1 .471-.471z"
                                style="fill:#333"
                                transform="translate(-58.5 -1.22)"
                            />
                        </svg>
                        Download PDF
                    </button> -->
                </div>
                <div class="ehd-fixed-details" v-if="windowWidth > 1100">
                    <div class="ehd-details">
                        <div class="details">
                            <div class="detail-heading">Project Name</div>
                            <div class="detail-subheading">
                                {{ EHDDetails.project_name }}
                            </div>
                        </div>
                        <div class="details">
                            <div class="detail-heading">Layout Type</div>
                            <div class="detail-subheading">
                                {{ EHDDetails.floor_plan }}
                                <span v-if="EHDDetails.carpet_area"
                                    >({{ EHDDetails.carpet_area }})</span
                                >
                            </div>
                        </div>
                        <div class="details">
                            <div class="detail-heading">Theme</div>
                            <div class="detail-subheading">
                                {{ EHDDetails.theme }}
                            </div>
                        </div>
                        <div class="details">
                            <div class="detail-heading">Total Cost</div>
                            <div
                                class="detail-subheading"
                                :class="requiredDomain == false ? 'blur' : ''"
                            >
                                <p v-if="$route.query.ok">
                                    ₹
                                    {{
                                        convertToIndianNumberSystem(
                                            kitchenPrice,
                                        )
                                    }}
                                </p>
                                <p v-else>
                                    ₹
                                    {{
                                        convertToIndianNumberSystem(
                                            EHDDetails.total_cost,
                                        )
                                    }}
                                </p>
                            </div>
                        </div>
                        <div class="details">
                            <div class="detail-heading">
                                Estimated Completion
                            </div>
                            <div class="detail-subheading">
                                4 Weeks
                            </div>
                        </div>
                    </div>
                    <div class="ehd-buttons">
                        <router-link
                            :to="{
                                name: 'MyDesignIdeas',
                            }"
                        >
                            <Button name="Explore designs" outline />
                        </router-link>
                        <router-link
                            :to="{
                                name: 'FinaliseAndPlaceOrderPage',
                                query: {
                                    ehd_id: EHDDetails.id,
                                    scene_id: EHDDetails.scene_data[0].id,
                                },
                            }"
                        >
                            <Button name="Buy" primary />
                        </router-link>
                    </div>
                </div>
                <div class="ehd-header" v-else>
                    <div class="ehd-details">
                        <div class="details">
                            <div class="detail-heading">Project Name</div>
                            <div class="detail-subheading">
                                {{ EHDDetails.project_name }}
                            </div>
                        </div>
                        <div class="details">
                            <div class="detail-heading">Layout Type</div>
                            <div class="detail-subheading">
                                {{ EHDDetails.floor_plan }}
                                <span v-if="EHDDetails.carpet_area"
                                    >({{ EHDDetails.carpet_area }})</span
                                >
                            </div>
                        </div>
                        <div class="details">
                            <div class="detail-heading">Theme</div>
                            <div class="detail-subheading">
                                {{ EHDDetails.theme }}
                            </div>
                        </div>
                        <div class="details">
                            <div class="detail-heading">Total Cost</div>
                            <div
                                class="detail-subheading"
                                :class="requiredDomain == false ? 'blur' : ''"
                            >
                                <p v-if="$route.query.ok">
                                    ₹
                                    {{
                                        convertToIndianNumberSystem(
                                            kitchenPrice,
                                        )
                                    }}
                                </p>
                                <p v-else>
                                    ₹
                                    {{
                                        convertToIndianNumberSystem(
                                            EHDDetails.total_cost,
                                        )
                                    }}
                                </p>
                            </div>
                        </div>
                        <div class="details">
                            <div class="detail-heading">
                                Estimated Completion
                            </div>
                            <div class="detail-subheading">
                                3-5 Weeks
                            </div>
                        </div>
                    </div>
                    <div class="ehd-actions-mobile">
                        <router-link
                            :to="{
                                name: 'MyDesignIdeas',
                            }"
                        >
                            <Button name="Explore designs" outline />
                        </router-link>
                        <router-link
                            :to="{
                                name: 'FinaliseAndPlaceOrderPage',
                                query: { ehd_id: EHDDetails.id },
                            }"
                        >
                            <Button name="Buy" primary />
                        </router-link>
                    </div>
                </div>
                <div class="ehd-body">
                    <Tabs
                        :key="EHDDetails.id"
                        :isselected="currentState"
                        @selectedTab="updateCurrentState"
                    >
                        <template>
                            <Tab
                                v-for="(scene, index) in EHDDetails.scene_data"
                                :key="`${scene.id}_${index}`"
                                :title="scene.room_type"
                                :img="
                                    scene.scene_image_data.length > 0
                                        ? scene.scene_image_data[0]
                                              .scene_image_url
                                        : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg'
                                "
                                :blur="!roomsAvailable[index].room_available"
                            >
                                <div class="scene-container">
                                    <div class="scene-body">
                                        <div
                                            class="scene-img-wrapper"
                                            v-if="windowWidth > 1100"
                                        >
                                            <img
                                                :src="
                                                    scene.scene_image_data
                                                        .length !== 0
                                                        ? scene
                                                              .scene_image_data[0]
                                                              .scene_image_url
                                                        : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg'
                                                "
                                                :alt="scene.room_type"
                                            />

                                            <div class="ehd-scene-button">
                                                <a
                                                    :href="scene.vt_link"
                                                    target="_blank"
                                                    v-if="scene.vt_link"
                                                >
                                                    <div class="virtual-button">
                                                        <span
                                                            class="button-text"
                                                            >Virtual Tour</span
                                                        >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="19.867"
                                                            height="19.867"
                                                            viewBox="0 0 19.867 19.867"
                                                        >
                                                            <g
                                                                data-name="Group 1593"
                                                            >
                                                                <g
                                                                    data-name="Group 178"
                                                                >
                                                                    <g
                                                                        data-name="Group 177"
                                                                    >
                                                                        <path
                                                                            data-name="Path 559"
                                                                            d="M9.733 0a9.733 9.733 0 1 0 9.733 9.733A9.744 9.744 0 0 0 9.733 0zm0 18.656a8.922 8.922 0 1 1 8.922-8.922 8.932 8.932 0 0 1-8.922 8.922z"
                                                                            style="fill:#ff6100;stroke:#ff6100;stroke-width:.4px"
                                                                            transform="translate(.2 .2)"
                                                                        />
                                                                        <path
                                                                            data-name="Path 560"
                                                                            d="m198.3 153.046-5.678-3.65a.406.406 0 0 0-.625.341v7.3a.405.405 0 0 0 .625.341l5.678-3.65a.405.405 0 0 0 0-.682zm-5.492 3.248v-5.814l4.522 2.907z"
                                                                            transform="translate(-184.5 -143.453)"
                                                                            style="fill:#ff6100;stroke:#ff6100;stroke-width:.4px"
                                                                        />
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </a>
                                                <template
                                                    v-if="
                                                        scene_thumbnails[index]
                                                            .length > 1
                                                    "
                                                >
                                                    <div
                                                        class="image-button"
                                                        @click.stop="
                                                            toggleSceneThumbnails
                                                        "
                                                        v-if="!showThumbnails"
                                                        tabindex="0"
                                                        @focus="
                                                            hideThumbnails(
                                                                false,
                                                            )
                                                        "
                                                        @blur="
                                                            hideThumbnails(true)
                                                        "
                                                    >
                                                        <span
                                                            class="button-text"
                                                            >More Image</span
                                                        >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="22.205"
                                                            height="19"
                                                            viewBox="0 0 22.205 19"
                                                        >
                                                            <path
                                                                data-name="Path 2444"
                                                                d="M29.964 68.874a1.426 1.426 0 0 1-1.426 1.426H13.175a1.426 1.426 0 0 1-1.426-1.426v-12.16a1.426 1.426 0 0 1 1.426-1.426h15.363a1.426 1.426 0 0 1 1.426 1.426zm-1.9-1.176-3.3-7.006-4.1 5.1-3-3.3-4 5.2zm-10.71-8.707a1.1 1.1 0 1 1-1.1-1.1 1.1 1.1 0 0 1 1.1 1.1zm14.174 8.322a1.426 1.426 0 0 0 1.426-1.426V53.726a1.426 1.426 0 0 0-1.426-1.426H16.165a1.426 1.426 0 0 0-1.426 1.426"
                                                                transform="translate(-11.249 -51.8)"
                                                                style="fill:none;stroke:#ff6100;stroke-miterlimit:10"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div
                                                        class="nocursor-view"
                                                        v-else
                                                        tabindex="0"
                                                        @focus="
                                                            hideThumbnails(
                                                                false,
                                                            )
                                                        "
                                                        @blur="
                                                            hideThumbnails(true)
                                                        "
                                                    >
                                                        <span class="more-text"
                                                            >More Image</span
                                                        >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="22.205"
                                                            height="19"
                                                            viewBox="0 0 22.205 19"
                                                        >
                                                            <path
                                                                data-name="Path 2444"
                                                                d="M29.964 68.874a1.426 1.426 0 0 1-1.426 1.426H13.175a1.426 1.426 0 0 1-1.426-1.426v-12.16a1.426 1.426 0 0 1 1.426-1.426h15.363a1.426 1.426 0 0 1 1.426 1.426zm-1.9-1.176-3.3-7.006-4.1 5.1-3-3.3-4 5.2zm-10.71-8.707a1.1 1.1 0 1 1-1.1-1.1 1.1 1.1 0 0 1 1.1 1.1zm14.174 8.322a1.426 1.426 0 0 0 1.426-1.426V53.726a1.426 1.426 0 0 0-1.426-1.426H16.165a1.426 1.426 0 0 0-1.426 1.426"
                                                                transform="translate(-11.249 -51.8)"
                                                                style="fill:none;stroke:#ff6100;stroke-miterlimit:10"
                                                            />
                                                        </svg>
                                                        <div
                                                            class="dropdown_menu"
                                                        >
                                                            <div
                                                                class="scrollable-menu"
                                                            >
                                                                <div
                                                                    v-for="(image,
                                                                    i) in scene_thumbnails[
                                                                        index
                                                                    ]"
                                                                    :key="i"
                                                                    class="thumbnail-container"
                                                                    @click="
                                                                        changeDefaultSceneImage(
                                                                            image.image,
                                                                        )
                                                                    "
                                                                >
                                                                    <div
                                                                        class="thumbnail-image"
                                                                    >
                                                                        <img
                                                                            :src="
                                                                                image.image
                                                                            "
                                                                            :class="
                                                                                default_scene_image ==
                                                                                image.image
                                                                                    ? 'activeThumbnail'
                                                                                    : ''
                                                                            "
                                                                        />
                                                                    </div>
                                                                    <p>
                                                                        {{
                                                                            image.scene_image_type
                                                                        }}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="12.906"
                                                                height="7.959"
                                                                viewBox="0 0 12.906 7.959"
                                                                class="thumbnail-arrow"
                                                                @click="
                                                                    toggleSceneThumbnails
                                                                "
                                                            >
                                                                <path
                                                                    data-name="Color"
                                                                    d="M1.514 0 0 1.516l4.919 4.937L0 11.389l1.514 1.516 6.445-6.452z"
                                                                    transform="rotate(90 6.453 6.453)"
                                                                    style="fill:#333"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                            <a
                                                class="ehd-full-screen"
                                                :href="
                                                    default_scene_image !== ''
                                                        ? default_scene_image
                                                        : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg'
                                                "
                                                target="_blank"
                                            >
                                                <svg
                                                    width="20px"
                                                    height="20px"
                                                    fill="#ff6100"
                                                    viewBox="0 0 48 48"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <rect
                                                        width="48"
                                                        height="48"
                                                        fill="white"
                                                        fill-opacity="0.01"
                                                    />
                                                    <path
                                                        d="M6 6L16 15.8995"
                                                        stroke="#ff6100"
                                                        stroke-width="4"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        d="M6 41.8995L16 32"
                                                        stroke="#ff6100"
                                                        stroke-width="4"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        d="M42.0001 41.8995L32.1006 32"
                                                        stroke="#ff6100"
                                                        stroke-width="4"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        d="M41.8995 6L32 15.8995"
                                                        stroke="#ff6100"
                                                        stroke-width="4"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        d="M33 6H42V15"
                                                        stroke="#ff6100"
                                                        stroke-width="4"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        d="M42 33V42H33"
                                                        stroke="#ff6100"
                                                        stroke-width="4"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        d="M15 42H6V33"
                                                        stroke="#ff6100"
                                                        stroke-width="4"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        d="M6 15V6H15"
                                                        stroke="#ff6100"
                                                        stroke-width="4"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>
                                            </a>
                                        </div>

                                        <div class="mobile-img-wrapper" v-else>
                                            <template
                                                v-if="
                                                    scene_thumbnails[index]
                                                        .length > 0
                                                "
                                            >
                                                <Carousel :settings="settings1">
                                                    <img
                                                        v-for="(image,
                                                        i) in scene_thumbnails[
                                                            index
                                                        ]"
                                                        :key="i"
                                                        :src="image.image"
                                                    />
                                                </Carousel>
                                            </template>
                                            <template v-else>
                                                <img
                                                    src="https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg"
                                                />
                                            </template>
                                            <a
                                                :href="scene.vt_link"
                                                target="_blank"
                                                v-if="scene.vt_link"
                                            >
                                                <div
                                                    class="virtual-tour-button"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="19.867"
                                                        height="19.867"
                                                        viewBox="0 0 19.867 19.867"
                                                    >
                                                        <g
                                                            data-name="Group 1593"
                                                        >
                                                            <g
                                                                data-name="Group 178"
                                                            >
                                                                <g
                                                                    data-name="Group 177"
                                                                >
                                                                    <path
                                                                        data-name="Path 559"
                                                                        d="M9.733 0a9.733 9.733 0 1 0 9.733 9.733A9.744 9.744 0 0 0 9.733 0zm0 18.656a8.922 8.922 0 1 1 8.922-8.922 8.932 8.932 0 0 1-8.922 8.922z"
                                                                        style="fill:#ff6100;stroke:#ff6100;stroke-width:.4px"
                                                                        transform="translate(.2 .2)"
                                                                    />
                                                                    <path
                                                                        data-name="Path 560"
                                                                        d="m198.3 153.046-5.678-3.65a.406.406 0 0 0-.625.341v7.3a.405.405 0 0 0 .625.341l5.678-3.65a.405.405 0 0 0 0-.682zm-5.492 3.248v-5.814l4.522 2.907z"
                                                                        transform="translate(-184.5 -143.453)"
                                                                        style="fill:#ff6100;stroke:#ff6100;stroke-width:.4px"
                                                                    />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="scene-details">
                                            <div class="designer">
                                                <img
                                                    :src="
                                                        EHDDetails.designer_image
                                                            ? EHDDetails.designer_image
                                                            : require('assets/images/default-designer.png')
                                                    "
                                                    :alt="EHDDetails.designer"
                                                />
                                                <div class="designer-name">
                                                    <p>
                                                        Designed by
                                                        {{
                                                            EHDDetails.designer
                                                        }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="heading">
                                                {{ scene.room_type }} |
                                                <span
                                                    :class="
                                                        requiredDomain == false
                                                            ? 'blur'
                                                            : ''
                                                    "
                                                >
                                                    ₹
                                                    {{
                                                        convertToIndianNumberSystem(
                                                            scene.price,
                                                        )
                                                    }}
                                                </span>
                                                |
                                                {{ EHDDetails.theme }}
                                            </div>
                                            <template
                                                v-if="scene.description != null"
                                            >
                                                <template
                                                    v-if="
                                                        scene.description
                                                            .length < 100
                                                    "
                                                >
                                                    <p class="description">
                                                        {{ scene.description }}
                                                    </p>
                                                </template>
                                                <template v-else>
                                                    <template
                                                        v-if="!seeMoreActivated"
                                                    >
                                                        <p class="description">
                                                            {{
                                                                scene.description.slice(
                                                                    0,
                                                                    100,
                                                                )
                                                            }}...
                                                            <span
                                                                class="more"
                                                                @click="
                                                                    activateSeeMore(
                                                                        true,
                                                                    )
                                                                "
                                                                >see more</span
                                                            >
                                                        </p>
                                                    </template>
                                                    <template
                                                        v-if="seeMoreActivated"
                                                    >
                                                        <p class="description">
                                                            {{
                                                                scene.description.slice(
                                                                    0,
                                                                    100,
                                                                )
                                                            }}...
                                                        </p>
                                                        <div
                                                            class="seemore-modal"
                                                        >
                                                            <div
                                                                class="seemore"
                                                            >
                                                                <span
                                                                    @click="
                                                                        activateSeeMore(
                                                                            false,
                                                                        )
                                                                    "
                                                                    class="close"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="25"
                                                                        height="24"
                                                                        fill="none"
                                                                        viewBox="0 0 25 24"
                                                                    >
                                                                        <path
                                                                            fill="#000"
                                                                            d="M19.275 6.41L17.865 5l-5.59 5.59L6.685 5l-1.41 1.41 5.59 5.59-5.59 5.59L6.685 19l5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <p
                                                                    class="seemore-heading"
                                                                >
                                                                    Description
                                                                </p>
                                                                <p
                                                                    class="seemore-text"
                                                                >
                                                                    {{
                                                                        scene.description
                                                                    }}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </template>
                                            </template>
                                            <div class="scene-price">
                                                <div
                                                    class="space-cost"
                                                    v-if="scene.price > 0"
                                                >
                                                    <p>Space Cost</p>
                                                    <p
                                                        :class="
                                                            requiredDomain ==
                                                            false
                                                                ? 'blur'
                                                                : ''
                                                        "
                                                    >
                                                        ₹
                                                        {{
                                                            convertToIndianNumberSystem(
                                                                scene.price,
                                                            )
                                                        }}
                                                    </p>
                                                </div>
                                                <div
                                                    class="products"
                                                    v-if="
                                                        scene.furnishing_cost >
                                                            0 ||
                                                            scene.decor_cost > 0
                                                    "
                                                >
                                                    <p>
                                                        Products
                                                        <span
                                                            >({{
                                                                scene.furnishing_products_in_scene.filter(
                                                                    value =>
                                                                        value.is_active ===
                                                                        true,
                                                                ).length +
                                                                    scene.decor_products_in_scene.filter(
                                                                        value =>
                                                                            value.is_active ===
                                                                            true,
                                                                    ).length
                                                            }})</span
                                                        >
                                                    </p>
                                                    <p
                                                        :class="
                                                            requiredDomain ==
                                                            false
                                                                ? 'blur'
                                                                : ''
                                                        "
                                                    >
                                                        ₹
                                                        {{
                                                            convertToIndianNumberSystem(
                                                                scene.furnishing_cost +
                                                                    scene.decor_cost,
                                                            )
                                                        }}
                                                    </p>
                                                </div>
                                                <div
                                                    class="services"
                                                    v-if="
                                                        scene.total_price_of_services >
                                                            0
                                                    "
                                                >
                                                    <p>
                                                        Services
                                                        <span
                                                            >({{
                                                                scene.services_data.filter(
                                                                    value =>
                                                                        value.active ===
                                                                        true,
                                                                ).length
                                                            }})</span
                                                        >
                                                    </p>
                                                    <p
                                                        :class="
                                                            requiredDomain ==
                                                            false
                                                                ? 'blur'
                                                                : ''
                                                        "
                                                    >
                                                        ₹
                                                        {{
                                                            convertToIndianNumberSystem(
                                                                scene.total_price_of_services,
                                                            )
                                                        }}
                                                    </p>
                                                </div>
                                                <div
                                                    class="products"
                                                    v-if="
                                                        scene.custom_product_cost >
                                                            0
                                                    "
                                                >
                                                    <p>
                                                        Custom Products
                                                        <span
                                                            >({{
                                                                scene.one_time_custom_product.filter(
                                                                    value =>
                                                                        value.active ===
                                                                        true,
                                                                ).length
                                                            }})</span
                                                        >
                                                    </p>
                                                    <p
                                                        :class="
                                                            requiredDomain ==
                                                            false
                                                                ? 'blur'
                                                                : ''
                                                        "
                                                    >
                                                        ₹
                                                        {{
                                                            convertToIndianNumberSystem(
                                                                scene.custom_product_cost,
                                                            )
                                                        }}
                                                    </p>
                                                </div>
                                                <div
                                                    class="unavailable"
                                                    v-if="
                                                        scene.unavailable_products_count >
                                                            0
                                                    "
                                                >
                                                    <p>
                                                        Prod Unavailable ({{
                                                            scene.unavailable_products_count
                                                        }})
                                                    </p>
                                                    <p
                                                        :class="
                                                            requiredDomain ==
                                                            false
                                                                ? 'blur'
                                                                : ''
                                                        "
                                                    >
                                                        -₹
                                                        {{
                                                            convertToIndianNumberSystem(
                                                                scene.unavailable_products_cost,
                                                            )
                                                        }}
                                                    </p>
                                                </div>
                                            </div>
                                            <Button
                                                v-if="
                                                    scene.scene_type == 'WHITE'
                                                "
                                                name="Customise"
                                                primary
                                                class="customisation-button"
                                                :data-scene-id="scene.id"
                                                @click.native="
                                                    checkDraft({
                                                        ehd_id: EHDDetails.id,
                                                        scene_id: scene.id,
                                                        operation: 'customise',
                                                        scene: scene.room_type,
                                                        scene_img:
                                                            scene
                                                                .scene_image_data[0]
                                                                .scene_image_url !=
                                                            null
                                                                ? scene
                                                                      .scene_image_data[0]
                                                                      .scene_image_url
                                                                : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg',
                                                    })
                                                "
                                            />
                                        </div>
                                    </div>
                                    <Tabs
                                        :isselected="currentTab"
                                        @selectedTab="updateCurrentTab"
                                    >
                                        <Tab
                                            :title="
                                                `Products (${scene.furnishing_products_in_scene.filter(
                                                    value =>
                                                        value.is_active ===
                                                        true,
                                                ).length +
                                                    scene.decor_products_in_scene.filter(
                                                        value =>
                                                            value.is_active ===
                                                            true,
                                                    ).length})`
                                            "
                                        >
                                            <div class="add-more-products">
                                                <Button
                                                    name="Add More Products"
                                                    primary
                                                    class="add-products-button"
                                                    :data-scene-id="scene.id"
                                                    @click.native="
                                                        checkDraft({
                                                            ehd_id:
                                                                EHDDetails.id,
                                                            scene_id: scene.id,
                                                            operation: 'add',
                                                            scene_type:
                                                                scene.scene_type,
                                                        })
                                                    "
                                                />
                                            </div>
                                            <div
                                                class="scene-products"
                                                v-if="
                                                    scene
                                                        .furnishing_products_in_scene
                                                        .length > 0 ||
                                                        scene
                                                            .decor_products_in_scene
                                                            .length > 0
                                                "
                                            >
                                                <div class="products-grid">
                                                    <div
                                                        v-for="(product,
                                                        indx) in totalProducts[
                                                            index
                                                        ].active"
                                                        :key="indx"
                                                        class="product-in-grid"
                                                    >
                                                        <NewCard
                                                            v-if="
                                                                product.available ==
                                                                    false
                                                            "
                                                            :id="product.id"
                                                            :img="
                                                                product.image_url
                                                                    ? product.image_url
                                                                    : require('@/assets/images/default-product-img.png')
                                                            "
                                                            unavailable
                                                            :vendor="
                                                                product.vendor
                                                            "
                                                            :cardname="
                                                                product.name
                                                            "
                                                            :price="
                                                                product.item_price
                                                            "
                                                            :requiredDomain="
                                                                requiredDomain
                                                            "
                                                        />

                                                        <NewCard
                                                            v-else
                                                            :id="product.id"
                                                            :img="
                                                                product.image_url
                                                                    ? product.image_url
                                                                    : require('@/assets/images/default-product-img.png')
                                                            "
                                                            available
                                                            :threeDavailable="
                                                                product.is_3d_available
                                                            "
                                                            :vendor="
                                                                product.vendor
                                                            "
                                                            :cardname="
                                                                product.name
                                                            "
                                                            :price="
                                                                product.item_price
                                                            "
                                                            :quantity="
                                                                product.active_quantity
                                                            "
                                                            :requiredDomain="
                                                                requiredDomain
                                                            "
                                                        />
                                                        <div class="dropdown">
                                                            <router-link
                                                                :to="{
                                                                    name:
                                                                        'ProductDetailsPage',
                                                                    params: {
                                                                        id:
                                                                            product.id,
                                                                    },
                                                                }"
                                                                target="_blank"
                                                            >
                                                                <div
                                                                    class="product-name"
                                                                >
                                                                    <p
                                                                        class="product-name-text"
                                                                    >
                                                                        {{
                                                                            product.name.slice(
                                                                                0,
                                                                                22,
                                                                            )
                                                                        }}
                                                                        <span
                                                                            v-if="
                                                                                product
                                                                                    .name
                                                                                    .length >
                                                                                    22
                                                                            "
                                                                        >
                                                                            ...
                                                                        </span>
                                                                    </p>
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="13"
                                                                        height="13"
                                                                        viewBox="0 0 13 13"
                                                                        class="info-icon"
                                                                    >
                                                                        <g
                                                                            id="Group_1750"
                                                                            data-name="Group 1750"
                                                                            transform="translate(-827 -350.9)"
                                                                        >
                                                                            <g
                                                                                id="Ellipse_546"
                                                                                data-name="Ellipse 546"
                                                                                class="cls-1"
                                                                                transform="translate(827 350.9)"
                                                                            >
                                                                                <circle
                                                                                    class="cls-3"
                                                                                    cx="6.5"
                                                                                    cy="6.5"
                                                                                    r="6.5"
                                                                                />
                                                                                <circle
                                                                                    class="cls-4"
                                                                                    cx="6.5"
                                                                                    cy="6.5"
                                                                                    r="6.05"
                                                                                />
                                                                            </g>
                                                                            <g
                                                                                id="noun-info-2096291"
                                                                                transform="translate(832.019 354.103)"
                                                                            >
                                                                                <path
                                                                                    id="Path_2036"
                                                                                    data-name="Path 2036"
                                                                                    class="cls-2"
                                                                                    d="M227.816,3.146l-.1.067h0a.182.182,0,0,0-.051.252l.012.019c.039.059.118.067.172.081a.566.566,0,0,1,.134.023.357.357,0,0,1,.186.148.437.437,0,0,1,.007.323c-.143.586-.289,1.172-.429,1.76a.633.633,0,0,0,.668.84,2.683,2.683,0,0,0,1.531-.5.375.375,0,0,0,.084-.08c.091-.12.056-.211-.09-.244-.043-.01-.087-.017-.129-.029-.279-.075-.351-.2-.283-.478.145-.591.293-1.182.435-1.773a.616.616,0,0,0-.448-.8h0a1.323,1.323,0,0,0-.3-.032,2.834,2.834,0,0,0-.373.04c-.079.015-.152.031-.218.049a3.288,3.288,0,0,0-.378.127c-.075.03-.17.071-.277.124ZM229.561-.02a.935.935,0,0,0-.956.944.954.954,0,1,0,.957-.944Z"
                                                                                    transform="translate(-227.634 0.02)"
                                                                                />
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </div>
                                                            </router-link>
                                                            <p
                                                                class="product-price"
                                                                :class="
                                                                    requiredDomain ==
                                                                    false
                                                                        ? 'blur'
                                                                        : ''
                                                                "
                                                            >
                                                                ₹
                                                                {{
                                                                    convertToIndianNumberSystem(
                                                                        product.item_price,
                                                                    )
                                                                }}
                                                            </p>

                                                            <div
                                                                class="actions"
                                                            >
                                                                <div
                                                                    class="number-of-products"
                                                                >
                                                                    <div
                                                                        class="remove-one"
                                                                        v-if="
                                                                            product.active_quantity !=
                                                                                0
                                                                        "
                                                                        @click="
                                                                            checkDraft(
                                                                                {
                                                                                    product: product,
                                                                                    scene_id:
                                                                                        scene.id,
                                                                                    operation:
                                                                                        'count',
                                                                                    enable: false,
                                                                                    ehd_id:
                                                                                        EHDDetails.id,
                                                                                },
                                                                            )
                                                                        "
                                                                    >
                                                                        <svg
                                                                            viewbox="0 0 10 10"
                                                                        >
                                                                            <line
                                                                                x1="6"
                                                                                y1="11.5"
                                                                                x2="16"
                                                                                y2="11.5"
                                                                                fill="none"
                                                                                stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                vector-effect="non-scaling-stroke"
                                                                            />
                                                                        </svg>
                                                                    </div>
                                                                    <div
                                                                        v-else
                                                                        class="disable-icon"
                                                                    >
                                                                        <svg
                                                                            viewbox="0 0 10 10"
                                                                        >
                                                                            <line
                                                                                x1="6"
                                                                                y1="11.5"
                                                                                x2="16"
                                                                                y2="11.5"
                                                                                fill="none"
                                                                                stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                vector-effect="non-scaling-stroke"
                                                                            />
                                                                        </svg>
                                                                    </div>

                                                                    <div
                                                                        class="total"
                                                                    >
                                                                        {{
                                                                            product.active_quantity
                                                                        }}
                                                                    </div>

                                                                    <div
                                                                        class="add-one"
                                                                        v-if="
                                                                            product.active_quantity !=
                                                                                product.total_quantity
                                                                        "
                                                                        @click="
                                                                            checkDraft(
                                                                                {
                                                                                    product: product,
                                                                                    scene_id:
                                                                                        scene.id,
                                                                                    operation:
                                                                                        'count',
                                                                                    enable: true,
                                                                                    ehd_id:
                                                                                        EHDDetails.id,
                                                                                },
                                                                            )
                                                                        "
                                                                    >
                                                                        <svg
                                                                            viewbox="0 0 10 10"
                                                                        >
                                                                            <line
                                                                                x1="6"
                                                                                y1="11.5"
                                                                                x2="15"
                                                                                y2="11.5"
                                                                                fill="none"
                                                                                stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                vector-effect="non-scaling-stroke"
                                                                            />
                                                                            <line
                                                                                y1="7"
                                                                                x1="10.5"
                                                                                y2="16"
                                                                                x2="10.5"
                                                                                fill="none"
                                                                                stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                vector-effect="non-scaling-stroke"
                                                                            />
                                                                        </svg>
                                                                    </div>
                                                                    <div
                                                                        v-else
                                                                        class="disable-icon"
                                                                    >
                                                                        <svg
                                                                            viewbox="0 0 10 10"
                                                                        >
                                                                            <line
                                                                                x1="6"
                                                                                y1="11.5"
                                                                                x2="15"
                                                                                y2="11.5"
                                                                                fill="none"
                                                                                stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                vector-effect="non-scaling-stroke"
                                                                            />
                                                                            <line
                                                                                y1="7"
                                                                                x1="10.5"
                                                                                y2="16"
                                                                                x2="10.5"
                                                                                fill="none"
                                                                                stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                vector-effect="non-scaling-stroke"
                                                                            />
                                                                        </svg>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    class="change-remove"
                                                                >
                                                                    <div
                                                                        class="change"
                                                                        @click="
                                                                            checkDraft(
                                                                                {
                                                                                    product: product,
                                                                                    quantity:
                                                                                        product.total_quantity,
                                                                                    scene_id:
                                                                                        scene.id,
                                                                                    index: index,
                                                                                    operation:
                                                                                        'change',
                                                                                    ehd_id:
                                                                                        EHDDetails.id,
                                                                                },
                                                                            )
                                                                        "
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="20.027"
                                                                            height="14.375"
                                                                            class="change-icon"
                                                                        >
                                                                            <g
                                                                                id="noun_change_435217"
                                                                                transform="translate(-5 -17.7)"
                                                                            >
                                                                                <path
                                                                                    id="Path_846"
                                                                                    data-name="Path 846"
                                                                                    class="cls-1"
                                                                                    d="M42.453 20.148V17.7L36.4 21.772l6.053 4.072V23.2a22.127 22.127 0 0 1 2.715.134c3.382.4 4.273 2.515 4.273 2.515 0-5.163-6.987-5.7-6.987-5.7"
                                                                                    transform="translate(-24.413)"
                                                                                />
                                                                                <path
                                                                                    id="Path_847"
                                                                                    data-name="Path 847"
                                                                                    class="cls-1"
                                                                                    d="M11.987 48.348a22.126 22.126 0 0 1-2.715-.134C5.89 47.814 5 45.7 5 45.7c0 5.163 6.987 5.7 6.987 5.7v2.448l6.053-4.072-6.053-4.076z"
                                                                                    transform="translate(0 -21.769)"
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                        <p>
                                                                            Change
                                                                        </p>
                                                                    </div>

                                                                    <div
                                                                        class="remove"
                                                                        @click="
                                                                            checkDraft(
                                                                                {
                                                                                    product: product,
                                                                                    scene_id:
                                                                                        scene.id,
                                                                                    operation:
                                                                                        'toggle',
                                                                                    enable: false,
                                                                                    ehd_id:
                                                                                        EHDDetails.id,
                                                                                },
                                                                            )
                                                                        "
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="11.326"
                                                                            height="14.375"
                                                                            class="dustbin-icon"
                                                                        >
                                                                            <g
                                                                                id="noun_dustbin_3666639"
                                                                                transform="translate(-14 -7)"
                                                                            >
                                                                                <g
                                                                                    id="Group_493"
                                                                                    data-name="Group 493"
                                                                                    transform="translate(14 7)"
                                                                                >
                                                                                    <path
                                                                                        id="Path_844"
                                                                                        data-name="Path 844"
                                                                                        class="cls-1"
                                                                                        d="m17.35 21 1.2 10.363a1.089 1.089 0 0 0 1.082.963h5.298a1.089 1.089 0 0 0 1.082-.963L27.216 21zm3.191 9.339a.106.106 0 0 1-.026 0 .219.219 0 0 1-.216-.194l-.873-7.793a.218.218 0 0 1 .433-.048l.873 7.793a.217.217 0 0 1-.191.242zm1.96-.316a.218.218 0 1 1-.436 0v-7.84a.218.218 0 0 1 .436 0zm1.766.124a.219.219 0 0 1-.216.194.106.106 0 0 1-.026 0 .217.217 0 0 1-.192-.24l.873-7.793a.218.218 0 0 1 .433.048z"
                                                                                        transform="translate(-16.62 -17.951)"
                                                                                    />
                                                                                    <path
                                                                                        id="Path_845"
                                                                                        data-name="Path 845"
                                                                                        class="cls-1"
                                                                                        d="M23.8 7.871h-2.2A1.089 1.089 0 0 0 20.534 7h-1.742a1.093 1.093 0 0 0-1.067.871h-2.2A1.526 1.526 0 0 0 14 9.4a.218.218 0 0 0 .218.218h10.89a.218.218 0 0 0 .218-.218A1.526 1.526 0 0 0 23.8 7.871zm-5.009-.436h1.742a.656.656 0 0 1 .616.436h-2.974a.656.656 0 0 1 .617-.435z"
                                                                                        transform="translate(-14 -7)"
                                                                                    />
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                        <p>
                                                                            Remove
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p
                                                    class="productState"
                                                    v-if="
                                                        totalProducts[index]
                                                            .removed.length != 0
                                                    "
                                                >
                                                    Unused Products
                                                </p>
                                                <div class="products-grid">
                                                    <div
                                                        v-for="(product,
                                                        index) in totalProducts[
                                                            index
                                                        ].removed"
                                                        :key="index"
                                                        class="product-in-grid"
                                                    >
                                                        <NewCard
                                                            v-if="
                                                                product.available ==
                                                                    false
                                                            "
                                                            :id="product.id"
                                                            :img="
                                                                product.image_url
                                                                    ? product.image_url
                                                                    : require('@/assets/images/default-product-img.png')
                                                            "
                                                            :notActive="true"
                                                            :threeDavailable="
                                                                product.is_3d_available
                                                            "
                                                            :vendor="
                                                                product.vendor
                                                            "
                                                            unavailable
                                                            :cardname="
                                                                product.name
                                                            "
                                                            :quantity="
                                                                product.active_quantity
                                                            "
                                                            :price="
                                                                product.item_price
                                                            "
                                                            :requiredDomain="
                                                                requiredDomain
                                                            "
                                                        />
                                                        <NewCard
                                                            v-else
                                                            :id="product.id"
                                                            :img="
                                                                product.image_url
                                                                    ? product.image_url
                                                                    : require('@/assets/images/default-product-img.png')
                                                            "
                                                            :notActive="true"
                                                            :threeDavailable="
                                                                product.is_3d_available
                                                            "
                                                            :vendor="
                                                                product.vendor
                                                            "
                                                            available
                                                            :cardname="
                                                                product.name
                                                            "
                                                            :quantity="
                                                                product.active_quantity
                                                            "
                                                            :price="
                                                                product.item_price
                                                            "
                                                            :requiredDomain="
                                                                requiredDomain
                                                            "
                                                        />
                                                        <div class="dropdown">
                                                            <router-link
                                                                :to="{
                                                                    name:
                                                                        'ProductDetailsPage',
                                                                    params: {
                                                                        id:
                                                                            product.id,
                                                                    },
                                                                }"
                                                                target="_blank"
                                                            >
                                                                <p
                                                                    class="product-name"
                                                                >
                                                                    {{
                                                                        product.name.slice(
                                                                            0,
                                                                            25,
                                                                        )
                                                                    }}
                                                                    <span
                                                                        v-if="
                                                                            product
                                                                                .name
                                                                                .length >
                                                                                25
                                                                        "
                                                                        >...</span
                                                                    >
                                                                </p>
                                                            </router-link>
                                                            <p
                                                                class="product-price"
                                                                :class="
                                                                    requiredDomain ==
                                                                    false
                                                                        ? 'blur'
                                                                        : ''
                                                                "
                                                            >
                                                                ₹
                                                                {{
                                                                    convertToIndianNumberSystem(
                                                                        product.item_price,
                                                                    )
                                                                }}
                                                            </p>
                                                            <div
                                                                class="actions"
                                                            >
                                                                <div
                                                                    class="remove"
                                                                    @click="
                                                                        checkDraft(
                                                                            {
                                                                                product: product,
                                                                                scene_id:
                                                                                    scene.id,
                                                                                operation:
                                                                                    'toggle',
                                                                                enable: true,
                                                                                ehd_id:
                                                                                    EHDDetails.id,
                                                                            },
                                                                        )
                                                                    "
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="13.846"
                                                                        height="12"
                                                                        class="restore-icon"
                                                                    >
                                                                        <g
                                                                            data-name="Group 733"
                                                                        >
                                                                            <path
                                                                                data-name="Path 1023"
                                                                                d="M10.9 11A5.949 5.949 0 0 0 5 17a6.037 6.037 0 0 0 6 6c.012 0 .007-2.309 0-2.308A3.715 3.715 0 0 1 7.051 17a3.88 3.88 0 0 1 3.849-3.913 3.816 3.816 0 0 1 3.767 3.452h-2.282l3.231 3.231 3.231-3.231h-2.075A5.936 5.936 0 0 0 10.9 11z"
                                                                                transform="translate(-5 -11)"
                                                                            />
                                                                        </g>
                                                                    </svg>

                                                                    <p>
                                                                        Restore
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                v-else
                                                class="no-data-available"
                                            >
                                                No products available.
                                            </div>
                                        </Tab>
                                        <Tab
                                            :title="
                                                `Services (${
                                                    scene.services_data.filter(
                                                        value =>
                                                            value.active ===
                                                            true,
                                                    ).length
                                                })`
                                            "
                                        >
                                            <div
                                                class="scene-services"
                                                v-if="
                                                    scene.services_data.length >
                                                        0
                                                "
                                            >
                                                <div class="services-grid">
                                                    <div
                                                        v-for="(service,
                                                        index) in totalServices[
                                                            index
                                                        ].active"
                                                        :key="
                                                            index +
                                                                '_' +
                                                                service.id
                                                        "
                                                        class="services-in-grid"
                                                    >
                                                        <NewCard
                                                            :img="
                                                                service.service_icon_url
                                                                    ? service.service_icon_url
                                                                    : require('@/assets/images/enable_service.png')
                                                            "
                                                            :description="
                                                                service.description
                                                            "
                                                            :forServices="true"
                                                            :cardname="
                                                                service.name
                                                            "
                                                            :price="
                                                                service.price
                                                            "
                                                            :requiredDomain="
                                                                requiredDomain
                                                            "
                                                        />
                                                        <div
                                                            class="dropdown-services"
                                                        >
                                                            <p
                                                                class="service-name"
                                                            >
                                                                {{
                                                                    service.name.slice(
                                                                        0,
                                                                        25,
                                                                    )
                                                                }}
                                                                <span
                                                                    v-if="
                                                                        service
                                                                            .name
                                                                            .length >
                                                                            25
                                                                    "
                                                                    >...</span
                                                                >
                                                            </p>
                                                            <p
                                                                class="service-vendor"
                                                            >
                                                                {{
                                                                    service.vendor
                                                                }}
                                                            </p>
                                                            <p
                                                                class="service-vendor"
                                                                v-if="
                                                                    windowWidth >
                                                                        1100
                                                                "
                                                            >
                                                                {{
                                                                    service.description.slice(
                                                                        0,
                                                                        55,
                                                                    )
                                                                }}
                                                                <span
                                                                    v-if="
                                                                        service
                                                                            .description
                                                                            .length >
                                                                            55
                                                                    "
                                                                    >...</span
                                                                >
                                                            </p>
                                                            <p
                                                                class="service-vendor"
                                                                v-else
                                                            >
                                                                {{
                                                                    service.description.slice(
                                                                        0,
                                                                        38,
                                                                    )
                                                                }}
                                                                <span
                                                                    v-if="
                                                                        service
                                                                            .description
                                                                            .length >
                                                                            38
                                                                    "
                                                                    >...</span
                                                                >
                                                            </p>
                                                            <div
                                                                class="actions"
                                                            >
                                                                <div
                                                                    class="remove"
                                                                    @click="
                                                                        checkDraft(
                                                                            {
                                                                                operation:
                                                                                    'service',
                                                                                service_id:
                                                                                    service.id,
                                                                                scene_id:
                                                                                    scene.id,
                                                                                enable: false,
                                                                                ehd_id:
                                                                                    EHDDetails.id,
                                                                            },
                                                                        )
                                                                    "
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="11.326"
                                                                        height="14.375"
                                                                        class="dustbin-icon"
                                                                    >
                                                                        <g
                                                                            id="noun_dustbin_3666639"
                                                                            transform="translate(-14 -7)"
                                                                        >
                                                                            <g
                                                                                id="Group_493"
                                                                                data-name="Group 493"
                                                                                transform="translate(14 7)"
                                                                            >
                                                                                <path
                                                                                    id="Path_844"
                                                                                    data-name="Path 844"
                                                                                    class="cls-1"
                                                                                    d="m17.35 21 1.2 10.363a1.089 1.089 0 0 0 1.082.963h5.298a1.089 1.089 0 0 0 1.082-.963L27.216 21zm3.191 9.339a.106.106 0 0 1-.026 0 .219.219 0 0 1-.216-.194l-.873-7.793a.218.218 0 0 1 .433-.048l.873 7.793a.217.217 0 0 1-.191.242zm1.96-.316a.218.218 0 1 1-.436 0v-7.84a.218.218 0 0 1 .436 0zm1.766.124a.219.219 0 0 1-.216.194.106.106 0 0 1-.026 0 .217.217 0 0 1-.192-.24l.873-7.793a.218.218 0 0 1 .433.048z"
                                                                                    transform="translate(-16.62 -17.951)"
                                                                                />
                                                                                <path
                                                                                    id="Path_845"
                                                                                    data-name="Path 845"
                                                                                    class="cls-1"
                                                                                    d="M23.8 7.871h-2.2A1.089 1.089 0 0 0 20.534 7h-1.742a1.093 1.093 0 0 0-1.067.871h-2.2A1.526 1.526 0 0 0 14 9.4a.218.218 0 0 0 .218.218h10.89a.218.218 0 0 0 .218-.218A1.526 1.526 0 0 0 23.8 7.871zm-5.009-.436h1.742a.656.656 0 0 1 .616.436h-2.974a.656.656 0 0 1 .617-.435z"
                                                                                    transform="translate(-14 -7)"
                                                                                />
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                    <p>
                                                                        Remove
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p
                                                    class="serviceState"
                                                    v-if="
                                                        totalServices[index]
                                                            .removed.length != 0
                                                    "
                                                >
                                                    Unused Services
                                                </p>
                                                <div class="services-grid">
                                                    <div
                                                        v-for="(service,
                                                        index) in totalServices[
                                                            index
                                                        ].removed"
                                                        :key="
                                                            index +
                                                                '_' +
                                                                service.id
                                                        "
                                                        class="services-in-grid"
                                                    >
                                                        <NewCard
                                                            :img="
                                                                service.service_icon_url
                                                                    ? service.service_icon_url
                                                                    : require('@/assets/images/disable_service.png')
                                                            "
                                                            :description="
                                                                service.description
                                                            "
                                                            :forServices="true"
                                                            :notActive="true"
                                                            :cardname="
                                                                service.name
                                                            "
                                                            :price="
                                                                service.price
                                                            "
                                                            :requiredDomain="
                                                                requiredDomain
                                                            "
                                                        />
                                                        <div
                                                            class="dropdown-services"
                                                        >
                                                            <p
                                                                class="service-name"
                                                            >
                                                                {{
                                                                    service.name.slice(
                                                                        0,
                                                                        25,
                                                                    )
                                                                }}
                                                                <span
                                                                    v-if="
                                                                        service
                                                                            .name
                                                                            .length >
                                                                            25
                                                                    "
                                                                    >...</span
                                                                >
                                                            </p>
                                                            <p
                                                                class="service-vendor"
                                                            >
                                                                {{
                                                                    service.vendor
                                                                }}
                                                            </p>
                                                            <div
                                                                class="actions"
                                                            >
                                                                <div
                                                                    class="remove"
                                                                    @click="
                                                                        checkDraft(
                                                                            {
                                                                                operation:
                                                                                    'service',
                                                                                service_id:
                                                                                    service.id,
                                                                                scene_id:
                                                                                    scene.id,
                                                                                enable: true,
                                                                                ehd_id:
                                                                                    EHDDetails.id,
                                                                            },
                                                                        )
                                                                    "
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="13.846"
                                                                        height="12"
                                                                        class="restore-icon"
                                                                    >
                                                                        <g
                                                                            data-name="Group 733"
                                                                        >
                                                                            <path
                                                                                data-name="Path 1023"
                                                                                d="M10.9 11A5.949 5.949 0 0 0 5 17a6.037 6.037 0 0 0 6 6c.012 0 .007-2.309 0-2.308A3.715 3.715 0 0 1 7.051 17a3.88 3.88 0 0 1 3.849-3.913 3.816 3.816 0 0 1 3.767 3.452h-2.282l3.231 3.231 3.231-3.231h-2.075A5.936 5.936 0 0 0 10.9 11z"
                                                                                transform="translate(-5 -11)"
                                                                            />
                                                                        </g>
                                                                    </svg>

                                                                    <p>
                                                                        Restore
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                v-else
                                                class="no-data-available"
                                            >
                                                No services available.
                                            </div>
                                        </Tab>
                                        <Tab
                                            :title="
                                                `Custom Products(${
                                                    scene.one_time_custom_product.filter(
                                                        value =>
                                                            value.active ===
                                                            true,
                                                    ).length
                                                })`
                                            "
                                            v-if="
                                                scene.one_time_custom_product
                                                    .length > 0
                                            "
                                        >
                                            <div
                                                class="scene-services"
                                                v-if="
                                                    scene
                                                        .one_time_custom_product
                                                        .length > 0
                                                "
                                            >
                                                <div class="services-grid">
                                                    <div
                                                        v-for="(customProduct,
                                                        index) in totalCustomProducts[
                                                            index
                                                        ].active"
                                                        :key="
                                                            index +
                                                                '_' +
                                                                customProduct.id
                                                        "
                                                        class="services-in-grid"
                                                    >
                                                        <NewCard
                                                            :img="
                                                                customProduct.img_url
                                                                    ? customProduct.img_url
                                                                    : require('@/assets/images/custom-product.png')
                                                            "
                                                            :description="
                                                                customProduct.description
                                                            "
                                                            :forServices="true"
                                                            :cardname="
                                                                customProduct.name
                                                            "
                                                            :price="
                                                                customProduct.price
                                                            "
                                                            :requiredDomain="
                                                                requiredDomain
                                                            "
                                                        />
                                                        <div
                                                            class="dropdown-services"
                                                        >
                                                            <p
                                                                class="service-name"
                                                            >
                                                                {{
                                                                    customProduct.name.slice(
                                                                        0,
                                                                        25,
                                                                    )
                                                                }}
                                                                <span
                                                                    v-if="
                                                                        customProduct
                                                                            .name
                                                                            .length >
                                                                            25
                                                                    "
                                                                    >...</span
                                                                >
                                                            </p>
                                                            <p
                                                                class="service-vendor"
                                                            >
                                                                {{
                                                                    customProduct.vendor
                                                                }}
                                                            </p>
                                                            <p
                                                                class="service-vendor"
                                                                v-if="
                                                                    windowWidth >
                                                                        1100
                                                                "
                                                            >
                                                                {{
                                                                    customProduct.description.slice(
                                                                        0,
                                                                        55,
                                                                    )
                                                                }}
                                                                <span
                                                                    v-if="
                                                                        customProduct
                                                                            .description
                                                                            .length >
                                                                            55
                                                                    "
                                                                    >...</span
                                                                >
                                                            </p>
                                                            <p
                                                                class="service-vendor"
                                                                v-else
                                                            >
                                                                {{
                                                                    customProduct.description.slice(
                                                                        0,
                                                                        38,
                                                                    )
                                                                }}
                                                                <span
                                                                    v-if="
                                                                        customProduct
                                                                            .description
                                                                            .length >
                                                                            38
                                                                    "
                                                                    >...</span
                                                                >
                                                            </p>
                                                            <div
                                                                class="actions"
                                                            >
                                                                <div
                                                                    class="remove"
                                                                    @click="
                                                                        checkDraft(
                                                                            {
                                                                                operation:
                                                                                    'customProduct',
                                                                                custom_product_id:
                                                                                    customProduct.prod_id,
                                                                                scene_id:
                                                                                    scene.id,
                                                                                enable: false,
                                                                                ehd_id:
                                                                                    EHDDetails.id,
                                                                            },
                                                                        )
                                                                    "
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="11.326"
                                                                        height="14.375"
                                                                        class="dustbin-icon"
                                                                    >
                                                                        <g
                                                                            id="noun_dustbin_3666639"
                                                                            transform="translate(-14 -7)"
                                                                        >
                                                                            <g
                                                                                id="Group_493"
                                                                                data-name="Group 493"
                                                                                transform="translate(14 7)"
                                                                            >
                                                                                <path
                                                                                    id="Path_844"
                                                                                    data-name="Path 844"
                                                                                    class="cls-1"
                                                                                    d="m17.35 21 1.2 10.363a1.089 1.089 0 0 0 1.082.963h5.298a1.089 1.089 0 0 0 1.082-.963L27.216 21zm3.191 9.339a.106.106 0 0 1-.026 0 .219.219 0 0 1-.216-.194l-.873-7.793a.218.218 0 0 1 .433-.048l.873 7.793a.217.217 0 0 1-.191.242zm1.96-.316a.218.218 0 1 1-.436 0v-7.84a.218.218 0 0 1 .436 0zm1.766.124a.219.219 0 0 1-.216.194.106.106 0 0 1-.026 0 .217.217 0 0 1-.192-.24l.873-7.793a.218.218 0 0 1 .433.048z"
                                                                                    transform="translate(-16.62 -17.951)"
                                                                                />
                                                                                <path
                                                                                    id="Path_845"
                                                                                    data-name="Path 845"
                                                                                    class="cls-1"
                                                                                    d="M23.8 7.871h-2.2A1.089 1.089 0 0 0 20.534 7h-1.742a1.093 1.093 0 0 0-1.067.871h-2.2A1.526 1.526 0 0 0 14 9.4a.218.218 0 0 0 .218.218h10.89a.218.218 0 0 0 .218-.218A1.526 1.526 0 0 0 23.8 7.871zm-5.009-.436h1.742a.656.656 0 0 1 .616.436h-2.974a.656.656 0 0 1 .617-.435z"
                                                                                    transform="translate(-14 -7)"
                                                                                />
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                    <p>
                                                                        Remove
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p
                                                    class="serviceState"
                                                    v-if="
                                                        totalCustomProducts[
                                                            index
                                                        ].removed.length != 0
                                                    "
                                                >
                                                    Unused Custom Products
                                                </p>
                                                <div class="services-grid">
                                                    <div
                                                        v-for="(customProduct,
                                                        index) in totalCustomProducts[
                                                            index
                                                        ].removed"
                                                        :key="
                                                            index +
                                                                '_' +
                                                                customProduct.id
                                                        "
                                                        class="services-in-grid"
                                                    >
                                                        <NewCard
                                                            :img="
                                                                customProduct.img_url
                                                                    ? customProduct.img_url
                                                                    : require('@/assets/images/custom-product.png')
                                                            "
                                                            :description="
                                                                customProduct.description
                                                            "
                                                            :forServices="true"
                                                            :notActive="true"
                                                            :cardname="
                                                                customProduct.name
                                                            "
                                                            :price="
                                                                customProduct.price
                                                            "
                                                            :requiredDomain="
                                                                requiredDomain
                                                            "
                                                        />
                                                        <div
                                                            class="dropdown-services"
                                                        >
                                                            <p
                                                                class="service-name"
                                                            >
                                                                {{
                                                                    customProduct.name.slice(
                                                                        0,
                                                                        25,
                                                                    )
                                                                }}
                                                                <span
                                                                    v-if="
                                                                        customProduct
                                                                            .name
                                                                            .length >
                                                                            25
                                                                    "
                                                                    >...</span
                                                                >
                                                            </p>
                                                            <p
                                                                class="service-vendor"
                                                            >
                                                                {{
                                                                    customProduct.vendor
                                                                }}
                                                            </p>
                                                            <div
                                                                class="actions"
                                                            >
                                                                <div
                                                                    class="remove"
                                                                    @click="
                                                                        checkDraft(
                                                                            {
                                                                                operation:
                                                                                    'customProduct',
                                                                                custom_product_id:
                                                                                    customProduct.prod_id,
                                                                                scene_id:
                                                                                    scene.id,
                                                                                enable: true,
                                                                                ehd_id:
                                                                                    EHDDetails.id,
                                                                            },
                                                                        )
                                                                    "
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="13.846"
                                                                        height="12"
                                                                        class="restore-icon"
                                                                    >
                                                                        <g
                                                                            data-name="Group 733"
                                                                        >
                                                                            <path
                                                                                data-name="Path 1023"
                                                                                d="M10.9 11A5.949 5.949 0 0 0 5 17a6.037 6.037 0 0 0 6 6c.012 0 .007-2.309 0-2.308A3.715 3.715 0 0 1 7.051 17a3.88 3.88 0 0 1 3.849-3.913 3.816 3.816 0 0 1 3.767 3.452h-2.282l3.231 3.231 3.231-3.231h-2.075A5.936 5.936 0 0 0 10.9 11z"
                                                                                transform="translate(-5 -11)"
                                                                            />
                                                                        </g>
                                                                    </svg>

                                                                    <p>
                                                                        Restore
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                v-else
                                                class="no-data-available"
                                            >
                                                No custom products available.
                                            </div>
                                        </Tab>

                                        <Tab
                                            :title="`Info `"
                                            v-if="
                                                scene.scene_info != '' &&
                                                    scene.scene_info != null
                                            "
                                        >
                                            <div class="info-tab">
                                                <div
                                                    v-html="scene.scene_info"
                                                ></div>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                    <div
                                        v-if="
                                            roomsAvailable[index].scene ==
                                                scene.id &&
                                                roomsAvailable[index]
                                                    .room_available == false
                                        "
                                        class="room-unavailable"
                                    >
                                        <div class="explore-designs-banner">
                                            <p>Loved Siloho kitchen?</p>
                                            <p>Furnish rest of the house.</p>
                                            <Button
                                                class="add-rooms-btn"
                                                name="Add All Rooms"
                                                primary
                                                @click.native="
                                                    addAllRooms(EHDDetails.id)
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        </template>
                    </Tabs>
                </div>
            </template>

            <template v-if="showNotFound == true">
                <NotFound :data="'EHD'" :content="content" />
            </template>
            <FullPageNavModal
                v-if="showModal"
                @close="toggleFullPageModal"
                :sceneId="currentSceneId"
                :ehdId="ehdId"
                :requiredDomain="requiredDomain"
                @update="updateEHD"
            >
            </FullPageNavModal>

            <!-- <ReplaceProductModal
                v-if="replaceProductModal"
                :currentProductID="productID"
                :currentProductImage="
                    productImage
                        ? productImage
                        : require('@/assets/images/default-product-img.png')
                "
                :currentProductName="productName"
                :requiredDomain="requiredDomain"
                :currentProductPrice="productPrice"
                :currentProductShow="currentProductShow"
                :currentProductDescription="productDescription"
                :currentSceneId="currentSceneId"
                :add="add"
                @setLoader="setLoader"
                @toggleReplaceProductModal="toggleReplaceProductModal"
                @changeProductForEHD="changeProductForEHD"
                @addProductForEHD="addProductForEHD"
            /> -->
            <FullPageModal
                v-if="replaceProductModal == true"
                @close="toggleReplaceProductModal"
                @loadMoreProducts="loadMoreProducts"
                :replaceProduct="true"
                class="replace-product-modal"
            >
                <div class="productlist-layout">
                    <div class="sidebar">
                        <div class="sidebar-item">
                            <div
                                class="current-product"
                                v-if="currentProductShow == true"
                            >
                                <router-link
                                    :to="{
                                        name: 'ProductDetailsPage',
                                        params: {
                                            id: productID,
                                        },
                                    }"
                                >
                                    <CurrentProduct
                                        :img="
                                            productImage
                                                ? productImage
                                                : require('@/assets/images/default-product-img.png')
                                        "
                                        :productName="productName"
                                        :requiredDomain="requiredDomain"
                                        :productPrice="productPrice"
                                        :description="productDescription"
                                    />
                                </router-link>
                            </div>

                            <div
                                class="filters"
                                v-if="
                                    add == false &&
                                        filters[1].options.length != 0
                                "
                            >
                                <FiltersBox
                                    :filters="filters"
                                    @filter="getFilteredProduct"
                                    v-if="windowWidth > 1100"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="main">
                        <template v-if="relatedProducts">
                            <div class="productlist-head">
                                <h1 class="">
                                    {{
                                        add == true
                                            ? 'Add product'
                                            : 'Replace with'
                                    }}
                                </h1>

                                <SearchAutoComplete
                                    @search="fetchSuggestions"
                                    @loadMoreSearchOptions="
                                        loadMoreSearchOptions
                                    "
                                    :suggestionsArr="suggestions"
                                >
                                    <template #suggestion="{suggestion}">
                                        <div class="autocomplete-product">
                                            <div
                                                class="autocomplete-product-img"
                                            >
                                                <img :src="suggestion.image" />
                                            </div>
                                            <div
                                                class="autocomplete-product-detail"
                                            >
                                                <p>{{ suggestion.name }}</p>
                                                <div
                                                    class="autocomplete-category"
                                                >
                                                    <span>
                                                        {{
                                                            suggestion.item_category
                                                        }}
                                                    </span>
                                                    <template
                                                        v-if="
                                                            suggestion.item_subcategory
                                                        "
                                                    >
                                                        <span>
                                                            <svg
                                                                width="25"
                                                                height="24"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M10.283 6 8.875 7.41 13.449 12l-4.574 4.59L10.283 18l5.992-6-5.992-6z"
                                                                    fill="#000"
                                                                />
                                                            </svg>
                                                        </span>
                                                        <span>
                                                            {{
                                                                suggestion.item_subcategory
                                                            }}
                                                        </span>
                                                    </template>
                                                </div>
                                            </div>
                                            <div
                                                class="autocomplete-product-action"
                                            >
                                                <template v-if="add == true">
                                                    <Button
                                                        class="autocomplete-add"
                                                        name="Add"
                                                        primary
                                                        @click.native="
                                                            addProductForEHD(
                                                                suggestion,
                                                            )
                                                        "
                                                    />
                                                </template>
                                                <template v-else>
                                                    <Button
                                                        class="autocomplete-add"
                                                        name="Replace"
                                                        primary
                                                        @click.native="
                                                            changeProductForEHD(
                                                                suggestion,
                                                            )
                                                        "
                                                    />
                                                </template>
                                            </div>
                                        </div>
                                    </template>
                                </SearchAutoComplete>
                            </div>
                            <div class="cards-container">
                                <template
                                    v-if="relatedProducts.payload.prod_data"
                                >
                                    <div
                                        v-for="product in relatedProducts
                                            .payload.prod_data"
                                        :key="product.id"
                                    >
                                        <ProductCards
                                            v-if="add == true"
                                            :threeDavailable="
                                                product.is_3d_available
                                            "
                                            :product-obj="product"
                                            :vendor="product.vendor"
                                            :dimRemarks="product.dim_remarks"
                                            :product-id="product.product_id"
                                            :img="
                                                product.image_meta
                                                    ? product.image_meta.n_image
                                                    : require('@/assets/images/default-product-img.png')
                                            "
                                            :name="product.name"
                                            :current-price="product.item_price"
                                            :requiredDomain="requiredDomain"
                                            :bestSeller="product.bestseller"
                                            :recommended="product.recommended"
                                            @add="addProductForEHD(product)"
                                        />
                                        <ProductCards
                                            v-else
                                            :threeDavailable="
                                                product.is_3d_available
                                            "
                                            :product-obj="product"
                                            replace
                                            :vendor="product.vendor"
                                            :dimRemarks="product.dim_remarks"
                                            :product-id="product.product_id"
                                            :img="
                                                product.image_meta
                                                    ? product.image_meta.n_image
                                                    : require('@/assets/images/default-product-img.png')
                                            "
                                            :name="product.name"
                                            :current-price="product.item_price"
                                            :requiredDomain="requiredDomain"
                                            :bestSeller="product.bestseller"
                                            :recommended="product.recommended"
                                            @replace="
                                                changeProductForEHD(product)
                                            "
                                        />
                                    </div>
                                </template>
                                <template v-else>
                                    <div
                                        v-for="product in similarProducts"
                                        :key="product.id"
                                    >
                                        <ProductCards
                                            v-if="add == true"
                                            :threeDavailable="
                                                product.is_3d_available
                                            "
                                            :product-obj="product"
                                            :vendor="product.vendor"
                                            :product-id="product.product_id"
                                            :dimRemarks="product.dim_remarks"
                                            :img="
                                                product.image_meta
                                                    ? product.image_meta.n_image
                                                    : require('@/assets/images/default-product-img.png')
                                            "
                                            :name="product.name"
                                            :current-price="product.item_price"
                                            :requiredDomain="requiredDomain"
                                            :bestSeller="product.bestseller"
                                            :recommended="product.recommended"
                                            @add="addProductForEHD(product)"
                                        />
                                        <ProductCards
                                            v-else
                                            :threeDavailable="
                                                product.is_3d_available
                                            "
                                            :product-obj="product"
                                            replace
                                            :vendor="product.vendor"
                                            :dimRemarks="product.dim_remarks"
                                            :product-id="product.product_id"
                                            :img="
                                                product.image_meta
                                                    ? product.image_meta.n_image
                                                    : require('@/assets/images/default-product-img.png')
                                            "
                                            :name="product.name"
                                            :current-price="product.item_price"
                                            :requiredDomain="requiredDomain"
                                            :bestSeller="product.bestseller"
                                            :recommended="product.recommended"
                                            @replace="
                                                changeProductForEHD(product)
                                            "
                                        />
                                    </div>
                                </template>
                            </div>
                            <div class="loader-container"></div>
                        </template>
                        <template v-else>
                            <div
                                style="text-align: center;"
                                class="no-products-found"
                            >
                                <img
                                    src="@/assets/images/no-results-found.png"
                                />
                                <p>No Products Available</p>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="request-product-container">
                    <RequestProduct />
                </div>
            </FullPageModal>

            <!-- <Modal v-if="ehdShowModal" hideClose transparent>
                <div class="ehdmodal">
                    <div class="ehdmodal-view">
                        <p>{{ ehdModalMsg }}</p>
                    </div>
                </div>
            </Modal> -->
            <Modal v-if="draftModal" @close="closeDraftModal" transparent>
                <div class="ehdmodal">
                    <div class="ehdmodal-view">
                        <p>{{ draftModalMsg }}</p>

                        <div
                            class="ehd-display"
                            v-if="showDraftVersions == true"
                        >
                            <template v-if="useWorkspaceComp == false">
                                <div class="draft-ehd">
                                    <p>My Draft</p>
                                    <div class="draft-details">
                                        <img
                                            :src="
                                                draftDetails.draft_scene
                                                    .scene_image
                                            "
                                        />
                                        <div class="draft-info">
                                            <p
                                                :class="
                                                    requiredDomain == false
                                                        ? 'blur'
                                                        : ''
                                                "
                                            >
                                                ₹{{
                                                    convertToIndianNumberSystem(
                                                        draftDetails.draft_scene
                                                            .price,
                                                    )
                                                }}
                                            </p>
                                            <p>
                                                {{
                                                    draftDetails.draft_scene
                                                        .no_of_products
                                                }}
                                                Products
                                            </p>
                                            <p>
                                                {{
                                                    draftDetails.draft_scene
                                                        .no_of_services
                                                }}
                                                Services
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="original-ehd">
                                    <p>Original</p>
                                    <div class="draft-details">
                                        <img
                                            :src="
                                                draftDetails.original_scene
                                                    .scene_image
                                            "
                                        />
                                        <div class="draft-info">
                                            <p
                                                :class="
                                                    requiredDomain == false
                                                        ? 'blur'
                                                        : ''
                                                "
                                            >
                                                ₹{{
                                                    convertToIndianNumberSystem(
                                                        draftDetails
                                                            .original_scene
                                                            .price,
                                                    )
                                                }}
                                            </p>
                                            <p>
                                                {{
                                                    draftDetails.original_scene
                                                        .no_of_products
                                                }}
                                                Products
                                            </p>
                                            <p>
                                                {{
                                                    draftDetails.original_scene
                                                        .no_of_services
                                                }}
                                                Services
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template v-else>
                                <div>
                                    <WorkspaceComponent
                                        :draftImage="
                                            draftDetails.draft_scene.scene_image
                                        "
                                        :theme="
                                            draftDetails.draft_scene.theme_name
                                        "
                                        :ogDetails="draftDetails.original_scene"
                                        :price="draftDetails.draft_scene.price"
                                        :footerName="
                                            draftDetails.draft_scene
                                                .display_name
                                        "
                                        :noOfProducts="
                                            draftDetails.draft_scene
                                                .no_of_products
                                        "
                                        :noOfServices="
                                            draftDetails.draft_scene
                                                .no_of_services
                                        "
                                        :space="
                                            draftDetails.draft_scene.space_name
                                        "
                                        :eta="draftDetails.draft_scene.ETA"
                                        :products="
                                            draftDetails.draft_scene
                                                .no_of_products
                                        "
                                        :timeCreated="
                                            draftDetails.draft_scene.updated_at
                                        "
                                        :sceneid="
                                            draftDetails.draft_scene.scene_id
                                        "
                                        :ehdid="
                                            draftDetails.draft_scene
                                                .draft_ehd_id
                                                ? draftDetails.draft_scene
                                                      .draft_ehd_id
                                                : null
                                        "
                                        :draftBySpace="true"
                                        :hideButtons="true"
                                    />
                                </div>
                            </template>
                        </div>

                        <div
                            class="result-button"
                            v-if="showDraftVersions == true"
                            style="margin-top: 20px; display: flex; justify-content: center;"
                        >
                            <Button
                                class="delete-draft"
                                name="Continue"
                                outline
                                @click.native="continueWithDraft"
                            />
                            <Button
                                style="margin-left:20px;"
                                class="override"
                                name="Restore"
                                primary
                                @click.native="restoreDraft"
                            />
                        </div>
                    </div>
                </div>
            </Modal>

            <template v-if="loaderShow">
                <div class="wait">
                    <Loader :loaderType="loaderType" />
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { getDraft, deleteDraft, updateDraft } from 'api/userApi'
import {
    toggleProduct,
    removeProduct,
    toggleService,
    changeProduct as changeProductApi,
    addProduct,
    getProductsBySearch,
} from 'api/productsApi'
import CustomisationPageModule from 'store/modules/CustomisationPage'
import ProductListPageModule from 'store/modules/ProductListPage'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import ConvertToPrice from 'mixins/ConvertToPrice'
import EHDPageModule from 'store/modules/EHDPage'
import AuthModule from 'store/modules/$authModule'
import Button from 'componentsv2/Button'
import Tabs from 'componentsv2/Tabs'
import NotFound from 'componentsv2/NotFound'
import Tab from 'componentsv2/Tab'
//import Cards from 'componentsv2/Cards'
import NewCard from 'componentsv2/NewCard'
import FullPageNavModal from 'componentsv2/FullPageNavModal'
import Modal from 'componentsv2/Modal'
import Loader from 'componentsv2/Loader'
import FullPageModal from 'componentsv2/FullPageModal'
import CurrentProduct from 'componentsv2/CurrentProduct'
import ProductCards from 'componentsv2/ProductCards'
import FiltersBox from 'componentsv2/FiltersBox'
import RequestProduct from 'componentsv2/RequestProduct'
import SearchAutoComplete from 'componentsv2/SearchAutoComplete'
import CustomizeKitchenModule from 'store/modules/CustomizeKitchenPage'
import { getEHDDetails, submitKitchenData } from 'api/ehdApi'
import { toggleOnlyKitchen } from 'api/userApi'
import Carousel from 'componentsv2/Carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import WorkspaceComponent from 'componentsv2/WorkspaceComponent'
import loaderHandler from 'mixins/loader'
// import ReplaceProductModal from 'componentsv2/ReplaceProductModal'

export default {
    name: 'EHDPage',
    components: {
        Button,
        Tabs,
        Tab,
        // Cards,
        FullPageNavModal,
        Modal,
        Loader,
        NewCard,
        NotFound,
        FullPageModal,
        CurrentProduct,
        ProductCards,
        FiltersBox,
        RequestProduct,
        SearchAutoComplete,
        // ReplaceProductModal,
        Carousel,
        WorkspaceComponent,
    },
    mixins: [RegisterStoreModule, ConvertToPrice, loaderHandler],
    props: {
        requiredDomain: Boolean,
    },
    data() {
        return {
            settings1: {
                dots: true,
                dragabble: true,
                arrows: false,
                focusOnSelect: true,
                infinite: false,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
            },
            showNotFound: false,
            // loaderShow: false,
            showModal: false,
            callDraft: false,
            draftModal: false,
            draftModalMsg: '',
            ehdId: this.$route.params.id ? this.$route.params.id : null,
            currentSceneId: null,
            draftEHDId: null,
            draftSceneId: null,
            preEHDShow: this.$route.query.pq ? this.$route.query.pq : false,
            seeMoreActivated: false,
            scrollPosition: '',
            showMenu: null,
            replaceProductModal: false,
            productID: null,
            productIndex: null,
            newSceneId: null,
            productImage: null,
            productName: null,
            productPrice: null,
            productDescription: null,
            currentSceneImageIndex: 0,
            draftExists: null,
            quantity: null,
            countAltered: false,
            currentProductShow: true,
            scene_type: '',
            scene_id: null,
            add: false,
            autocomplete: '',
            suggestions: [],
            currentState: 0,
            currentTab: 0,
            //paging for related products
            pageNumber: 1,
            totalNumberOfPages: '',
            similarProducts: null,
            autoLoad: true,
            filter_str: '',
            default_scene_image: '',
            scene_thumbnails: [],
            showThumbnails: false,
            //paging for search products
            searchNumber: 1,
            totalNumberOfSearchPages: '',
            searchingActive: true,
            //Checking which operation called while checking draft
            operation: '',
            draftObject: {},
            showDraftVersions: false,
            useWorkspaceComp: false,
            draftDetails: {},
            checkDraftExists: false,
            content: 'Not allowed to access this EHD,',
            roomsAvailable: [],
            kitchenPrice: 0,
            one_time_custom_product: [
                {
                    id: 1,
                    prod_id: 1,
                    name: 'test',
                    description: 'test',
                    quantity: 1,
                    img_url: '',
                    rate: 1,
                    product_link: 'http://www.google.com',
                    category: 'Lamp Table',
                    category_id: 47,
                    vendor: 'FabIndia',
                    vendor_id: 8,
                    price: 1,
                },
                {
                    id: 2,
                    prod_id: 1,
                    name: 'test',
                    description: 'test',
                    quantity: 45,
                    img_url: '',
                    rate: 1,
                    product_link: 'http://www.google.com',
                    category: 'Lamp Table',
                    category_id: 47,
                    vendor: 'FabIndia',
                    vendor_id: 8,
                    price: 45,
                },
            ],
        }
    },
    computed: {
        ...mapState({
            isLoggedIn: state => state.AuthModule.isLoggedIn,
            userData: state => state.AuthModule.userData,
            showAuthModal: state => state.AuthModule.showAuthModal,
            EHDDetails: state => state.EHDPage.EHDDetails,
            ehdShowModal: state => state.EHDPage.ehdShowModal,
            ehdModalMsg: state => state.EHDPage.ehdModalMsg,
            windowWidth: state => state.AppModule.windowWidth,
            filters: state => state.ProductListPage.filters_data,
            allSceneData: state => state.CustomisationPage.scene,
            scene: state => state.CustomisationPage.scene.scene_data,
            relatedProducts: state => state.CustomisationPage.relatedProducts,
            totalProducts: state => state.EHDPage.totalProducts,
            totalServices: state => state.EHDPage.totalServices,
            totalCustomProducts: state => state.EHDPage.totalCustomProducts,
        }),
    },
    watch: {
        isLoggedIn(e) {
            this.fetchEHDDetails({ id: this.$route.params.id })
        },

        $route(to, from) {
            if (to !== from) {
                this.fetchEHDDetails({ id: this.$route.params.id }).then(
                    payload => {
                        if (payload.responseCode == 200) {
                            this.setDefaultThumbnails(payload.data.scene_data)
                            setTimeout(() => {
                                this.loaderShow = false
                            }, 1000)

                            if (this.$route.query.scene) {
                                payload.data.scene_data.forEach(
                                    (scene, index) => {
                                        if (
                                            scene.id == this.$route.query.scene
                                        ) {
                                            this.currentState = index
                                        }
                                    },
                                )
                            }
                            if (this.$route.query.ok) {
                                payload.data.scene_data.forEach(
                                    (scene, index) => {
                                        let obj = {
                                            room_available: '',
                                            scene: scene.id,
                                        }
                                        if (scene.room_type == 'Kitchen') {
                                            obj.room_available = true
                                            this.currentState = index
                                            this.kitchenPrice = scene.price
                                        } else {
                                            obj.room_available = false
                                        }
                                        this.roomsAvailable.push(obj)
                                    },
                                )
                            } else {
                                payload.data.scene_data.forEach(
                                    (scene, index) => {
                                        let obj = {
                                            room_available: true,
                                            scene: scene.id,
                                        }
                                        this.roomsAvailable.push(obj)
                                    },
                                )
                            }

                            if (
                                this.$route.query.cust == 'true' &&
                                this.$route.query.scene
                            ) {
                                getDraft(
                                    this.$route.params.id,
                                    this.$route.query.scene,
                                ).then(response => {
                                    this.loaderShow = true
                                    if (
                                        response.responseCode == 200 &&
                                        response.payload.ehd_id !=
                                            this.$route.params.id
                                    ) {
                                        getEHDDetails(
                                            response.payload.ehd_id,
                                        ).then(res => {
                                            this.loaderShow = false
                                            if (res.responseCode == 200) {
                                                this.draftEHDId = res.payload.id
                                                this.draftSceneId =
                                                    res.data.scene_data[
                                                        this.currentState
                                                    ].id
                                                this.draftModal = true
                                                this.draftModalMsg =
                                                    'Do you want to edit your draft?'
                                            }
                                        })
                                    } else {
                                        this.loaderShow = false
                                        this.currentSceneId = this.$route.query.scene
                                        this.toggleFullPageModal()
                                    }
                                })
                            }

                            document.querySelector(
                                "meta[property='og:title']",
                            ).content = `${this.EHDDetails.theme +
                                ' ' +
                                this.EHDDetails.floor_plan}`

                            document.querySelector(
                                "meta[property='og:description']",
                            ).content = `${this.EHDDetails.theme +
                                ' ' +
                                this.EHDDetails.floor_plan +
                                ' by ' +
                                this.EHDDetails.designer} `
                        } else {
                            setTimeout(() => {
                                this.loaderShow = false
                                this.showNotFound = true
                            }, 1000)
                        }

                        // else {
                        //     this.$router.push({ name: '404' })
                        // }
                    },
                )
            }
        },
    },
    metaInfo: {
        title: 'SILOHO',
        titleTemplate: '%s | EHD Page',
        bodyAttrs: {
            class: ['ehd-page-body'],
        },
    },
    created() {
        this.registerStoreModule('EHDPage', EHDPageModule)
        this.registerStoreModule('CustomizeKitchenPage', CustomizeKitchenModule)
        // this.loaderShow = true

        this.registerStoreModule('CustomisationPage', CustomisationPageModule)
        this.registerStoreModule('ProductListPage', ProductListPageModule)
        this.fetchEHDDetails({ id: this.$route.params.id }).then(payload => {
            if (payload.responseCode == 200) {
                setTimeout(() => {
                    this.loaderShow = false
                }, 1000)
                this.setDefaultThumbnails(payload.data.scene_data)

                if (this.$route.query.scene) {
                    payload.data.scene_data.forEach((scene, index) => {
                        if (scene.id == this.$route.query.scene) {
                            this.currentState = index
                        }
                    })
                }

                if (
                    this.$route.query.cust == 'true' &&
                    this.$route.query.scene
                ) {
                    getDraft(
                        this.$route.params.id,
                        this.$route.query.scene,
                    ).then(response => {
                        this.loaderShow = true
                        if (
                            response.responseCode == 200 &&
                            response.payload.ehd_id != this.$route.params.id
                        ) {
                            getEHDDetails(response.payload.ehd_id).then(res => {
                                this.loaderShow = false
                                if (res.responseCode == 200) {
                                    this.draftEHDId = res.payload.id
                                    this.draftSceneId =
                                        res.payload.scene_data[
                                            this.currentState
                                        ].id
                                    this.draftModal = true
                                    this.draftModalMsg =
                                        'Do you want to edit your draft?'
                                }
                            })
                        } else {
                            this.loaderShow = false
                            this.currentSceneId = this.$route.query.scene
                            this.toggleFullPageModal()
                        }
                    })
                }
                if (this.$route.query.ok) {
                    payload.data.scene_data.forEach((scene, index) => {
                        let obj = { room_available: '', scene: scene.id }
                        if (scene.room_type == 'Kitchen') {
                            obj.room_available = true
                            this.currentState = index
                            this.kitchenPrice = scene.price
                        } else {
                            obj.room_available = false
                        }
                        this.roomsAvailable.push(obj)
                    })
                } else {
                    payload.data.scene_data.forEach((scene, index) => {
                        let obj = { room_available: true, scene: scene.id }
                        this.roomsAvailable.push(obj)
                    })
                }

                document.querySelector(
                    "meta[property='og:title']",
                ).content = `${this.EHDDetails.theme +
                    ' ' +
                    this.EHDDetails.floor_plan}`

                document.querySelector(
                    "meta[property='og:description']",
                ).content = `${this.EHDDetails.theme +
                    ' ' +
                    this.EHDDetails.floor_plan +
                    ' by ' +
                    this.EHDDetails.designer} `
            } else {
                setTimeout(() => {
                    this.loaderShow = false
                    this.showNotFound = true
                }, 1000)
            }
            // else {
            //     this.$router.push({ name: '404' })
            // }
        })
    },
    mounted() {
        if (this.$route.query.scene_id) {
            this.checkDraft(this.$route.query.scene_id)
        }
    },
    methods: {
        ...mapActions({
            fetchEHDDetails: 'fetchEHDDetails',
            toggleEhdModal: 'toggleEhdModal',
            toggleShowAuthModal: 'toggleShowAuthModal',
            fetchFilters: 'fetchFilters',
            fetchScene: 'fetchScene',
            fetchRelatedProducts: 'fetchRelatedProducts',
            fetchProducts: 'fetchProducts',
            updateRelatedProducts: 'updateRelatedProducts',
            CLIENT_SCENE: 'CLIENT_SCENE',
            restoreDraftDetails: 'restoreDraftDetails',
            fetchQuoteList: 'fetchQuoteList',
        }),
        toggleSceneThumbnails() {
            this.showThumbnails = !this.showThumbnails
        },
        hideThumbnails(bool) {
            if (bool == true) {
                this.showThumbnails = false
            }
        },
        setDefaultThumbnails(scene_data) {
            scene_data.forEach(scene => {
                var temp_images = []
                scene.scene_image_data.forEach(image => {
                    temp_images.push({
                        image: image.scene_image_url,
                        scene_image_type: image.scene_image_type,
                    })
                })
                this.scene_thumbnails.push(temp_images)
            })
            this.default_scene_image =
                this.scene_thumbnails[this.currentState].length > 0
                    ? this.scene_thumbnails[this.currentState][0].image
                    : ''
        },
        changeDefaultSceneImage(image) {
            this.default_scene_image = image
        },
        addAllRooms(id) {
            this.roomsAvailable = []
            toggleOnlyKitchen(false).then(response => {
                if (response.responseCode == 200) {
                    this.$router.push({ path: `/ehd/${id}/v2` })
                }
            })
        },

        loadMoreProducts() {
            const spinner = `<div class="spinner"></div>`
            let loaderContainer = document.querySelector('.loader-container')
            if (
                this.pageNumber < this.totalNumberOfPages &&
                this.autoLoad == true
            ) {
                this.autoLoad = false
                this.pageNumber++
                loaderContainer.innerHTML += spinner
                this.fetchRelatedProducts({
                    obj: `?product_id=${this.productID}${this.filter_str}&page=${this.pageNumber}&size=21&scene_id=${this.currentSceneId}`,
                }).then(response => {
                    if (response.responseCode == 200) {
                        this.totalNumberOfPages = response.payload.total_page
                        loaderContainer.removeChild(
                            document.querySelector('.spinner'),
                        )
                        response.payload.related_product.forEach(product => {
                            this.similarProducts.push(product)
                        })
                        setTimeout(() => {
                            this.autoLoad = true
                        }, 1500)
                    }
                })
            }
        },
        openPDFView() {
            this.$router.push({
                name: 'DownloadBOQ',
                query: { ehd_id: this.$route.params.id },
            })
        },
        updateCurrentState(index) {
            this.currentState = index
            this.default_scene_image =
                this.scene_thumbnails[this.currentState].length > 0
                    ? this.scene_thumbnails[this.currentState][0].image
                    : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg'
            this.showThumbnails = false
        },
        updateCurrentTab(index) {
            this.currentTab = index
        },
        fetchSuggestions(query) {
            if (query.length > 2) {
                this.suggestions = []
                this.searchNumber = 1
                getProductsBySearch({
                    string: query,
                    page: this.searchNumber,
                }).then(response => {
                    this.suggestions = response.furnish_data
                    this.totalNumberOfSearchPages = response.num_pages
                })
            }
        },
        loadMoreSearchOptions(query) {
            if (
                this.searchNumber < this.totalNumberOfSearchPages &&
                this.searchingActive == true
            ) {
                this.searchingActive = false
                this.searchNumber++
                getProductsBySearch({
                    string: query,
                    page: this.searchNumber,
                }).then(response => {
                    response.furnish_data.forEach(product => {
                        this.suggestions.push(product)
                    })
                    this.searchingActive = true
                    this.totalNumberOfSearchPages = response.num_pages
                })
            }
        },

        toggleReplaceProductModal() {
            this.replaceProductModal = !this.replaceProductModal
            if (this.replaceProductModal) {
                document.querySelector('body').style.overflow = 'hidden'
            } else {
                document.querySelector('body').style.overflow = 'auto'
                this.similarProducts = {}
                this.totalNumberOfPages = null
                this.pageNumber = 1
                this.filter_str = ''
                this.suggestions = []
                this.totalNumberOfSearchPages = null
            }
        },

        changeProducts(obj) {
            this.add = false
            this.currentProductShow = true
            this.quantity = obj.total_quantity
            this.currentSceneId = obj.scene_id
            this.ehdId = this.$route.params.id
            this.productID = obj.product.id
            this.productIndex = obj.index
            this.productName = obj.product.name
            this.productDescription = obj.product.description
            this.productPrice = obj.product.item_price
            this.productImage = obj.product.image_url

            this.fetchScene({ id: obj.scene_id })
            this.fetchFilters({
                source: 'RelatedProduct',
                product_id: this.productID,
            })
            this.fetchRelatedProducts({
                obj: `?product_id=${this.productID}${this.filter_str}&page=${this.pageNumber}&size=21&scene_id=${this.currentSceneId}`,
            }).then(response => {
                this.toggleReplaceProductModal()
                if (response.responseCode == 200) {
                    this.totalNumberOfPages = response.payload.total_page
                    this.similarProducts = response.payload.related_product
                    setTimeout(() => {
                        this.loaderShow = false
                    }, 1500)
                } else {
                    setTimeout(() => {
                        this.loaderShow = false
                    }, 1500)
                }
            })
        },

        changeProductForEHD(newproduct) {
            this.loaderShow = true
            this.toggleReplaceProductModal()
            this.scene.furnishing_products_in_scene_grouped.forEach(
                (product, idx) => {
                    if (product.id == this.productID) {
                        product.specific_data.forEach((replace, index) => {
                            changeProductApi({
                                scene_id: this.scene.id,
                                old_product: parseInt(this.productID),
                                new_product: newproduct.product_id,
                                xloc: parseFloat(
                                    this.scene
                                        .furnishing_products_in_scene_grouped[
                                        idx
                                    ].specific_data[index].xloc,
                                ),
                                yloc: parseFloat(
                                    this.scene
                                        .furnishing_products_in_scene_grouped[
                                        idx
                                    ].specific_data[index].yloc,
                                ),
                                zloc: parseFloat(
                                    this.scene
                                        .furnishing_products_in_scene_grouped[
                                        idx
                                    ].specific_data[index].zloc,
                                ),
                                product_loc_id: this.scene
                                    .furnishing_products_in_scene_grouped[idx]
                                    .specific_data[index].product_loc_id,
                                type: 'CHANGE_PRODUCT',
                            })
                                .then(response => {
                                    let data = response.data.payload
                                    this.updateEHD({
                                        ehd_id: data.ehd_id,
                                        scene_id: data.scene_id,
                                        callDraft: true,
                                    })

                                    if (response.status == 200) {
                                        setTimeout(() => {
                                            this.loaderShow = false
                                        }, 1500)
                                    }
                                })
                                .catch(() => {
                                    setTimeout(() => {
                                        this.loaderShow = false
                                    }, 1000)
                                })
                        })
                    }
                },
            )
            this.scene.decor_products_in_scene_grouped.forEach(
                (product, idx) => {
                    if (product.id == this.productID) {
                        product.specific_data.forEach((replace, index) => {
                            changeProductApi({
                                scene_id: this.scene.id,
                                old_product: parseInt(this.productID),
                                new_product: newproduct.product_id,
                                xloc: parseFloat(
                                    this.scene.decor_products_in_scene_grouped[
                                        idx
                                    ].specific_data[index].xloc,
                                ),
                                yloc: parseFloat(
                                    this.scene.decor_products_in_scene_grouped[
                                        idx
                                    ].specific_data[index].yloc,
                                ),
                                zloc: parseFloat(
                                    this.scene.decor_products_in_scene_grouped[
                                        idx
                                    ].specific_data[index].zloc,
                                ),
                                product_loc_id: this.scene
                                    .decor_products_in_scene_grouped[idx]
                                    .specific_data[index].product_loc_id,
                                type: 'CHANGE_PRODUCT',
                            })
                                .then(response => {
                                    let data = response.data.payload
                                    this.updateEHD({
                                        ehd_id: data.ehd_id,
                                        scene_id: data.scene_id,
                                        callDraft: true,
                                    })

                                    if (response.status == 200) {
                                        setTimeout(() => {
                                            this.loaderShow = false
                                        }, 1500)
                                    }
                                })
                                .catch(() => {
                                    setTimeout(() => {
                                        this.loaderShow = false
                                    }, 1000)
                                })
                        })
                    }
                },
            )
        },

        getFilteredProduct(filters) {
            if (filters.vendor_ids.length > 0) {
                let filters_string = Object.keys(filters)
                    .map(key => key + '=' + filters[key])
                    .join('&')
                this.filter_str = `&${filters_string}`
            } else {
                this.filter_str = ''
            }
            this.pageNumber = 1

            this.fetchRelatedProducts({
                obj: `?product_id=${this.productID}${this.filter_str}&page=${this.pageNumber}&size=21&scene_id=${this.currentSceneId}`,
            }).then(response => {
                if (response.responseCode == 200) {
                    this.totalNumberOfPages = response.payload.total_page
                    this.similarProducts = response.payload.related_product
                }
            })
        },

        toggleProducts(obj) {
            this.loaderShow = true
            document.querySelector('body').style.overflow = 'hidden'
            for (let i = 0; i < obj.product.total_quantity; i++) {
                if (obj.product.specific_data[i].is_active != obj.enable) {
                    toggleProduct({
                        type: 'TOGGLE_PRODUCT',
                        product_id: obj.product.id,
                        product_enable: obj.enable,
                        scene_id: obj.scene_id,
                        xloc: obj.product.specific_data[i].xloc,
                        yloc: obj.product.specific_data[i].yloc,
                        zloc: obj.product.specific_data[i].zloc,
                        product_loc_id:
                            obj.product.specific_data[i].product_loc_id,
                    })
                        .then(response => {
                            let data = response.data.payload
                            this.updateEHD({
                                ehd_id: data.ehd_id,
                                scene_id: data.scene_id,
                                callDraft: true,
                            })
                            if (
                                i == obj.product.total_quantity - 1 &&
                                response.status == 200
                            ) {
                                this.fetchEHDDetails({ id: data.ehd_id })
                                    .then(res => {
                                        setTimeout(() => {
                                            this.loaderShow = false
                                            document.querySelector(
                                                'body',
                                            ).style.overflow = 'auto'
                                        }, 150)
                                    })
                                    .catch(err => {
                                        setTimeout(() => {
                                            this.loaderShow = false
                                        }, 100)
                                    })
                            }
                        })
                        .catch(() => {
                            setTimeout(() => {
                                this.loaderShow = false
                            }, 1000)
                        })
                }
            }
        },
        toggleCustomProducts(obj) {
            this.loaderShow = true
            document.querySelector('body').style.overflow = 'hidden'
            console.log(obj, 'object')
            toggleProduct({
                type: 'TOGGLE_CUSTOM_PRODUCT',
                product_id: obj.custom_product_id,
                product_enable: obj.enable,
                scene_id: obj.scene_id,
                ehd_id: obj.ehd_id,
            })
                .then(response => {
                    let data = response.data.payload
                    this.updateEHD({
                        ehd_id: data.ehd_id,
                        scene_id: data.scene_id,
                        callDraft: true,
                    })
                    this.fetchEHDDetails({ id: data.ehd_id })
                    setTimeout(() => {
                        this.loaderShow = false
                        document.querySelector('body').style.overflow = 'auto'
                    }, 1500)
                })
                .catch(() => {
                    setTimeout(() => {
                        this.loaderShow = false
                    }, 1000)
                })
        },

        changeCount(obj) {
            this.countAltered = false
            this.loaderShow = true
            document.querySelector('body').style.overflow = 'hidden'
            this.fetchScene({ id: obj.scene_id }).then(response => {
                response.scene_data.furnishing_products_in_scene_grouped.forEach(
                    (product, idx) => {
                        if (product.id == obj.product.id) {
                            product.specific_data.forEach((replace, index) => {
                                if (this.countAltered == false) {
                                    if (replace.is_active != obj.enable) {
                                        toggleProduct({
                                            type: 'TOGGLE_PRODUCT',
                                            product_id: obj.product.id,
                                            product_enable: obj.enable,
                                            scene_id: obj.scene_id,
                                            xloc: replace.xloc,
                                            yloc: replace.yloc,
                                            zloc: replace.zloc,
                                            product_loc_id:
                                                replace.product_loc_id,
                                        })
                                            .then(response => {
                                                let data = response.data.payload
                                                this.updateEHD({
                                                    ehd_id: data.ehd_id,
                                                    scene_id: data.scene_id,
                                                    callDraft: true,
                                                })

                                                if (response.status == 200) {
                                                    this.fetchEHDDetails({
                                                        id: data.ehd_id,
                                                    })
                                                    setTimeout(() => {
                                                        this.loaderShow = false
                                                        document.querySelector(
                                                            'body',
                                                        ).style.overflow =
                                                            'auto'
                                                    }, 1500)
                                                }
                                            })
                                            .catch(() => {
                                                setTimeout(() => {
                                                    this.loaderShow = false
                                                }, 1000)
                                            })
                                        this.countAltered = true
                                    }
                                }
                            })
                        }
                    },
                )
                response.scene_data.decor_products_in_scene_grouped.forEach(
                    (product, idx) => {
                        if (product.id == obj.product.id) {
                            product.specific_data.forEach((replace, index) => {
                                if (this.countAltered == false) {
                                    if (replace.is_active != obj.enable) {
                                        toggleProduct({
                                            type: 'TOGGLE_PRODUCT',
                                            product_id: obj.product.id,
                                            product_enable: obj.enable,
                                            scene_id: obj.scene_id,
                                            xloc: replace.xloc,
                                            yloc: replace.yloc,
                                            zloc: replace.zloc,
                                            product_loc_id:
                                                replace.product_loc_id,
                                        })
                                            .then(response => {
                                                let data = response.data.payload
                                                this.updateEHD({
                                                    ehd_id: data.ehd_id,
                                                    scene_id: data.scene_id,
                                                    callDraft: true,
                                                })

                                                if (response.status == 200) {
                                                    this.fetchEHDDetails({
                                                        id: data.ehd_id,
                                                    })
                                                    setTimeout(() => {
                                                        this.loaderShow = false
                                                        document.querySelector(
                                                            'body',
                                                        ).style.overflow =
                                                            'auto'
                                                    }, 1500)
                                                }
                                            })
                                            .catch(() => {
                                                setTimeout(() => {
                                                    this.loaderShow = false
                                                }, 1000)
                                            })
                                        this.countAltered = true
                                    }
                                }
                            })
                        }
                    },
                )
            })
        },

        closeDraftModal() {
            this.loaderShow = false
            this.draftModal = false
            document.querySelector('body').style.overflow = 'auto'
        },

        toggleFullPageModal() {
            this.loaderShow = true
            this.showModal = !this.showModal
            if (this.showModal) {
                document.querySelector('body').style.overflow = 'hidden'
            } else {
                document.querySelector('body').style.overflow = 'auto'
            }
            setTimeout(() => {
                this.loaderShow = false
            }, 1000)
        },

        updateEHD(e) {
            this.callDraft = e.callDraft
            this.ehdId = e.ehd_id
            this.currentSceneId = e.scene_id
            if (this.$route.params.id != e.ehd_id) {
                this.$router.push({ path: `/ehd/${e.ehd_id}/v2` })
            } else {
                this.fetchEHDDetails({ id: this.$route.params.id })
            }
        },

        checkDraft(obj) {
            this.add = false
            this.loaderShow = true
            this.currentSceneId = obj.scene_id
            this.operation = obj.operation
            this.scene_type = obj.scene_type
            this.draftObject = obj
            if (this.checkDraftExists == false) {
                this.checkDraftExists = true
                getDraft(obj.ehd_id, obj.scene_id).then(data => {
                    if (data.responseCode == 2012) {
                        this.checkDraftExists = false
                        switch (obj.operation) {
                            case 'change':
                                this.loaderShow = true
                                this.changeProducts(obj)
                                break
                            case 'add':
                                this.addMoreProducts({
                                    scene_id: obj.scene_id,
                                    scene_type: obj.scene_type,
                                })
                                break
                            case 'toggle':
                                this.toggleProducts(obj)
                                break
                            case 'customProduct':
                                this.toggleCustomProducts(obj)
                                break
                            case 'service':
                                this.toggleServices(obj)
                                break
                            case 'customise':
                                this.customiseScene()
                                break
                            case 'count':
                                this.changeCount(obj)
                                break
                            default:
                                break
                        }
                    } else {
                        this.useWorkspaceComp = data.data.use_ws_draft_comp
                        this.draftDetails = data.data.draft_info
                        this.draftObject.ehd_id = data.data.ehd_id
                        this.draftObject.scene_id = data.data.scene_id
                        this.showDraftVersions = true
                        document.querySelector('body').style.overflow = 'hidden'
                        this.draftModal = true
                        this.draftModalMsg =
                            "You have already made changes on this EHD. Would you like to 'continue' editing your draft or 'restore' previous changes?"
                        this.draftEHDId = data.data.ehd_id
                        this.draftSceneId = data.data.scene_id
                        this.currentSceneId = this.draftSceneId
                        this.ehdId = data.data.ehd_id
                        this.checkDraftExists = false
                        this.loaderShow = false
                    }
                })
            }
        },

        toggleServices(obj) {
            document.querySelector('body').style.overflow = 'hidden'
            toggleService({
                type: 'TOGGLE_SERVICE',
                service_id: obj.service_id,
                service_enable: obj.enable,
                scene_id: obj.scene_id,
            })
                .then(response => {
                    let data = response.data.payload
                    this.ehdId = data.ehd_id
                    this.newSceneId = data.scene_id
                    this.updateEHD({
                        ehd_id: data.ehd_id,
                        scene_id: data.scene_id,
                        callDraft: true,
                    })
                    if (response.status == 200) {
                        setTimeout(() => {
                            this.loaderShow = false
                            document.querySelector('body').style.overflow =
                                'auto'
                        }, 2000)
                    }
                })
                .catch(() => {
                    setTimeout(() => {
                        this.loaderShow = false
                    }, 1000)
                })
        },

        // deleteEHDDraft() {
        //     deleteDraft().then(data => {
        //         if (data.responseCode == 200) {
        //             this.callDraft == false
        //             this.draftModal = false
        //             this.draftModalMsg = ''
        //             this.toggleFullPageModal()
        //         }
        //     })
        // },
        customiseScene() {
            if (this.draftObject.scene == 'Kitchen') {
                const that = this
                this.fetchQuoteList(this.$route.params.id).then(response => {
                    if (response.responseCode == 200) {
                        // this.$router.push({name:'KitchenCustomisationPage',query: { eid:obj.ehd_id,sid:obj.scene_id} })
                        let obj_post = {
                            scene_id: this.draftObject.scene_id,
                            ehd_id: this.draftObject.ehd_id,
                            image: this.draftObject.scene_img,
                        }
                        let json_obj_post = JSON.stringify(obj_post)
                        submitKitchenData(json_obj_post).then(response => {
                            if (response.responseCode == 200) {
                                // this.$router.push({
                                //     name: 'EHDPage',
                                //     params: { id: response.payload.ehd_id },
                                // })
                                that.$router.push({
                                    name: 'KitchenCustomisationPage',
                                    query: {
                                        eid: response.payload.ehd_id,
                                        sid: response.payload.scene_id,
                                    },
                                })
                                setTimeout(() => {
                                    this.loaderShow = false
                                }, 1000)
                            }
                        })
                        setTimeout(() => {
                            this.loaderShow = false
                        }, 1000)
                    } else {
                        that.toggleFullPageModal()
                        setTimeout(() => {
                            this.loaderShow = false
                        }, 1000)
                    }
                })
            } else {
                this.toggleFullPageModal()
                setTimeout(() => {
                    this.loaderShow = false
                }, 1000)
            }
        },

        continueWithDraft() {
            this.loaderShow = true
            document.querySelector('body').style.overflow = 'auto'
            if (this.$route.query.cust == 'true' && this.$route.query.scene) {
                this.$router.push({
                    path: `/ehd/${this.draftEHDId}/v2?scene=${this.draftSceneId}&cust=true`,
                })
            } else {
                this.$router.push({ path: `/ehd/${this.draftEHDId}/v2` })
            }
            if (this.draftObject.operation == 'customise') {
                this.customiseScene()
            }

            this.draftModal = false
            this.draftModalMsg = ''
            this.showDraftVersions = false
        },
        restoreDraft() {
            this.loaderShow = true
            document.querySelector('body').style.overflow = 'auto'
            this.restoreDraftDetails({
                ehd_id: this.ehdId,
                scene_id: this.currentSceneId,
            }).then(response => {
                if (response.responseCode == 200) {
                    if (
                        this.$route.query.cust == 'true' &&
                        this.$route.query.scene
                    ) {
                        this.$router.push({
                            path: `/ehd/${this.draftEHDId}/v2?scene=${this.draftSceneId}&cust=true`,
                        })
                    } else {
                        this.$router.push({
                            path: `/ehd/${this.draftEHDId}/v2`,
                        })
                    }

                    if (this.draftObject.operation == 'customise') {
                        this.customiseScene()
                    }
                }
            })

            this.draftModal = false
            this.draftModalMsg = ''
            this.showDraftVersions = false
        },
        activateSeeMore(bool) {
            this.seeMoreActivated = bool
            if (this.seeMoreActivated) {
                document.querySelector('.description').style.overflow = 'auto'
            } else {
                document.querySelector('.description').style.overflow = 'hidden'
            }
        },

        addMoreProducts(obj) {
            this.add = true
            this.scene_id = obj.scene_id
            this.scene_type = obj.scene_type
            this.add = true
            this.$store.commit('setFiltersData', [])
            this.currentProductShow = false
            // this.fetchFilters({
            //     source: 'RelatedProduct',
            //     product_id: 210,
            // })
            // this.fetchProducts(0)
            this.fetchProducts(0)
                .then(() => {
                    setTimeout(() => {
                        this.loaderShow = false
                    }, 1000)
                })
                .catch(() => {
                    setTimeout(() => {
                        this.loaderShow = false
                    }, 1000)
                })
            this.toggleReplaceProductModal()
        },

        addProductForEHD(newproduct) {
            this.loaderShow = true
            this.toggleReplaceProductModal()
            addProduct({
                scene_id: this.scene_id,
                new_product: newproduct.product_id,
                xloc: 0.0,
                yloc: 0.0,
                zloc: 0.0,
                type: 'ADD_PRODUCT',
            }).then(response => {
                let data = response.payload
                this.updateEHD({
                    ehd_id: data.ehd_id,
                    scene_id: data.scene_id,
                    callDraft: true,
                })
                if (response.responseCode == 200) {
                    setTimeout(() => {
                        this.loaderShow = false
                        document.querySelector('body').style.overflow = 'auto'
                    }, 1500)
                }
            })
        },

        loadMore() {
            console.log('loadmore')
        },
    },
}
</script>

<style lang="scss">
@import './EHDPage.scss';
</style>
