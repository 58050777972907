<template>
    <div>
        <template v-if="loaderShow">
            <div class="wait">
                <Loader :loaderType="loaderType" />
            </div>
        </template>
        <div v-if="scene != undefined">
            <transition name="fullpagenavmodal">
                <div class="fullpagenavmodal" id="fullpagenavmodal">
                    <nav class="f-modal-nav">
                        <div class="navbar-f-container">
                            <div class="navbar-menu-scene-info">
                                <span @click="toggleSideDrawer">
                                    <svg
                                        width="25"
                                        height="24"
                                        class="navbar-menu"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M21.275 18h-18v-2h18v2zm0-5h-18v-2h18v2zm0-5h-18V6h18v2z"
                                        />
                                    </svg>
                                </span>
                            </div>
                            <div class="nav-center-content">
                                <span
                                    id="nav_scene_info"
                                    class="nav_info_content"
                                >
                                    {{ scene.room_type }} | ETA: 3-5 Weeks |
                                    <span
                                        :class="
                                            requiredDomain == false
                                                ? 'blur'
                                                : ''
                                        "
                                        >₹
                                        {{
                                            convertToIndianNumberSystem(
                                                scene.price,
                                            )
                                        }}</span
                                    >
                                </span>
                                <!-- <div
                                    class="switch-btn"
                                    v-if="scene.is_gltf_available === true"
                                >
                                    <div
                                        class="hd-btn"
                                        :class="{
                                            'q-active': q === 'HD',
                                        }"
                                        @click="SRQ('HD')"
                                    >
                                        HD
                                    </div>
                                    <div
                                        class="sd-btn"
                                        :class="{
                                            'q-active': q === 'SD',
                                        }"
                                        @click="SRQ('SD')"
                                    >
                                        SD
                                    </div>
                                </div> -->
                            </div>
                            <div class="nav-right-content">
                                <!-- <div>
                                <input
                                    type="checkbox"
                                    id="nav-mode-checkbox"
                                    style="display:none;"
                                    @click="nightView"
                                    checked="false"
                                />
                                <label
                                    for="nav-mode-checkbox"
                                    class="nav-mode-toggle"
                                >
                                    <span>
                                        <i class=""></i>
                                        <i class=""></i>
                                    </span>
                                </label>
                                </div>
                                <p>Night Mode</p> -->

                                <div
                                    class="custm-f-exit"
                                    @click="$emit('close')"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="24"
                                        class="close-icon"
                                        viewBox="0 0 25 24"
                                    >
                                        <path
                                            d="M19.275 6.41L17.865 5l-5.59 5.59L6.685 5l-1.41 1.41 5.59 5.59-5.59 5.59L6.685 19l5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </nav>

                    <!-- footer navingarion component -->
                    <div class="f-bottom_nav">
                        <div class="btn-container">
                            <button
                                class="f-nva-blockb"
                                @click="toggleSideDrawer"
                            >
                                <div class="svg-container">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="33.487"
                                        height="31.009"
                                        class="product-icon"
                                    >
                                        <g
                                            id="noun_products_2804222"
                                            transform="translate(-1.366 -67.981)"
                                        >
                                            <g
                                                id="Group_587"
                                                data-name="Group 587"
                                                transform="translate(1.516 68.124)"
                                            >
                                                <g
                                                    id="Group_568"
                                                    data-name="Group 568"
                                                    transform="translate(8.123)"
                                                >
                                                    <g
                                                        id="Group_561"
                                                        data-name="Group 561"
                                                        transform="translate(0 4.293)"
                                                    >
                                                        <g
                                                            id="Group_560"
                                                            data-name="Group 560"
                                                        >
                                                            <path
                                                                id="Path_961"
                                                                data-name="Path 961"
                                                                class="cls-1"
                                                                d="M275.742 205.175q-1.38-.726-2.756-1.456-2.184-1.152-4.367-2.306l-1-.529c.059.1.114.2.173.3v-8.572l-.519.3q1.38.726 2.756 1.456 2.184 1.157 4.367 2.31l1 .529c-.059-.1-.114-.2-.173-.3v8.569a.346.346 0 0 0 .692 0V196.9a.341.341 0 0 0-.173-.3q-1.38-.726-2.756-1.456-2.184-1.157-4.367-2.31l-1-.529a.348.348 0 0 0-.519.3v8.572a.341.341 0 0 0 .173.3q1.38.726 2.756 1.456 2.184 1.152 4.367 2.306l1 .529c.39.209.74-.386.346-.593z"
                                                                transform="translate(-267.1 -192.268)"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g
                                                        id="Group_563"
                                                        data-name="Group 563"
                                                        transform="translate(8.123 4.293)"
                                                    >
                                                        <g
                                                            id="Group_562"
                                                            data-name="Group 562"
                                                        >
                                                            <path
                                                                id="Path_962"
                                                                data-name="Path 962"
                                                                class="cls-1"
                                                                d="M510.3 200.883q-1.38.726-2.756 1.456l-4.371 2.306-1 .529.519.3V196.9c-.059.1-.114.2-.173.3q1.38-.726 2.756-1.456 2.184-1.157 4.371-2.31l1-.529-.519-.3v8.572a.346.346 0 0 0 .692 0v-8.572a.348.348 0 0 0-.519-.3q-1.38.726-2.756 1.456-2.184 1.157-4.371 2.31l-1 .529a.341.341 0 0 0-.173.3v8.569a.348.348 0 0 0 .519.3q1.38-.726 2.756-1.456l4.371-2.306 1-.529c.393-.207.044-.806-.346-.595z"
                                                                transform="translate(-502 -192.268)"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g
                                                        id="Group_565"
                                                        data-name="Group 565"
                                                        transform="translate(.008)"
                                                    >
                                                        <g
                                                            id="Group_564"
                                                            data-name="Group 564"
                                                        >
                                                            <path
                                                                id="Path_963"
                                                                data-name="Path 963"
                                                                class="cls-1"
                                                                d="M283.739 72.456q-1.38.726-2.756 1.456-2.184 1.157-4.371 2.31l-1 .529h.349q-1.38-.726-2.756-1.456-2.184-1.157-4.367-2.31l-1-.529v.6q1.38-.726 2.756-1.456 2.184-1.152 4.367-2.306l1-.529h-.349q1.38.726 2.756 1.456l4.371 2.306 1 .529c.394.207.743-.391.349-.6q-1.39-.737-2.784-1.47-2.184-1.157-4.371-2.31c-.318-.169-.636-.349-.961-.508a.458.458 0 0 0-.47.055c-.166.086-.332.173-.494.263l-4.167 2.2c-1.1.581-2.213 1.134-3.3 1.739-.017.01-.035.017-.052.028a.35.35 0 0 0 0 .6q1.39.737 2.784 1.47 2.184 1.157 4.371 2.31c.318.169.636.349.961.508a.465.465 0 0 0 .474-.055c.166-.086.332-.176.495-.263l4.167-2.2c1.1-.581 2.213-1.134 3.3-1.739.017-.01.035-.017.052-.028.386-.209.037-.807-.354-.6z"
                                                                transform="translate(-267.325 -68.124)"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g
                                                        id="Group_567"
                                                        data-name="Group 567"
                                                        transform="translate(4.063 2.145)"
                                                    >
                                                        <g
                                                            id="Group_566"
                                                            data-name="Group 566"
                                                        >
                                                            <path
                                                                id="Path_964"
                                                                data-name="Path 964"
                                                                class="cls-1"
                                                                d="m392.887 130.2-2.753 1.456q-2.184 1.157-4.367 2.31l-1 .529c-.394.207-.045.806.349.6l2.753-1.456q2.184-1.157 4.367-2.31l1-.529c.394-.207.045-.806-.349-.6z"
                                                                transform="translate(-384.595 -130.154)"
                                                            />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g
                                                    id="Group_577"
                                                    data-name="Group 577"
                                                    transform="translate(0 12.864)"
                                                >
                                                    <g
                                                        id="Group_570"
                                                        data-name="Group 570"
                                                        transform="translate(0 4.296)"
                                                    >
                                                        <g
                                                            id="Group_569"
                                                            data-name="Group 569"
                                                        >
                                                            <path
                                                                id="Path_965"
                                                                data-name="Path 965"
                                                                class="cls-1"
                                                                d="m40.838 577.268-2.753-1.452q-2.184-1.151-4.367-2.306l-1-.529c.059.1.114.2.173.3v-8.569l-.519.3 2.753 1.456q2.184 1.157 4.367 2.31l1 .529c-.059-.1-.114-.2-.173-.3v8.565a.346.346 0 0 0 .692 0V569a.342.342 0 0 0-.173-.3l-2.753-1.456q-2.184-1.157-4.367-2.31l-1-.529a.348.348 0 0 0-.519.3v8.569a.341.341 0 0 0 .173.3l2.753 1.452q2.184 1.151 4.367 2.306l1 .529c.391.213.74-.386.346-.593z"
                                                                transform="translate(-32.2 -564.368)"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g
                                                        id="Group_572"
                                                        data-name="Group 572"
                                                        transform="translate(8.119 4.293)"
                                                    >
                                                        <g
                                                            id="Group_571"
                                                            data-name="Group 571"
                                                        >
                                                            <path
                                                                id="Path_966"
                                                                data-name="Path 966"
                                                                class="cls-1"
                                                                d="m275.3 572.884-2.756 1.452-4.371 2.306-1 .529.519.3V568.9c-.059.1-.114.2-.173.3.92-.484 1.836-.972 2.756-1.456q2.184-1.157 4.371-2.31l1-.529-.519-.3v8.572a.346.346 0 0 0 .692 0v-8.572a.348.348 0 0 0-.519-.3q-1.38.726-2.756 1.456-2.184 1.157-4.371 2.31l-1 .529a.342.342 0 0 0-.173.3v8.565a.348.348 0 0 0 .519.3l2.756-1.452 4.371-2.307 1-.529c.393-.206.044-.801-.346-.593z"
                                                                transform="translate(-267 -564.269)"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g
                                                        id="Group_574"
                                                        data-name="Group 574"
                                                        transform="translate(.004)"
                                                    >
                                                        <g
                                                            id="Group_573"
                                                            data-name="Group 573"
                                                        >
                                                            <path
                                                                id="Path_967"
                                                                data-name="Path 967"
                                                                class="cls-1"
                                                                d="M48.739 444.46q-1.38.726-2.756 1.456-2.184 1.157-4.371 2.31l-1 .529h.349L38.21 447.3q-2.184-1.157-4.367-2.31l-1-.529v.6q1.38-.726 2.756-1.456 2.184-1.157 4.367-2.31l1-.529h-.349q1.38.726 2.756 1.456 2.184 1.152 4.367 2.306l1 .529c.394.207.743-.387.349-.6q-1.39-.737-2.784-1.47-2.184-1.157-4.371-2.31c-.318-.169-.636-.349-.961-.508a.458.458 0 0 0-.47.055c-.166.086-.332.176-.494.263l-4.167 2.2c-1.1.581-2.213 1.134-3.3 1.743-.017.01-.035.017-.052.028a.35.35 0 0 0 0 .6q1.39.737 2.784 1.47 2.184 1.157 4.371 2.31c.318.169.636.349.961.508a.465.465 0 0 0 .474-.055c.166-.086.332-.176.494-.263l4.167-2.2c1.1-.581 2.213-1.134 3.3-1.743.017-.01.035-.017.052-.028.386-.21.037-.808-.354-.597z"
                                                                transform="translate(-32.325 -440.124)"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g
                                                        id="Group_576"
                                                        data-name="Group 576"
                                                        transform="translate(4.06 2.151)"
                                                    >
                                                        <g
                                                            id="Group_575"
                                                            data-name="Group 575"
                                                        >
                                                            <path
                                                                id="Path_968"
                                                                data-name="Path 968"
                                                                class="cls-1"
                                                                d="m157.887 502.361-2.753 1.456q-2.184 1.157-4.367 2.31l-1 .529c-.394.207-.045.806.349.6l2.753-1.456q2.184-1.157 4.367-2.31l1-.529c.394-.211.045-.809-.349-.6z"
                                                                transform="translate(-149.595 -502.317)"
                                                            />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g
                                                    id="Group_586"
                                                    data-name="Group 586"
                                                    transform="translate(16.247 12.871)"
                                                >
                                                    <g
                                                        id="Group_579"
                                                        data-name="Group 579"
                                                        transform="translate(.003 4.286)"
                                                    >
                                                        <g
                                                            id="Group_578"
                                                            data-name="Group 578"
                                                        >
                                                            <path
                                                                id="Path_969"
                                                                data-name="Path 969"
                                                                class="cls-1"
                                                                d="m510.745 577.171-2.756-1.452-4.371-2.307-1-.529c.059.1.114.2.173.3v-8.572l-.519.3q1.38.726 2.756 1.456 2.184 1.157 4.371 2.31l1 .529c-.059-.1-.114-.2-.173-.3v8.565a.346.346 0 0 0 .692 0V568.9a.341.341 0 0 0-.173-.3q-1.38-.726-2.756-1.456-2.184-1.157-4.371-2.31l-1-.529a.348.348 0 0 0-.519.3v8.572a.341.341 0 0 0 .173.3l2.756 1.452 4.371 2.306 1 .529c.387.213.74-.385.346-.593z"
                                                                transform="translate(-502.1 -564.268)"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g
                                                        id="Group_581"
                                                        data-name="Group 581"
                                                        transform="translate(8.125 4.286)"
                                                    >
                                                        <g
                                                            id="Group_580"
                                                            data-name="Group 580"
                                                        >
                                                            <path
                                                                id="Path_970"
                                                                data-name="Path 970"
                                                                class="cls-1"
                                                                d="m745.292 572.884-2.753 1.452q-2.184 1.152-4.367 2.307l-1 .529.519.3V568.9c-.059.1-.114.2-.173.3l2.753-1.456q2.184-1.157 4.367-2.31l1-.529-.519-.3v8.569a.346.346 0 0 0 .692 0v-8.569a.348.348 0 0 0-.519-.3l-2.753 1.456q-2.184 1.157-4.367 2.31l-1 .529a.342.342 0 0 0-.173.3v8.565a.348.348 0 0 0 .519.3l2.753-1.452q2.184-1.152 4.367-2.307l1-.529c.398-.206.049-.801-.346-.593z"
                                                                transform="translate(-737 -564.269)"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g
                                                        id="Group_583"
                                                        data-name="Group 583"
                                                    >
                                                        <g
                                                            id="Group_582"
                                                            data-name="Group 582"
                                                        >
                                                            <path
                                                                id="Path_971"
                                                                data-name="Path 971"
                                                                class="cls-1"
                                                                d="m518.443 444.656-2.753 1.456q-2.184 1.157-4.367 2.31l-1 .529h.349q-1.38-.726-2.756-1.456-2.184-1.157-4.371-2.31l-1-.529v.6q1.38-.726 2.756-1.456l4.371-2.306 1-.529h-.349l2.753 1.456q2.184 1.157 4.367 2.31l1 .529c.394.207.743-.387.349-.6l-2.784-1.473q-2.184-1.157-4.371-2.31c-.318-.169-.636-.349-.961-.508a.465.465 0 0 0-.474.055c-.166.086-.332.173-.495.263l-4.167 2.2c-1.1.581-2.213 1.134-3.3 1.739-.017.01-.035.017-.052.028a.35.35 0 0 0 0 .6l2.784 1.473q2.184 1.157 4.371 2.31c.318.169.636.349.961.508a.458.458 0 0 0 .47-.055c.166-.086.332-.176.495-.263l4.163-2.2c1.1-.581 2.213-1.134 3.292-1.739.017-.01.035-.017.052-.028.41-.217.061-.815-.333-.604z"
                                                                transform="translate(-502.025 -440.324)"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g
                                                        id="Group_585"
                                                        data-name="Group 585"
                                                        transform="translate(4.066 2.144)"
                                                    >
                                                        <g
                                                            id="Group_584"
                                                            data-name="Group 584"
                                                        >
                                                            <path
                                                                id="Path_972"
                                                                data-name="Path 972"
                                                                class="cls-1"
                                                                d="m627.887 502.361-2.753 1.456q-2.184 1.157-4.367 2.31l-1 .529c-.394.207-.045.806.349.6l2.753-1.456q2.184-1.157 4.367-2.31l1-.529c.394-.211.045-.809-.349-.6z"
                                                                transform="translate(-619.595 -502.317)"
                                                            />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>

                                    <p>Products</p>
                                </div>
                            </button>
                            <button
                                class="f-nva-blockb"
                                @click="toggleSideDrawerForServices"
                            >
                                <div class="svg-container">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="31.046"
                                        height="31.102"
                                        viewBox="0 0 31.046 31.102"
                                    >
                                        <path
                                            id="noun-service-4579321"
                                            d="M97.166.9a.478.478,0,0,0-.135-.771,5.729,5.729,0,0,0-6.509,1.14,6.259,6.259,0,0,0-1.435,6.355L78.039,18.67a5.654,5.654,0,0,0-1.911-.322,6.184,6.184,0,0,0-4.365,1.792,5.823,5.823,0,0,0-1.227,6.46.479.479,0,0,0,.779.146l3.343-3.36a.553.553,0,0,1,.759-.065l1.443,1.51a.53.53,0,0,1-.021.761l-3.33,3.351a.478.478,0,0,0,.135.77,5.776,5.776,0,0,0,2.481.563,5.657,5.657,0,0,0,4.028-1.7,6.254,6.254,0,0,0,1.434-6.354L92.635,11.17a5.663,5.663,0,0,0,1.912.322A6.184,6.184,0,0,0,98.912,9.7a5.823,5.823,0,0,0,1.227-6.46.477.477,0,0,0-.35-.28.487.487,0,0,0-.428.133l-3.343,3.36a.544.544,0,0,1-.767-.01L93.815,5.008a.528.528,0,0,1,0-.76L97.166.9ZM93.136,5.685,94.572,7.12a1.526,1.526,0,0,0,2.124.009L99.5,4.31a4.875,4.875,0,0,1-1.268,4.716,5.219,5.219,0,0,1-3.686,1.51,4.68,4.68,0,0,1-1.841-.364.478.478,0,0,0-.526.1L80.69,21.764a.477.477,0,0,0-.1.524A5.331,5.331,0,0,1,79.474,27.9,4.745,4.745,0,0,1,74.7,29.1l2.816-2.834a1.481,1.481,0,0,0,.028-2.1L76.1,22.655a1.529,1.529,0,0,0-2.124.059l-2.805,2.819a4.875,4.875,0,0,1,1.268-4.716,5.219,5.219,0,0,1,3.686-1.51,4.68,4.68,0,0,1,1.841.364.477.477,0,0,0,.526-.1L89.985,8.08a.477.477,0,0,0,.1-.524A5.332,5.332,0,0,1,91.2,1.943a4.746,4.746,0,0,1,4.77-1.2L93.137,3.574a1.48,1.48,0,0,0,0,2.111Zm4.849,23.26a.569.569,0,0,1-.792,0l-9.6-9.575a.479.479,0,0,0-.676.679l9.6,9.572a1.513,1.513,0,0,0,2.154-.007l1.368-1.43a1.483,1.483,0,0,0,0-2.112L90.464,16.5a.479.479,0,0,0-.677.677l9.574,9.574a.525.525,0,0,1-.007.768l-1.368,1.43ZM75.623,2.613,72.75-.259a.479.479,0,0,0-.677,0L70.159,1.656a.479.479,0,0,0,0,.677l2.872,2.872a.474.474,0,0,0,.338.141.481.481,0,0,0,.339-.14l.619-.62,7.32,7.32a.479.479,0,1,0,.678-.676L75,3.91l.619-.619a.479.479,0,0,0,0-.678ZM73.369,4.191l-2.2-2.2L72.411.757l2.2,2.2Z"
                                            transform="translate(-69.814 0.674)"
                                            fill="#333"
                                            stroke="#333"
                                            stroke-width="0.3"
                                        />
                                    </svg>

                                    <p>Services</p>
                                </div>
                            </button>
                            <!-- <button
                                class="f-nva-blockb"
                                @click="download"
                                :disabled="
                                    q === 'HD' ||
                                        scene.is_gltf_available === false
                                "
                            >
                                <div class="svg-container">
                                    <svg
                                        data-v-bf5490aa=""
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="31.046"
                                        height="31.102"
                                        viewBox="0 0 20.046 24.102"
                                    >
                                        <g
                                            data-v-bf5490aa=""
                                            id="noun-download-2251523"
                                            transform="matrix(1, 0.017, -0.017, 1, -106.817, -40.69)"
                                        >
                                            <path
                                                data-v-bf5490aa=""
                                                id="Path_2693"
                                                data-name="Path 2693"
                                                d="M167.978,38.98a.263.263,0,0,0-.23.271V57.128l-7.85-5.411a.26.26,0,1,0-.3.427l8.261,5.7a.263.263,0,0,0,.3,0l8.261-5.7a.263.263,0,1,0-.3-.427l-7.85,5.411V39.252a.263.263,0,0,0-.3-.271ZM159.29,61.062a.264.264,0,1,0,.049.526h17.343a.263.263,0,1,0,0-.526H159.34A.246.246,0,0,0,159.29,61.062Z"
                                                transform="translate(-50.973 0)"
                                                fill="#333"
                                                stroke="#333"
                                                stroke-width="0.33"
                                            ></path>
                                        </g>
                                    </svg>
                                    <p>Download</p>
                                </div>
                            </button> -->
                            <!-- <button class="f-nva-blockb" @click="changeView">
                            <font-awesome-icon icon="camera" />
                            <p>Rotate</p>
                        </button> -->
                        </div>
                        <!-- <button class="f-nva-blockb">
                        <font-awesome-icon icon="ellipsis-h" />
                        <p>More</p>
                    </button> -->
                    </div>
                    <div class="f-room-finalize">
                        <Button
                            name="Finalise this Room "
                            primary
                            class="f-customisation-button"
                            :data-scene-id="scene.id"
                            @click.native="postSQCompletion"
                        >
                            <template #icon-left>
                                <svg
                                    data-name="icon/action/check_circle_outline_24px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                >
                                    <path style="fill:none" d="M0 0h24v24H0z" />
                                    <path
                                        data-name="↳Color"
                                        d="M10 20a10 10 0 1 1 10-10 10.011 10.011 0 0 1-10 10zm0-18a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8zM8 15l-5-5 1.41-1.41L8 12.17l6.59-6.59L16 7l-8 8z"
                                        transform="translate(2 2)"
                                        style="fill:#fff"
                                    />
                                </svg>
                            </template>
                        </Button>
                    </div>
                    <!-- footer navingarion component -->

                    <template
                        v-if="q === 'SD' && scene.is_gltf_available === true"
                    >
                        <iframe
                            src="https://uat-uportal.siloho.com/"
                            id="low-render-frame"
                            width="100%"
                            height="100%"
                        />
                    </template>
                    <template v-else>
                        <div
                            v-if="
                                scenePanoImage != '' &&
                                    scene.is_pano_customizable == true
                            "
                            style="position: relative; height: 100%; width: 100%; overflow: hidden;"
                        >
                            <div v-if="hqImageLoader" class="f-image-loader">
                                <div class="spinner"></div>
                                <span>
                                    Generating high quality image
                                </span>
                            </div>
                            <PanoImage
                                v-if="scenePanoImage != ''"
                                :source="scenePanoImage"
                                :replaceable="clientSceneProduct"
                                @replace="replaceProduct"
                                @remove="deleteProduct"
                                @restore="restore"
                            />
                            <div
                                v-for="(product, index) in clientSceneProduct"
                                :key="index + '_' + product.product_id"
                                class="hotspot-wrapper"
                            >
                                <template
                                    v-if="
                                        product.is_pano_replaceable == true &&
                                            product.is_duplicate == false
                                    "
                                >
                                    <!-- <template v-if="product.is_active == false">
                                    <div
                                        class="hotspot hotspot-replace"
                                        :style="{
                                            left: product.xperc * 100 + '%',
                                            top: product.yperc * 100 + '%',
                                        }"
                                        @click="deleteProduct"
                                        :data-index="index"
                                        :data-id="product.product_id"
                                        :data-xloc="product.xperc"
                                        :data-yloc="product.yperc"
                                        :data-zloc="product.zperc"
                                        data-available="true"
                                    >
                                        <svg
                                            class="restore-icon"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="13.846"
                                            height="12"
                                        >
                                            <g data-name="Group 733">
                                                <path
                                                    data-name="Path 1023"
                                                    d="M10.9 11A5.949 5.949 0 0 0 5 17a6.037 6.037 0 0 0 6 6c.012 0 .007-2.309 0-2.308A3.715 3.715 0 0 1 7.051 17a3.88 3.88 0 0 1 3.849-3.913 3.816 3.816 0 0 1 3.767 3.452h-2.282l3.231 3.231 3.231-3.231h-2.075A5.936 5.936 0 0 0 10.9 11z"
                                                    transform="translate(-5 -11)"
                                                    style="fill:#d1d1d1"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                </template>
                                <template v-else> -->
                                    <div
                                        :id="`${product.product_id}_pano`"
                                        class="popper new-product-popup"
                                    >
                                        <div
                                            class="new-product-tab-content-wrapper"
                                        >
                                            <div
                                                class="new-product-tab-content"
                                            >
                                                <div class="new-product-img">
                                                    <img
                                                        :src="product.image_url"
                                                        :alt="product.name"
                                                    />
                                                </div>
                                                <div class="new-product-info">
                                                    <p class="product-name">
                                                        {{ product.name }}
                                                    </p>
                                                    <p
                                                        class="product-price"
                                                        v-if="
                                                            product.item_price !=
                                                                0
                                                        "
                                                        :class="
                                                            requiredDomain ==
                                                            false
                                                                ? 'blur'
                                                                : ''
                                                        "
                                                    >
                                                        MRP: ₹{{
                                                            convertToIndianNumberSystem(
                                                                product.item_price,
                                                            )
                                                        }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="new-product-tab-action">
                                                <div
                                                    class="new-remove"
                                                    data-type="popup"
                                                    :id="
                                                        `${product.product_id}_pano_remove`
                                                    "
                                                    :data-index="index"
                                                    :data-id="
                                                        product.product_id
                                                    "
                                                    :data-xloc="product.xperc"
                                                    :data-yloc="product.yperc"
                                                    :data-zloc="product.zperc"
                                                    data-available="false"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="11.326"
                                                        height="14.375"
                                                        class="new-remove-icon"
                                                    >
                                                        <g
                                                            id="noun_dustbin_3666639"
                                                            transform="translate(-14 -7)"
                                                        >
                                                            <g
                                                                id="Group_493"
                                                                data-name="Group 493"
                                                                transform="translate(14 7)"
                                                            >
                                                                <path
                                                                    id="Path_844"
                                                                    data-name="Path 844"
                                                                    class="cls-1"
                                                                    d="m17.35 21 1.2 10.363a1.089 1.089 0 0 0 1.082.963h5.298a1.089 1.089 0 0 0 1.082-.963L27.216 21zm3.191 9.339a.106.106 0 0 1-.026 0 .219.219 0 0 1-.216-.194l-.873-7.793a.218.218 0 0 1 .433-.048l.873 7.793a.217.217 0 0 1-.191.242zm1.96-.316a.218.218 0 1 1-.436 0v-7.84a.218.218 0 0 1 .436 0zm1.766.124a.219.219 0 0 1-.216.194.106.106 0 0 1-.026 0 .217.217 0 0 1-.192-.24l.873-7.793a.218.218 0 0 1 .433.048z"
                                                                    transform="translate(-16.62 -17.951)"
                                                                />
                                                                <path
                                                                    id="Path_845"
                                                                    data-name="Path 845"
                                                                    class="cls-1"
                                                                    d="M23.8 7.871h-2.2A1.089 1.089 0 0 0 20.534 7h-1.742a1.093 1.093 0 0 0-1.067.871h-2.2A1.526 1.526 0 0 0 14 9.4a.218.218 0 0 0 .218.218h10.89a.218.218 0 0 0 .218-.218A1.526 1.526 0 0 0 23.8 7.871zm-5.009-.436h1.742a.656.656 0 0 1 .616.436h-2.974a.656.656 0 0 1 .617-.435z"
                                                                    transform="translate(-14 -7)"
                                                                />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                    <p>REMOVE</p>
                                                </div>
                                                <div
                                                    class="new-change"
                                                    data-type="popup"
                                                    :id="
                                                        `${product.product_id}_pano_replace`
                                                    "
                                                    :data-index="index"
                                                    :data-id="
                                                        product.product_id
                                                    "
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="20.027"
                                                        height="14.375"
                                                        class="change-icon"
                                                    >
                                                        <g
                                                            id="noun_change_435217"
                                                            transform="translate(-5 -17.7)"
                                                        >
                                                            <path
                                                                id="Path_846"
                                                                data-name="Path 846"
                                                                class="cls-1"
                                                                d="M42.453 20.148V17.7L36.4 21.772l6.053 4.072V23.2a22.127 22.127 0 0 1 2.715.134c3.382.4 4.273 2.515 4.273 2.515 0-5.163-6.987-5.7-6.987-5.7"
                                                                transform="translate(-24.413)"
                                                            />
                                                            <path
                                                                id="Path_847"
                                                                data-name="Path 847"
                                                                class="cls-1"
                                                                d="M11.987 48.348a22.126 22.126 0 0 1-2.715-.134C5.89 47.814 5 45.7 5 45.7c0 5.163 6.987 5.7 6.987 5.7v2.448l6.053-4.072-6.053-4.076z"
                                                                transform="translate(0 -21.769)"
                                                            />
                                                        </g>
                                                    </svg>
                                                    <p
                                                        class="new-change-product"
                                                    >
                                                        CHANGE
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- </template> -->
                                </template>
                            </div>
                            <div
                                v-if="
                                    scene.is_gltf_available === false &&
                                        q === 'SD'
                                "
                                class="light-not-overlay"
                            >
                                Light version is not available for this scene
                            </div>
                        </div>
                        <div v-else class="scene-image-wrapper">
                            <img :src="sceneImage" />
                            <div
                                v-for="(product, index) in clientSceneProduct"
                                :key="index + '_' + product.product_id"
                                class="hotspot-wrapper"
                            >
                                <template
                                    v-if="
                                        product.is_pano_replaceable == true &&
                                            product.is_duplicate == false
                                    "
                                >
                                    <template v-if="product.is_active == false">
                                        <div
                                            class="hotspot hotspot-replace"
                                            :style="{
                                                left: product.xperc * 100 + '%',
                                                top: product.yperc * 100 + '%',
                                            }"
                                            @click="deleteProduct"
                                            :data-index="index"
                                            :data-id="product.product_id"
                                            :data-xloc="product.xperc"
                                            :data-yloc="product.yperc"
                                            :data-zloc="product.zperc"
                                            data-available="true"
                                            :data-product-loc-id="
                                                product.product_loc_id
                                            "
                                        >
                                            <svg
                                                class="restore-icon"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="13.846"
                                                height="12"
                                            >
                                                <g data-name="Group 733">
                                                    <path
                                                        data-name="Path 1023"
                                                        d="M10.9 11A5.949 5.949 0 0 0 5 17a6.037 6.037 0 0 0 6 6c.012 0 .007-2.309 0-2.308A3.715 3.715 0 0 1 7.051 17a3.88 3.88 0 0 1 3.849-3.913 3.816 3.816 0 0 1 3.767 3.452h-2.282l3.231 3.231 3.231-3.231h-2.075A5.936 5.936 0 0 0 10.9 11z"
                                                        transform="translate(-5 -11)"
                                                        style="fill:#d1d1d1"
                                                    />
                                                </g>
                                            </svg>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <Popper
                                            trigger="clickToToggle"
                                            :options="{
                                                placement: 'top',
                                                modifiers: {
                                                    offset: {
                                                        offset: '0,16px',
                                                    },
                                                },
                                            }"
                                        >
                                            <div
                                                :id="product.product_id"
                                                class="popper new-product-popup"
                                            >
                                                <div
                                                    class="new-product-tab-content-wrapper"
                                                >
                                                    <div
                                                        class="new-product-tab-content"
                                                    >
                                                        <div
                                                            class="new-product-img"
                                                        >
                                                            <img
                                                                :src="
                                                                    product.image_url
                                                                "
                                                                :alt="
                                                                    product.name
                                                                "
                                                            />
                                                        </div>
                                                        <div
                                                            class="new-product-info"
                                                        >
                                                            <p
                                                                class="product-name"
                                                            >
                                                                {{
                                                                    product.name
                                                                }}
                                                            </p>
                                                            <p
                                                                class="product-price"
                                                                v-if="
                                                                    product.item_price !=
                                                                        0
                                                                "
                                                                :class="
                                                                    requiredDomain ==
                                                                    false
                                                                        ? 'blur'
                                                                        : ''
                                                                "
                                                            >
                                                                MRP: ₹{{
                                                                    convertToIndianNumberSystem(
                                                                        product.item_price,
                                                                    )
                                                                }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="new-product-tab-action"
                                                    >
                                                        <div
                                                            class="new-remove"
                                                            @click="
                                                                deleteProduct
                                                            "
                                                            data-type="popup"
                                                            :data-index="index"
                                                            :data-id="
                                                                product.product_id
                                                            "
                                                            :data-xloc="
                                                                product.xperc
                                                            "
                                                            :data-yloc="
                                                                product.yperc
                                                            "
                                                            :data-zloc="
                                                                product.zperc
                                                            "
                                                            data-available="false"
                                                            :data-product-loc-id="
                                                                product.product_loc_id
                                                            "
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="11.326"
                                                                height="14.375"
                                                                class="new-remove-icon"
                                                            >
                                                                <g
                                                                    id="noun_dustbin_3666639"
                                                                    transform="translate(-14 -7)"
                                                                >
                                                                    <g
                                                                        id="Group_493"
                                                                        data-name="Group 493"
                                                                        transform="translate(14 7)"
                                                                    >
                                                                        <path
                                                                            id="Path_844"
                                                                            data-name="Path 844"
                                                                            class="cls-1"
                                                                            d="m17.35 21 1.2 10.363a1.089 1.089 0 0 0 1.082.963h5.298a1.089 1.089 0 0 0 1.082-.963L27.216 21zm3.191 9.339a.106.106 0 0 1-.026 0 .219.219 0 0 1-.216-.194l-.873-7.793a.218.218 0 0 1 .433-.048l.873 7.793a.217.217 0 0 1-.191.242zm1.96-.316a.218.218 0 1 1-.436 0v-7.84a.218.218 0 0 1 .436 0zm1.766.124a.219.219 0 0 1-.216.194.106.106 0 0 1-.026 0 .217.217 0 0 1-.192-.24l.873-7.793a.218.218 0 0 1 .433.048z"
                                                                            transform="translate(-16.62 -17.951)"
                                                                        />
                                                                        <path
                                                                            id="Path_845"
                                                                            data-name="Path 845"
                                                                            class="cls-1"
                                                                            d="M23.8 7.871h-2.2A1.089 1.089 0 0 0 20.534 7h-1.742a1.093 1.093 0 0 0-1.067.871h-2.2A1.526 1.526 0 0 0 14 9.4a.218.218 0 0 0 .218.218h10.89a.218.218 0 0 0 .218-.218A1.526 1.526 0 0 0 23.8 7.871zm-5.009-.436h1.742a.656.656 0 0 1 .616.436h-2.974a.656.656 0 0 1 .617-.435z"
                                                                            transform="translate(-14 -7)"
                                                                        />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                            <p>REMOVE</p>
                                                        </div>
                                                        <div
                                                            class="new-change"
                                                            @click="
                                                                replaceProduct
                                                            "
                                                            data-type="popup"
                                                            :data-index="index"
                                                            :data-id="
                                                                product.product_id
                                                            "
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="20.027"
                                                                height="14.375"
                                                                class="change-icon"
                                                            >
                                                                <g
                                                                    id="noun_change_435217"
                                                                    transform="translate(-5 -17.7)"
                                                                >
                                                                    <path
                                                                        id="Path_846"
                                                                        data-name="Path 846"
                                                                        class="cls-1"
                                                                        d="M42.453 20.148V17.7L36.4 21.772l6.053 4.072V23.2a22.127 22.127 0 0 1 2.715.134c3.382.4 4.273 2.515 4.273 2.515 0-5.163-6.987-5.7-6.987-5.7"
                                                                        transform="translate(-24.413)"
                                                                    />
                                                                    <path
                                                                        id="Path_847"
                                                                        data-name="Path 847"
                                                                        class="cls-1"
                                                                        d="M11.987 48.348a22.126 22.126 0 0 1-2.715-.134C5.89 47.814 5 45.7 5 45.7c0 5.163 6.987 5.7 6.987 5.7v2.448l6.053-4.072-6.053-4.076z"
                                                                        transform="translate(0 -21.769)"
                                                                    />
                                                                </g>
                                                            </svg>
                                                            <p
                                                                class="new-change-product"
                                                            >
                                                                CHANGE
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <template slot="reference">
                                                <div
                                                    class="hotspot hotspot-default"
                                                    :style="{
                                                        left:
                                                            product.xperc *
                                                                100 +
                                                            '%',
                                                        top:
                                                            product.yperc *
                                                                100 +
                                                            '%',
                                                    }"
                                                    :data-index="index"
                                                    :data-id="
                                                        product.product_id
                                                    "
                                                    :data-replace="
                                                        product.false
                                                    "
                                                >
                                                    <div class="pulse"></div>
                                                </div>
                                            </template>
                                        </Popper>
                                    </template>
                                </template>
                            </div>
                            <div
                                v-if="
                                    scene.is_gltf_available === false &&
                                        q === 'SD'
                                "
                                class="light-not-overlay"
                            >
                                Light version is not available for this scene
                            </div>
                        </div>
                    </template>
                </div>
            </transition>

            <SideDrawer v-if="showDrawer" @close="toggleSideDrawer">
                <div class="scene-detail-block">
                    <p>
                        <span>Space</span><span>: {{ scene.room_type }}</span>
                    </p>
                    <p>
                        Style: <span>{{ scene.room_type }}</span>
                    </p>
                    <p>
                        <span>Designed by</span
                        ><span>: {{ scene.designer_name }}</span>
                    </p>
                    <div class="scene-meta-block">
                        <div>
                            <p>
                                {{
                                    clientSceneProduct.filter(
                                        value => value.is_active === true,
                                    ).length
                                }}
                                Products
                            </p>
                            <p :class="requiredDomain == false ? 'blur' : ''">
                                ₹{{
                                    convertToIndianNumberSystem(
                                        scene.furnishing_cost +
                                            scene.decor_cost,
                                    )
                                }}
                            </p>
                        </div>
                        <div>
                            <p>
                                {{
                                    scene.services_data.filter(
                                        value => value.active === true,
                                    ).length
                                }}
                                Services
                            </p>
                            <p :class="requiredDomain == false ? 'blur' : ''">
                                ₹{{
                                    convertToIndianNumberSystem(
                                        scene.total_price_of_services,
                                    )
                                }}
                            </p>
                        </div>
                        <!-- <div>
                        <p>Discount</p>
                        <p>-20,000</p>
                    </div> -->
                        <hr />
                        <div class="scene-total-amount">
                            <p>Total</p>
                            <p :class="requiredDomain == false ? 'blur' : ''">
                                ₹{{
                                    convertToIndianNumberSystem(
                                        scene.furnishing_cost +
                                            scene.total_price_of_services +
                                            scene.decor_cost,
                                    )
                                }}
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    <Tabs :isselected="tabselect" :fullWidth="true">
                        <Tab
                            :title="
                                `Products (${
                                    clientSceneProduct.filter(
                                        value => value.is_active === true,
                                    ).length
                                })`
                            "
                        >
                            <div
                                v-for="(product,
                                index) in totalProductsCustomisation.active"
                                :key="index + '_' + product.product_id"
                                class="product-wrapper"
                            >
                                <div class="product-tab-content-wrapper">
                                    <div class="product-tab-content">
                                        <div class="product-img">
                                            <img
                                                :src="product.image_url"
                                                :alt="product.name"
                                            />
                                        </div>
                                        <div class="product-info">
                                            <div class="product-text">
                                                <p>{{ product.name }}</p>
                                                <p
                                                    v-if="product.item_price"
                                                    :class="
                                                        requiredDomain == false
                                                            ? 'blur'
                                                            : ''
                                                    "
                                                >
                                                    MRP: ₹{{
                                                        convertToIndianNumberSystem(
                                                            product.item_price,
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-tab-action">
                                        <div
                                            class="remove"
                                            @click="deleteProduct"
                                            data-type="drawer"
                                            :data-index="index"
                                            :data-id="product.product_id"
                                            :data-xloc="product.xperc"
                                            :data-yloc="product.yperc"
                                            :data-zloc="product.zperc"
                                            data-available="false"
                                            :data-product-loc-id="
                                                product.product_loc_id
                                            "
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="11.326"
                                                height="14.375"
                                                class="dustbin-icon"
                                            >
                                                <g
                                                    id="noun_dustbin_3666639"
                                                    transform="translate(-14 -7)"
                                                >
                                                    <g
                                                        id="Group_493"
                                                        data-name="Group 493"
                                                        transform="translate(14 7)"
                                                    >
                                                        <path
                                                            id="Path_844"
                                                            data-name="Path 844"
                                                            class="cls-1"
                                                            d="m17.35 21 1.2 10.363a1.089 1.089 0 0 0 1.082.963h5.298a1.089 1.089 0 0 0 1.082-.963L27.216 21zm3.191 9.339a.106.106 0 0 1-.026 0 .219.219 0 0 1-.216-.194l-.873-7.793a.218.218 0 0 1 .433-.048l.873 7.793a.217.217 0 0 1-.191.242zm1.96-.316a.218.218 0 1 1-.436 0v-7.84a.218.218 0 0 1 .436 0zm1.766.124a.219.219 0 0 1-.216.194.106.106 0 0 1-.026 0 .217.217 0 0 1-.192-.24l.873-7.793a.218.218 0 0 1 .433.048z"
                                                            transform="translate(-16.62 -17.951)"
                                                        />
                                                        <path
                                                            id="Path_845"
                                                            data-name="Path 845"
                                                            class="cls-1"
                                                            d="M23.8 7.871h-2.2A1.089 1.089 0 0 0 20.534 7h-1.742a1.093 1.093 0 0 0-1.067.871h-2.2A1.526 1.526 0 0 0 14 9.4a.218.218 0 0 0 .218.218h10.89a.218.218 0 0 0 .218-.218A1.526 1.526 0 0 0 23.8 7.871zm-5.009-.436h1.742a.656.656 0 0 1 .616.436h-2.974a.656.656 0 0 1 .617-.435z"
                                                            transform="translate(-14 -7)"
                                                        />
                                                    </g>
                                                </g>
                                            </svg>
                                            <p>REMOVE</p>
                                        </div>
                                        <div
                                            v-if="
                                                product.view_in_use ===
                                                    'Close' &&
                                                    product.open_view_available ===
                                                        true
                                            "
                                            class="change"
                                            @click="toggleView(product)"
                                            data-type="drawer"
                                        >
                                            <p class="change-product">
                                                Open
                                            </p>
                                        </div>
                                        <div
                                            v-if="
                                                product.view_in_use ===
                                                    'Open' &&
                                                    product.open_view_available ===
                                                        true
                                            "
                                            class="change"
                                            @click="toggleView(product)"
                                            data-type="drawer"
                                        >
                                            <p class="change-product">
                                                Close
                                            </p>
                                        </div>
                                        <div
                                            class="change"
                                            @click="replaceProduct"
                                            data-type="drawer"
                                            :data-index="index"
                                            :data-id="product.product_id"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20.027"
                                                height="14.375"
                                                class="change-icon"
                                            >
                                                <g
                                                    id="noun_change_435217"
                                                    transform="translate(-5 -17.7)"
                                                >
                                                    <path
                                                        id="Path_846"
                                                        data-name="Path 846"
                                                        class="cls-1"
                                                        d="M42.453 20.148V17.7L36.4 21.772l6.053 4.072V23.2a22.127 22.127 0 0 1 2.715.134c3.382.4 4.273 2.515 4.273 2.515 0-5.163-6.987-5.7-6.987-5.7"
                                                        transform="translate(-24.413)"
                                                    />
                                                    <path
                                                        id="Path_847"
                                                        data-name="Path 847"
                                                        class="cls-1"
                                                        d="M11.987 48.348a22.126 22.126 0 0 1-2.715-.134C5.89 47.814 5 45.7 5 45.7c0 5.163 6.987 5.7 6.987 5.7v2.448l6.053-4.072-6.053-4.076z"
                                                        transform="translate(0 -21.769)"
                                                    />
                                                </g>
                                            </svg>

                                            <p class="change-product">
                                                CHANGE
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p
                                class="removed-products"
                                v-if="
                                    totalProductsCustomisation.removed.length >
                                        0
                                "
                            >
                                Unused Products
                            </p>
                            <div
                                v-for="(product,
                                index) in totalProductsCustomisation.removed"
                                :key="index + '_' + product.product_id"
                                class="product-wrapper"
                            >
                                <div
                                    class="product-tab-content-wrapper product-tab-delete"
                                >
                                    <div class="product-tab-content">
                                        <div class="product-img">
                                            <img
                                                :src="product.image_url"
                                                :alt="product.name"
                                            />
                                        </div>
                                        <div class="product-info">
                                            <div class="product-text">
                                                <p>{{ product.name }}</p>
                                                <p
                                                    v-if="
                                                        product.item_price != 0
                                                    "
                                                    :class="
                                                        requiredDomain == false
                                                            ? 'blur'
                                                            : ''
                                                    "
                                                >
                                                    MRP: ₹{{
                                                        convertToIndianNumberSystem(
                                                            product.item_price,
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            class="overlay-delete deleted-product"
                                        ></div>
                                    </div>
                                    <div class="product-tab-action">
                                        <div
                                            class="restore"
                                            @click="deleteProduct"
                                            data-type="drawer"
                                            :data-index="index"
                                            :data-id="product.product_id"
                                            :data-xloc="product.xperc"
                                            :data-yloc="product.yperc"
                                            :data-zloc="product.zperc"
                                            data-available="true"
                                            :data-product-loc-id="
                                                product.product_loc_id
                                            "
                                        >
                                            <svg
                                                class="restore-icon"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="13.846"
                                                height="12"
                                            >
                                                <g data-name="Group 733">
                                                    <path
                                                        data-name="Path 1023"
                                                        d="M10.9 11A5.949 5.949 0 0 0 5 17a6.037 6.037 0 0 0 6 6c.012 0 .007-2.309 0-2.308A3.715 3.715 0 0 1 7.051 17a3.88 3.88 0 0 1 3.849-3.913 3.816 3.816 0 0 1 3.767 3.452h-2.282l3.231 3.231 3.231-3.231h-2.075A5.936 5.936 0 0 0 10.9 11z"
                                                        transform="translate(-5 -11)"
                                                        style="fill:#d1d1d1"
                                                    />
                                                </g>
                                            </svg>

                                            <p>RESTORE</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab
                            :title="
                                `Services (${
                                    scene.services_data.filter(
                                        value => value.active === true,
                                    ).length
                                })`
                            "
                        >
                            <div
                                v-for="(service,
                                index) in totalServicesCustomisation.active"
                                :key="index + '_' + service.id"
                            >
                                <div
                                    class="service-tab-content-wrapper"
                                    :class="
                                        service.active
                                            ? ''
                                            : 'service-tab-removed'
                                    "
                                >
                                    <div class="service-tab-content">
                                        <div class="service-img">
                                            <div class="service-img-wrapper">
                                                <img
                                                    :src="
                                                        service.service_icon_url
                                                            ? service.service_icon_url
                                                            : require('@/assets/images/enable_service.png')
                                                    "
                                                    :alt="service.name"
                                                />
                                            </div>
                                        </div>
                                        <div class="service-info">
                                            <div class="service-text">
                                                <h2>{{ service.name }}</h2>
                                                <div class="price-rate">
                                                    <p
                                                        class="price"
                                                        v-if="
                                                            service.price != 0
                                                        "
                                                        :class="
                                                            requiredDomain ==
                                                            false
                                                                ? 'blur'
                                                                : ''
                                                        "
                                                    >
                                                        ₹{{
                                                            convertToIndianNumberSystem(
                                                                service.price,
                                                            )
                                                        }}
                                                    </p>
                                                    <p class="info-badges">
                                                        <span
                                                            :class="
                                                                requiredDomain ==
                                                                false
                                                                    ? 'blur'
                                                                    : ''
                                                            "
                                                        >
                                                            ₹{{
                                                                convertToIndianNumberSystem(
                                                                    service.rate,
                                                                )
                                                            }}*
                                                            {{ service.unit }}
                                                        </span>
                                                    </p>
                                                </div>

                                                <p class="description">
                                                    {{ service.description }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="remove-service">
                                    <div
                                        class="remove"
                                        @click="
                                            deleteService(
                                                service.id,
                                                false,
                                                scene.id,
                                            )
                                        "
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="11.326"
                                            height="14.375"
                                            class="remove-icon"
                                        >
                                            <g
                                                id="noun_dustbin_3666639"
                                                transform="translate(-14 -7)"
                                            >
                                                <g
                                                    id="Group_493"
                                                    data-name="Group 493"
                                                    transform="translate(14 7)"
                                                >
                                                    <path
                                                        id="Path_844"
                                                        data-name="Path 844"
                                                        class="cls-1"
                                                        d="m17.35 21 1.2 10.363a1.089 1.089 0 0 0 1.082.963h5.298a1.089 1.089 0 0 0 1.082-.963L27.216 21zm3.191 9.339a.106.106 0 0 1-.026 0 .219.219 0 0 1-.216-.194l-.873-7.793a.218.218 0 0 1 .433-.048l.873 7.793a.217.217 0 0 1-.191.242zm1.96-.316a.218.218 0 1 1-.436 0v-7.84a.218.218 0 0 1 .436 0zm1.766.124a.219.219 0 0 1-.216.194.106.106 0 0 1-.026 0 .217.217 0 0 1-.192-.24l.873-7.793a.218.218 0 0 1 .433.048z"
                                                        transform="translate(-16.62 -17.951)"
                                                    />
                                                    <path
                                                        id="Path_845"
                                                        data-name="Path 845"
                                                        class="cls-1"
                                                        d="M23.8 7.871h-2.2A1.089 1.089 0 0 0 20.534 7h-1.742a1.093 1.093 0 0 0-1.067.871h-2.2A1.526 1.526 0 0 0 14 9.4a.218.218 0 0 0 .218.218h10.89a.218.218 0 0 0 .218-.218A1.526 1.526 0 0 0 23.8 7.871zm-5.009-.436h1.742a.656.656 0 0 1 .616.436h-2.974a.656.656 0 0 1 .617-.435z"
                                                        transform="translate(-14 -7)"
                                                    />
                                                </g>
                                            </g>
                                        </svg>

                                        <p>REMOVE</p>
                                    </div>
                                </div>
                            </div>
                            <p
                                class="removed-services"
                                v-if="
                                    totalServicesCustomisation.removed.length >
                                        0
                                "
                            >
                                Unused Services
                            </p>
                            <div
                                v-for="(service,
                                index) in totalServicesCustomisation.removed"
                                :key="index + '_' + service.id"
                            >
                                <div
                                    class="service-tab-content-wrapper service-tab-removed"
                                >
                                    <div class="service-tab-content">
                                        <div class="service-img">
                                            <div
                                                class="service-img-wrapper service-img-wrapper-removed"
                                            >
                                                <img
                                                    :src="
                                                        service.service_icon_url
                                                            ? service.service_icon_url
                                                            : require('@/assets/images/disable_service.png')
                                                    "
                                                    :alt="service.name"
                                                />
                                            </div>
                                        </div>
                                        <div class="service-info">
                                            <div class="service-text">
                                                <h2>{{ service.name }}</h2>
                                                <div class="price-rate">
                                                    <p
                                                        class="price"
                                                        v-if="
                                                            service.price != 0
                                                        "
                                                        :class="
                                                            requiredDomain ==
                                                            false
                                                                ? 'blur'
                                                                : ''
                                                        "
                                                    >
                                                        ₹{{
                                                            convertToIndianNumberSystem(
                                                                service.price,
                                                            )
                                                        }}
                                                    </p>
                                                    <p class="info-badges">
                                                        <span
                                                            :class="
                                                                requiredDomain ==
                                                                false
                                                                    ? 'blur'
                                                                    : ''
                                                            "
                                                        >
                                                            ₹{{
                                                                convertToIndianNumberSystem(
                                                                    service.rate,
                                                                )
                                                            }}*
                                                            {{ service.unit }}
                                                        </span>
                                                    </p>
                                                </div>

                                                <p class="description">
                                                    {{ service.description }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="remove-service">
                                    <div
                                        class="remove"
                                        @click="
                                            deleteService(
                                                service.id,
                                                true,
                                                scene.id,
                                            )
                                        "
                                        v-if="service.active != true"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="13.846"
                                            height="12"
                                        >
                                            <g data-name="Group 733">
                                                <path
                                                    data-name="Path 1023"
                                                    d="M10.9 11A5.949 5.949 0 0 0 5 17a6.037 6.037 0 0 0 6 6c.012 0 .007-2.309 0-2.308A3.715 3.715 0 0 1 7.051 17a3.88 3.88 0 0 1 3.849-3.913 3.816 3.816 0 0 1 3.767 3.452h-2.282l3.231 3.231 3.231-3.231h-2.075A5.936 5.936 0 0 0 10.9 11z"
                                                    transform="translate(-5 -11)"
                                                    style="fill:#d1d1d1"
                                                />
                                            </g>
                                        </svg>

                                        <p>RESTORE</p>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </SideDrawer>
            <!-- <ReplaceProductModal
                v-if="replaceProductModal"
                :currentProductID="Number(currentProductId)"
                :currentProductImage="
                    clientSceneProduct[currentProductIndex].image_meta
                        ? clientSceneProduct[currentProductIndex].image_meta
                              .p_image
                        : require('@/assets/images/default-product-img.png')
                "
                :currentProductName="
                    clientSceneProduct[currentProductIndex].name
                "
                :requiredDomain="requiredDomain"
                :currentProductPrice="
                    clientSceneProduct[currentProductIndex].item_price
                "
                :currentProductShow="true"
                :currentProductDescription="
                    clientSceneProduct[currentProductIndex].description
                "
                :currentSceneId="sceneId"
                :add="false"
                @setLoader="setLoader"
                @toggleReplaceProductModal="toggleReplaceProductModal"
                @changeProductForEHD="changeProduct"
            /> -->

            <FullPageModal
                v-if="replaceProductModal"
                @close="toggleReplaceProductModal"
                @loadMoreProducts="loadMoreProducts"
                :replaceProduct="true"
                class="replace-product-modal"
            >
                <div class="productlist-layout">
                    <div class="sidebar">
                        <div class="sidebar-item">
                            <router-link
                                :to="{
                                    name: 'ProductDetailsPage',
                                    params: {
                                        id:
                                            clientSceneProduct[
                                                currentProductIndex
                                            ].product_id,
                                    },
                                }"
                            >
                                <CurrentProduct
                                    :requiredDomain="requiredDomain"
                                    :img="
                                        clientSceneProduct[currentProductIndex]
                                            .image_url
                                            ? clientSceneProduct[
                                                  currentProductIndex
                                              ].image_url
                                            : require('@/assets/images/default-product-img.png')
                                    "
                                    :productName="
                                        clientSceneProduct[currentProductIndex]
                                            .name
                                    "
                                    :productPrice="
                                        clientSceneProduct[currentProductIndex]
                                            .item_price
                                    "
                                    :description="
                                        clientSceneProduct[currentProductIndex]
                                            .description
                                    "
                                />
                            </router-link>
                            <FiltersBox
                                :filters="filters"
                                @filter="getFilteredProduct"
                                v-if="windowWidth > 1100"
                            />
                        </div>
                    </div>
                    <div class="main">
                        <template v-if="similarProducts.length > 0">
                            <div class="productlist-head">
                                <h1>Replace with</h1>
                                <SearchAutoComplete
                                    @search="fetchSuggestions"
                                    @loadMoreSearchOptions="
                                        loadMoreSearchOptions
                                    "
                                    :suggestionsArr="suggestions"
                                >
                                    <template #suggestion="{suggestion}">
                                        <div class="autocomplete-product">
                                            <div
                                                class="autocomplete-product-img"
                                            >
                                                <img :src="suggestion.image" />
                                            </div>
                                            <div
                                                class="autocomplete-product-detail"
                                            >
                                                <p>{{ suggestion.name }}</p>
                                                <div
                                                    class="autocomplete-category"
                                                >
                                                    <span>
                                                        {{
                                                            suggestion.item_category
                                                        }}
                                                    </span>
                                                    <template
                                                        v-if="
                                                            suggestion.item_subcategory
                                                        "
                                                    >
                                                        <span>
                                                            <svg
                                                                width="25"
                                                                height="24"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M10.283 6 8.875 7.41 13.449 12l-4.574 4.59L10.283 18l5.992-6-5.992-6z"
                                                                    fill="#000"
                                                                />
                                                            </svg>
                                                        </span>
                                                        <span>
                                                            {{
                                                                suggestion.item_subcategory
                                                            }}
                                                        </span>
                                                    </template>
                                                </div>
                                            </div>
                                            <div
                                                class="autocomplete-product-action"
                                            >
                                                <Button
                                                    class="autocomplete-add"
                                                    name="Replace"
                                                    primary
                                                    @click.native="
                                                        changeProduct(
                                                            suggestion,
                                                        )
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </template>
                                </SearchAutoComplete>
                            </div>
                            <div class="cards-container">
                                <div
                                    v-for="product in similarProducts"
                                    :key="product.id"
                                >
                                    <ProductCards
                                        :threeDavailable="
                                            product.is_3d_available
                                        "
                                        :dimRemarks="product.dim_remarks"
                                        :product-obj="product"
                                        :vendor="product.vendor"
                                        :product-id="product.product_id"
                                        :img="
                                            product.image_meta
                                                ? product.image_meta.n_image
                                                : require('@/assets/images/default-product-img.png')
                                        "
                                        :name="product.name"
                                        :current-price="product.item_price"
                                        :requiredDomain="requiredDomain"
                                        :bestSeller="product.bestseller"
                                        :recommended="product.recommended"
                                        @replace="changeProduct"
                                        replace
                                    />
                                </div>
                            </div>
                            <div class="loader-container"></div>
                        </template>
                        <template v-else>
                            <p style="text-align: center;">
                                {{ relatedProducts.responseMessage }}
                            </p>
                        </template>
                    </div>
                </div>
                <div class="request-product-container">
                    <RequestProduct />
                </div>
            </FullPageModal>
            <Modal v-if="tryAgainModal" transparent @close="closeTryAgainModal">
                <div class="ehdmodal">
                    <div class="ehdmodal-view">
                        <div
                            class="result-button"
                            style="display: flex; flex-direction: column; justify-content: center;"
                        >
                            <p style="margin-bottom: 20px; font-size: 20px;">
                                Oops! Something went wrong
                            </p>
                            <div
                                class="result-button"
                                style="display: flex; justify-content: center;"
                            >
                                <Button
                                    class="delete-draft"
                                    name="Try again"
                                    outline
                                    @click.native="renderImageAgain"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <PostSqCustomisation
                v-if="showPostSqCustom"
                @close="closePostSqCustom"
                :url="`${currentUrl}ehd/${newEhdId}`"
                :img="sceneImage"
            />
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Popper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'
import {
    toggleProduct,
    removeProduct,
    toggleService,
    getProductsBySearch,
    changeProduct as changeProductApi,
} from 'api/productsApi'

import { mapState, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import ConvertToPrice from 'mixins/ConvertToPrice'
import CustomisationPageModule from 'store/modules/CustomisationPage'
import ProductListPageModule from 'store/modules/ProductListPage'
import SearchAutoComplete from 'componentsv2/SearchAutoComplete'

import Button from 'componentsv2/Button'
import SideDrawer from 'componentsv2/SideDrawer'
import Tabs from 'componentsv2/Tabs'
import Tab from 'componentsv2/Tab'
import FullPageModal from 'componentsv2/FullPageModal'
import CurrentProduct from 'componentsv2/CurrentProduct'
import ProductCards from 'componentsv2/ProductCards'
import FiltersBox from 'componentsv2/FiltersBox'
import RequestProduct from 'componentsv2/RequestProduct'
import Loader from 'componentsv2/Loader'
import PanoImage from 'componentsv2/PanoImage'
import Modal from 'componentsv2/Modal'
import { getDraft } from 'api/userApi'
import PostSqCustomisation from 'pagesv2/PostSqCustomisation'
import loaderHandler from 'mixins/loader'
import { SNAKE_LOADER, VISUAL_LOADER } from 'utils/constants'
// import ReplaceProductModal from 'componentsv2/ReplaceProductModal'

export default {
    name: 'FullPageNavModal',
    props: ['ehdId', 'sceneId', 'requiredDomain'],
    components: {
        SideDrawer,
        Tabs,
        Button,
        Tab,
        // ReplaceProductModal,
        FullPageModal,
        CurrentProduct,
        ProductCards,
        FiltersBox,
        RequestProduct,
        SearchAutoComplete,
        PostSqCustomisation,
        Loader,
        Popper,
        PanoImage,
        Modal,
    },
    mixins: [RegisterStoreModule, ConvertToPrice, loaderHandler],
    data() {
        return {
            showDrawer: false,
            replaceProductModal: false,
            currentProductId: null,
            currentProductIndex: null,
            newEhdId: null,
            newSceneId: null,
            newProductId: null,
            sceneImage: null,
            scenePanoImage: '',
            currentSceneImageIndex: 0,
            currentOpenPopUp: null,
            previouspopup: null,
            showPostSqCustom: false,
            shownPostSqCustom: 0,
            currentUrl: process.env.VUE_APP_BASE_URL,
            tabselect: 0,
            loadingPercentage: 0,
            suggestions: [],
            imageInterval: undefined,
            tryAgainModal: false,
            awsS3: process.env.VUE_AWS3,
            aws3Call: process.env.VUE_APP_AWS3_CALL,
            action: null,
            hqImageLoader: false,
            //paging for related products
            pageNumber: 1,
            totalNumberOfPages: '',
            similarProducts: null,
            autoLoad: true,
            filter_str: '',
            //paging for search products
            searchNumber: 1,
            totalNumberOfSearchPages: '',
            searchingActive: true,
            q: 'HD',
        }
    },
    computed: {
        ...mapState({
            allSceneData: state => state.CustomisationPage.scene,
            scene: state => state.CustomisationPage.scene.scene_data,
            relatedProducts: state => state.CustomisationPage.relatedProducts,
            filters: state => state.ProductListPage.filters_data,
            clientSceneProduct: state =>
                state.CustomisationPage.clientSceneProduct,
            windowWidth: state => state.AppModule.windowWidth,
            totalProductsCustomisation: state =>
                state.CustomisationPage.totalProductsCustomisation,
            totalServicesCustomisation: state =>
                state.CustomisationPage.totalServicesCustomisation,
        }),
    },
    created() {
        this.registerStoreModule('CustomisationPage', CustomisationPageModule)
        this.registerStoreModule('ProductListPage', ProductListPageModule)
        this.fetchScene({ id: this.sceneId }).then(() => {
            this.sceneImage = this.scene.scene_image_data_list[
                this.currentSceneImageIndex
            ].scene_image_url.sc_image
            this.scenePanoImage = this.scene.pano_image
            this.CLIENT_SCENE(
                this.allSceneData.scene_data.furnishing_products_in_scene.concat(
                    this.allSceneData.scene_data.decor_products_in_scene,
                ),
            )
        })
    },
    mounted() {
        this.loaderShow = false
        window.addEventListener('message', this.eM)
    },
    destroyed() {
        clearInterval(this.imageInterval)
        window.removeEventListener('message', this.eM)
    },
    methods: {
        ...mapActions({
            fetchScene: 'fetchScene',
            updateProduct: 'updateProduct',
            removeProduct1: 'removeProduct1',
            removeProduct: 'removeProduct',
            fetchRelatedProducts: 'fetchRelatedProducts',
            fetchFilters: 'fetchFilters',
            CLIENT_SCENE: 'CLIENT_SCENE',
            updateRelatedProducts: 'updateRelatedProducts',
        }),
        download() {
            console.log('download')
        },
        loadMoreProducts() {
            const spinner = `<div class="spinner"></div>`
            let loaderContainer = document.querySelector('.loader-container')
            if (
                this.pageNumber < this.totalNumberOfPages &&
                this.autoLoad == true
            ) {
                this.autoLoad = false
                this.pageNumber++
                loaderContainer.innerHTML += spinner
                this.fetchRelatedProducts({
                    obj: `?product_id=${this.currentProductId}${this.filter_str}&page=${this.pageNumber}&size=21&scene_id=${this.scene.id}`,
                }).then(response => {
                    if (response.responseCode == 200) {
                        this.totalNumberOfPages = response.payload.total_page
                        loaderContainer.removeChild(
                            document.querySelector('.spinner'),
                        )
                        response.payload.related_product.forEach(product => {
                            this.similarProducts.push(product)
                        })
                        setTimeout(() => {
                            this.autoLoad = true
                        }, 500)
                    }
                })
            }
        },

        checkIfImageExists(url, callback) {
            const img = new Image()
            img.src = url

            if (img.complete) {
                callback(true)
            } else {
                img.onload = () => {
                    callback(true, url)
                }

                img.onerror = () => {
                    callback(false, url)
                }
            }
        },

        closeTryAgainModal() {
            this.tryAgainModal = false
            this.loaderShow = false
            // this.loaderType = VISUAL_LOADER
            // changeProductApi({
            //     scene_id:
            //         this.newSceneId != null ? this.newSceneId : this.sceneId,
            //     old_product: parseInt(this.currentProductId),
            //     new_product: this.currentProductId,
            //     xloc: parseFloat(
            //         this.clientSceneProduct[this.currentProductIndex].xperc,
            //     ),
            //     yloc: parseFloat(
            //         this.clientSceneProduct[this.currentProductIndex].yperc,
            //     ),
            //     zloc: parseFloat(
            //         this.clientSceneProduct[this.currentProductIndex].zperc,
            //     ),
            //     product_loc_id: this.clientSceneProduct[this.currentProductIndex].product_loc_id,
            //     type: 'CHANGE_PRODUCT',
            // })
            //     .then(response => {
            //         if (response.data.responseCode === 200) {
            //             this.loaderShow = false
            //             this.loaderType = SNAKE_LOADER
            //             let data = response.data.payload
            //             this.newEhdId = data.ehd_id
            //             this.newSceneId = data.scene_id
            //             this.fetchScene(data.scene_id).then(() => {
            //                 this.CLIENT_SCENE(
            //                     this.allSceneData.scene_data.furnishing_products_in_scene.concat(
            //                         this.allSceneData.scene_data
            //                             .decor_products_in_scene,
            //                     ),
            //                 )
            //                 this.sceneImage = this.scene.scene_image_data_list[
            //                     this.currentSceneImageIndex
            //                 ].scene_image_url.sc_image
            //                 this.scenePanoImage = this.scene.pano_image
            //                 if (
            //                     this.newProduct.is_3d_available === true &&
            //                     this.aws3Call === true
            //                 ) {
            //                     this.hqImageLoader = true
            //                     this.imageInterval = setInterval(() => {
            //                         this.checkIfImageExists(
            //                             `${this.awsS3}${this.newEhdId}/3d-render-hq-360/${this.newSceneId}-3d-render-hq-360.jpeg`,
            //                             (exists, url) => {
            //                                 if (exists) {
            //                                     this.scenePanoImage = url
            //                                     this.hqImageLoader = false
            //                                     clearInterval(
            //                                         this.imageInterval,
            //                                     )
            //                                 }
            //                             },
            //                         )
            //                     }, 2000)
            //                 }
            //             })

            //             this.$emit('update', {
            //                 ehd_id: data.ehd_id,
            //                 scene_id: data.scene_id,
            //                 callDraft: true,
            //             })
            //         } else {
            //             this.loaderShow = false
            //             this.loaderType = SNAKE_LOADER
            //             this.tryAgainModal = true
            //         }
            //     })
            //     .catch(err => {
            //         setTimeout(() => {
            //             this.loaderShow = false
            //             this.loaderType = SNAKE_LOADER
            //         }, 100)
            //     })
        },

        renderImageAgain() {
            this.tryAgainModal = false
            if (this.action == 'REPLACE') {
                changeProductApi({
                    scene_id:
                        this.newSceneId != null
                            ? this.newSceneId
                            : this.sceneId,
                    old_product: parseInt(this.currentProductId),
                    new_product: this.newProduct.product_id,
                    xloc: parseFloat(
                        this.clientSceneProduct[this.currentProductIndex].xperc,
                    ),
                    yloc: parseFloat(
                        this.clientSceneProduct[this.currentProductIndex].yperc,
                    ),
                    zloc: parseFloat(
                        this.clientSceneProduct[this.currentProductIndex].zperc,
                    ),
                    product_loc_id: this.clientSceneProduct[
                        this.currentProductIndex
                    ].product_loc_id,
                    type: 'CHANGE_PRODUCT',
                })
                    .then(response => {
                        if (response.data.responseCode === 200) {
                            this.loaderShow = false
                            this.loaderType = SNAKE_LOADER
                            let data = response.data.payload
                            this.newEhdId = data.ehd_id
                            this.newSceneId = data.scene_id
                            this.fetchScene({ id: data.scene_id }).then(() => {
                                this.CLIENT_SCENE(
                                    this.allSceneData.scene_data.furnishing_products_in_scene.concat(
                                        this.allSceneData.scene_data
                                            .decor_products_in_scene,
                                    ),
                                )
                                this.sceneImage = this.scene.scene_image_data_list[
                                    this.currentSceneImageIndex
                                ].scene_image_url.sc_image
                                this.scenePanoImage = this.scene.pano_image
                                if (
                                    this.newProduct.is_3d_available === true &&
                                    this.aws3Call === true
                                ) {
                                    this.hqImageLoader = true
                                    this.imageInterval = setInterval(() => {
                                        this.checkIfImageExists(
                                            `${this.awsS3}${this.newEhdId}/3d-render-hq-360/${this.newSceneId}-3d-render-hq-360.jpeg`,
                                            (exists, url) => {
                                                if (exists) {
                                                    this.scenePanoImage = url
                                                    this.hqImageLoader = false
                                                    clearInterval(
                                                        this.imageInterval,
                                                    )
                                                }
                                            },
                                        )
                                    }, 2000)
                                }
                            })

                            this.$emit('update', {
                                ehd_id: data.ehd_id,
                                scene_id: data.scene_id,
                                callDraft: true,
                            })
                        } else {
                            this.loaderShow = false
                            this.loaderType = SNAKE_LOADER
                            this.tryAgainModal = true
                        }
                    })
                    .catch(err => {
                        setTimeout(() => {
                            this.loaderShow = false
                            this.loaderType = SNAKE_LOADER
                        }, 100)
                    })
            }
        },

        handleClose() {
            this.$emit('close')
        },

        fetchSuggestions(query) {
            if (query.length > 2) {
                this.suggestions = []
                this.searchNumber = 1
                getProductsBySearch({
                    string: query,
                    page: this.searchNumber,
                }).then(response => {
                    this.suggestions = response.furnish_data
                    this.totalNumberOfSearchPages = response.num_pages
                })
            }
        },
        loadMoreSearchOptions(query) {
            if (
                this.searchNumber < this.totalNumberOfSearchPages &&
                this.searchingActive == true
            ) {
                this.searchingActive = false
                this.searchNumber++
                getProductsBySearch({
                    string: query,
                    page: this.searchNumber,
                }).then(response => {
                    response.furnish_data.forEach(product => {
                        this.suggestions.push(product)
                    })
                    this.searchingActive = true
                    this.totalNumberOfSearchPages = response.num_pages
                })
            }
        },

        toggleSideDrawer() {
            this.showDrawer = !this.showDrawer
            this.tabselect = 0
        },

        toggleSideDrawerForServices() {
            this.showDrawer = !this.showDrawer
            this.tabselect = 1
        },

        toggleReplaceProductModal() {
            this.replaceProductModal = !this.replaceProductModal
            if (!this.replaceProductModal) {
                this.similarProducts = {}
                this.totalNumberOfPages = null
                this.pageNumber = 1
            }
        },

        closePopUp() {
            if (this.previouspopup != null) {
                let previous_popup_el = document.getElementById(
                    this.previouspopup,
                )
                if (previous_popup_el != null) {
                    previous_popup_el.classList.remove('product-popup--show')
                }
                this.previouspopup = null
            }
        },

        replaceProduct(e) {
            this.loaderShow = true
            document.querySelector('body').style.overflow = 'hidden'
            this.updateRelatedProducts()
            this.toggleReplaceProductModal()
            this.currentProductId = e.currentTarget.getAttribute('data-id')
            this.currentProductIndex = e.currentTarget.getAttribute(
                'data-index',
            )
            this.fetchRelatedProducts({
                obj: `?product_id=${this.currentProductId}${this.filter_str}&page=${this.pageNumber}&size=21&scene_id=${this.scene.id}`,
                config: {
                    onDownloadProgress: progressEvent => {
                        // console.log(progressEvent)
                        const percentage = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total,
                        )
                        this.loadingPercentage = percentage
                        // if (percentage === 100) {
                        //     console.log("completed")
                        // }
                    },
                },
            }).then(payload => {
                if (payload.responseCode == 200) {
                    setTimeout(() => {
                        this.totalNumberOfPages = payload.payload.total_page
                        this.similarProducts = payload.payload.related_product
                        this.loaderShow = false
                    }, 500)
                }
            })
            this.fetchFilters({
                source: 'RelatedProduct',
                product_id: this.currentProductId,
            })
        },

        getFilteredProduct(filters) {
            if (filters.vendor_ids.length > 0) {
                let filters_string = Object.keys(filters)
                    .map(key => key + '=' + filters[key])
                    .join('&')
                this.filter_str = `&${filters_string}`
            } else {
                this.filter_str = ''
            }
            this.pageNumber = 1
            this.fetchRelatedProducts({
                obj: `?product_id=${this.currentProductId}${this.filter_str}&page=${this.pageNumber}&size=21&scene_id=${this.scene.id}`,
                config: {
                    onDownloadProgress: progressEvent => {
                        // console.log(progressEvent)
                        const percentage = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total,
                        )
                        this.loadingPercentage = percentage
                        // if (percentage === 100) {
                        //     console.log("completed")
                        // }
                    },
                },
            }).then(response => {
                if (response.responseCode == 200) {
                    this.totalNumberOfPages = response.payload.total_page
                    this.similarProducts = response.payload.related_product
                }
            })
        },

        changeProduct(newproduct) {
            document.getElementById('fullpagenavmodal').click()
            if (this.q === 'HD') {
                this.loaderShow = true
            }
            this.loaderType = VISUAL_LOADER
            document.querySelector('body').style.overflow = 'hidden'
            this.toggleReplaceProductModal()
            this.newProduct = newproduct
            this.action = 'REPLACE'
            this.clientSceneProduct.forEach(product => {
                if (product.product_id == this.currentProductId) {
                    this.loadingPercentage = 0
                    changeProductApi(
                        {
                            scene_id:
                                this.newSceneId != null
                                    ? this.newSceneId
                                    : this.sceneId,
                            old_product: parseInt(this.currentProductId),
                            new_product: newproduct.product_id,
                            xloc: parseFloat(
                                this.clientSceneProduct[
                                    this.currentProductIndex
                                ].xperc,
                            ),
                            yloc: parseFloat(
                                this.clientSceneProduct[
                                    this.currentProductIndex
                                ].yperc,
                            ),
                            zloc: parseFloat(
                                this.clientSceneProduct[
                                    this.currentProductIndex
                                ].zperc,
                            ),
                            product_loc_id: this.clientSceneProduct[
                                this.currentProductIndex
                            ].product_loc_id,
                            type: 'CHANGE_PRODUCT',
                        },
                        {
                            onDownloadProgress: progressEvent => {
                                // console.log(progressEvent)
                                const percentage = Math.round(
                                    (progressEvent.loaded * 100) /
                                        progressEvent.total,
                                )
                                this.loadingPercentage = percentage
                                // if (percentage === 100) {
                                //     console.log("completed")
                                // }
                            },
                        },
                    )
                        .then(response => {
                            if (response.data.responseCode === 200) {
                                this.loaderShow = false
                                this.loaderType = SNAKE_LOADER
                                let data = response.data.payload
                                this.newEhdId = data.ehd_id
                                this.newSceneId = data.scene_id
                                this.fetchScene({ id: data.scene_id }).then(
                                    () => {
                                        this.CLIENT_SCENE(
                                            this.allSceneData.scene_data.furnishing_products_in_scene.concat(
                                                this.allSceneData.scene_data
                                                    .decor_products_in_scene,
                                            ),
                                        )
                                        this.sceneImage = this.scene.scene_image_data_list[
                                            this.currentSceneImageIndex
                                        ].scene_image_url.sc_image
                                        this.scenePanoImage = this.scene.pano_image
                                        if (
                                            this.newProduct.is_3d_available ===
                                                true &&
                                            this.aws3Call === true
                                        ) {
                                            this.hqImageLoader = true
                                            this.imageInterval = setInterval(
                                                () => {
                                                    this.checkIfImageExists(
                                                        `${this.awsS3}${this.newEhdId}/3d-render-hq-360/${this.newSceneId}-3d-render-hq-360.jpeg`,
                                                        (exists, url) => {
                                                            if (exists) {
                                                                this.scenePanoImage = url
                                                                this.hqImageLoader = false
                                                                clearInterval(
                                                                    this
                                                                        .imageInterval,
                                                                )
                                                            }
                                                        },
                                                    )
                                                },
                                                2000,
                                            )
                                        }
                                    },
                                )

                                this.$emit('update', {
                                    ehd_id: data.ehd_id,
                                    scene_id: data.scene_id,
                                    callDraft: true,
                                })
                            } else {
                                this.loaderShow = false
                                this.loaderType = SNAKE_LOADER
                                this.tryAgainModal = true
                            }
                        })
                        .catch(err => {
                            setTimeout(() => {
                                this.loaderShow = false
                                this.loaderType = SNAKE_LOADER
                            }, 1000)
                        })
                    let frame = document.getElementById('low-render-frame')
                    const payload = {
                        event: 'SCENE_UPDATE',
                        product_id: this.newProduct.product_id,
                        image_url: this.newProduct.image,
                        product_type: this.newProduct.product_type,
                        item_category: this.newProduct.item_category,
                    }
                    frame.contentWindow.postMessage(payload, '*')
                }
            })
        },

        deleteProduct(e) {
            document.getElementById('fullpagenavmodal').click()
            this.loaderShow = true
            this.loaderType = VISUAL_LOADER
            document.querySelector('body').style.overflow = 'hidden'
            let product_id = e.currentTarget.getAttribute('data-id')
            this.currentProductId = product_id
            let xloc = e.currentTarget.getAttribute('data-xloc')
            let yloc = e.currentTarget.getAttribute('data-yloc')
            let zloc = e.currentTarget.getAttribute('data-zloc')
            let product_enable = e.currentTarget.getAttribute('data-available')
            let product_loc_id = e.currentTarget.getAttribute(
                'data-product-loc-id',
            )
            this.loadingPercentage = 0
            toggleProduct(
                {
                    type: 'TOGGLE_PRODUCT',
                    product_id: product_id,
                    product_enable: JSON.parse(product_enable.toLowerCase()),
                    scene_id:
                        this.newSceneId != null
                            ? this.newSceneId
                            : this.sceneId,
                    xloc: parseFloat(xloc),
                    yloc: parseFloat(yloc),
                    zloc: parseFloat(zloc),
                    product_loc_id: parseInt(product_loc_id),
                },
                {
                    onDownloadProgress: progressEvent => {
                        const percentage = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total,
                        )
                        this.loadingPercentage = percentage
                        if (percentage === 100) {
                            return
                        }
                    },
                },
            )
                .then(response => {
                    if (response.data.responseCode === 200) {
                        let data = response.data.payload
                        this.newEhdId = data.ehd_id
                        this.newSceneId = data.scene_id
                        this.fetchScene({ id: data.scene_id })
                            .then(() => {
                                this.CLIENT_SCENE(
                                    this.allSceneData.scene_data.furnishing_products_in_scene.concat(
                                        this.allSceneData.scene_data
                                            .decor_products_in_scene,
                                    ),
                                )
                                this.sceneImage = this.scene.scene_image_data_list[
                                    this.currentSceneImageIndex
                                ].scene_image_url.sc_image
                                this.scenePanoImage = this.scene.pano_image
                                if (
                                    this.newProduct.is_3d_available === true &&
                                    this.aws3Call === true
                                ) {
                                    this.hqImageLoader = true
                                    this.imageInterval = setInterval(() => {
                                        this.checkIfImageExists(
                                            `${this.awsS3}${this.newEhdId}/3d-render-hq-360/${this.newSceneId}-3d-render-hq-360.jpeg`,
                                            (exists, url) => {
                                                if (exists) {
                                                    this.scenePanoImage = url
                                                    this.hqImageLoader = false
                                                    clearInterval(
                                                        this.imageInterval,
                                                    )
                                                }
                                            },
                                        )
                                    }, 2000)
                                }
                            })
                            .catch(err => {
                                setTimeout(() => {
                                    this.loaderShow = false
                                    this.loaderType = SNAKE_LOADER
                                }, 1000)
                            })
                        this.$emit('update', {
                            ehd_id: data.ehd_id,
                            scene_id: data.scene_id,
                            callDraft: true,
                        })
                        if (response.status == 200) {
                            setTimeout(() => {
                                this.loaderShow = false
                                this.loaderType = SNAKE_LOADER
                            }, 3000)
                        }
                    } else {
                        this.tryAgainModal = true
                    }
                })
                .catch(err => {
                    setTimeout(() => {
                        this.loaderShow = false
                        this.loaderType = SNAKE_LOADER
                    }, 1000)
                })
        },

        restore(e) {
            document.getElementById('fullpagenavmodal').click()
            this.loaderShow = true
            this.loaderType = VISUAL_LOADER
            document.querySelector('body').style.overflow = 'hidden'
            this.loadingPercentage = 0
            toggleProduct(
                {
                    type: 'TOGGLE_PRODUCT',
                    product_id: `${e.product_id}`,
                    product_enable: true,
                    scene_id:
                        this.newSceneId != null
                            ? this.newSceneId
                            : this.sceneId,
                    xloc: parseFloat(e.xperc),
                    yloc: parseFloat(e.yperc),
                    zloc: parseFloat(e.zperc),
                    product_loc_id: e.product_loc_id,
                },
                {
                    onDownloadProgress: progressEvent => {
                        const percentage = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total,
                        )
                        this.loadingPercentage = percentage
                        if (percentage === 100) {
                            return
                        }
                    },
                },
            )
                .then(response => {
                    let data = response.data.payload
                    this.newEhdId = data.ehd_id
                    this.newSceneId = data.scene_id
                    this.fetchScene({id:data.scene_id}).then(() => {
                        this.CLIENT_SCENE(
                            this.allSceneData.scene_data.furnishing_products_in_scene.concat(
                                this.allSceneData.scene_data
                                    .decor_products_in_scene,
                            ),
                        )
                        this.sceneImage = this.scene.scene_image_data_list[
                            this.currentSceneImageIndex
                        ].scene_image_url.sc_image
                        this.scenePanoImage = this.scene.pano_image
                    })
                    this.$emit('update', {
                        ehd_id: data.ehd_id,
                        scene_id: data.scene_id,
                        callDraft: true,
                    })
                    if (response.status == 200) {
                        setTimeout(() => {
                            this.loaderShow = false
                            this.loaderType = SNAKE_LOADER
                        }, 1000)
                    }
                })
                .catch(err => {
                    setTimeout(() => {
                        this.loaderShow = false
                        this.loaderType = SNAKE_LOADER
                    }, 1000)
                })
        },

        toggleView(data) {
            document.getElementById('fullpagenavmodal').click()
            this.loaderShow = true
            this.loaderType = VISUAL_LOADER
            document.querySelector('body').style.overflow = 'hidden'
            this.loadingPercentage = 0
            toggleProduct({
                type: 'TOGGLE_VIEW',
                product_id: `${data.product_id}`,
                product_enable: true,
                view_type: data.view_in_use == 'Close' ? 'Open' : 'Close',
                scene_id:
                    this.newSceneId != null ? this.newSceneId : this.sceneId,
                xloc: parseFloat(data.xperc),
                yloc: parseFloat(data.yperc),
                zloc: parseFloat(data.zperc),
                product_loc_id: data.product_loc_id,
            })
                .then(response => {
                    let data = response.data.payload
                    this.newEhdId = data.ehd_id
                    this.newSceneId = data.scene_id
                    this.fetchScene({id:data.scene_id}).then(() => {
                        this.CLIENT_SCENE(
                            this.allSceneData.scene_data.furnishing_products_in_scene.concat(
                                this.allSceneData.scene_data
                                    .decor_products_in_scene,
                            ),
                        )
                        this.sceneImage = this.scene.scene_image_data_list[
                            this.currentSceneImageIndex
                        ].scene_image_url.sc_image
                        this.scenePanoImage = this.scene.pano_image
                    })
                    this.$emit('update', {
                        ehd_id: data.ehd_id,
                        scene_id: data.scene_id,
                        callDraft: true,
                    })
                    if (response.status == 200) {
                        setTimeout(() => {
                            this.loaderShow = false
                            this.loaderType = SNAKE_LOADER
                        }, 1000)
                    }
                })
                .catch(err => {
                    setTimeout(() => {
                        this.loaderShow = false
                        this.loaderType = SNAKE_LOADER
                    }, 1000)
                })
        },

        deleteService(service_id, bool, scene_id) {
            this.loaderShow = true
            this.loaderType = VISUAL_LOADER
            //document.querySelector('body').style.overflow = 'hidden'
            this.loadingPercentage = 0
            toggleService(
                {
                    type: 'TOGGLE_SERVICE',
                    service_id: service_id,
                    service_enable: bool,
                    scene_id: scene_id,
                },
                {
                    onDownloadProgress: progressEvent => {
                        // console.log(progressEvent)
                        const percentage = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total,
                        )
                        this.loadingPercentage = percentage
                        // if (percentage === 100) {
                        //     console.log("completed")
                        // }
                    },
                },
            ).then(response => {
                let data = response.data.payload
                this.newEhdId = data.ehd_id
                this.newSceneId = data.scene_id
                this.fetchScene({id:data.scene_id})
                this.$emit('update', {
                    ehd_id: data.ehd_id,
                    scene_id: data.scene_id,
                    callDraft: true,
                })
                if (response.status == 200) {
                    setTimeout(() => {
                        this.loaderShow = false
                        this.loaderType = SNAKE_LOADER
                        //document.querySelector('body').style.overflow = 'auto'
                    }, 1000)
                }
            })
        },

        changeView() {
            if (
                this.currentSceneImageIndex <
                this.scene.scene_image_data_list.length - 1
            ) {
                this.currentSceneImageIndex++
            } else {
                this.currentSceneImageIndex = 0
            }

            this.sceneImage = this.scene.scene_image_data_list[
                this.currentSceneImageIndex
            ].scene_image_url.sc_image
        },

        nightView() {
            let night = document.getElementById('nav-mode-checkbox')
            if (night.checked) {
                this.sceneImage = this.scene.scene_image_data_list[
                    this.currentSceneImageIndex
                ].scene_image_url.sc_image
            } else {
                this.sceneImage = this.allSceneData.night_top_image
            }
        },

        closePostSqCustom() {
            this.showPostSqCustom = false
        },

        postSQCompletion() {
            if (this.shownPostSqCustom == 0 && this.$route.query.src == 'SQ') {
                this.showPostSqCustom = true
                this.shownPostSqCustom = 1
            } else {
                this.$emit('close')
            }
        },

        SRQ(q) {
            this.q = q
        },

        sM() {
            if (this.q === 'SD') {
                let frame = document.getElementById('low-render-frame')
                const payload = {
                    event: 'SCENE_SWITCH',
                    file_name: this.scene.parent_id,
                }
                console.log(payload)
                frame.contentWindow.postMessage(payload, '*')
            }
        },

        eM(e) {
            const { data } = e
            if (data.pageLoaded) {
                this.sM()
            }
            if (data.modal === true) {
                this.showDrawer = !this.showDrawer
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import './FullPageNavModal.scss';
</style>
