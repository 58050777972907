var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ehd-page"},[(_vm.isLoggedIn == true)?[(Object.keys(_vm.EHDDetails).length > 0)?[_c('div',{staticClass:"ehd-message"},[_c('p',[_vm._v(" Hey "+_vm._s(_vm.userData.firstname)+", now that we know what you like, we have some ideas for you. Not to forget, "+_vm._s(_vm.EHDDetails.designer)+" is one of our star interior designers specialising in "+_vm._s(_vm.EHDDetails.theme)+" Designs. ")])]),(_vm.windowWidth > 1100)?_c('div',{staticClass:"ehd-fixed-details"},[_c('div',{staticClass:"ehd-details"},[_c('div',{staticClass:"details"},[_c('div',{staticClass:"detail-heading"},[_vm._v("Project Name")]),_c('div',{staticClass:"detail-subheading"},[_vm._v(" "+_vm._s(_vm.EHDDetails.project_name)+" ")])]),_c('div',{staticClass:"details"},[_c('div',{staticClass:"detail-heading"},[_vm._v("Layout Type")]),_c('div',{staticClass:"detail-subheading"},[_vm._v(" "+_vm._s(_vm.EHDDetails.floor_plan)+" "),(_vm.EHDDetails.carpet_area)?_c('span',[_vm._v("("+_vm._s(_vm.EHDDetails.carpet_area)+")")]):_vm._e()])]),_c('div',{staticClass:"details"},[_c('div',{staticClass:"detail-heading"},[_vm._v("Theme")]),_c('div',{staticClass:"detail-subheading"},[_vm._v(" "+_vm._s(_vm.EHDDetails.theme)+" ")])]),_c('div',{staticClass:"details"},[_c('div',{staticClass:"detail-heading"},[_vm._v("Total Cost")]),_c('div',{staticClass:"detail-subheading",class:_vm.requiredDomain == false ? 'blur' : ''},[(_vm.$route.query.ok)?_c('p',[_vm._v(" ₹ "+_vm._s(_vm.convertToIndianNumberSystem( _vm.kitchenPrice ))+" ")]):_c('p',[_vm._v(" ₹ "+_vm._s(_vm.convertToIndianNumberSystem( _vm.EHDDetails.total_cost ))+" ")])])]),_vm._m(0)]),_c('div',{staticClass:"ehd-buttons"},[_c('router-link',{attrs:{"to":{
                            name: 'MyDesignIdeas',
                        }}},[_c('Button',{attrs:{"name":"Explore designs","outline":""}})],1),_c('router-link',{attrs:{"to":{
                            name: 'FinaliseAndPlaceOrderPage',
                            query: {
                                ehd_id: _vm.EHDDetails.id,
                                scene_id: _vm.EHDDetails.scene_data[0].id,
                            },
                        }}},[_c('Button',{attrs:{"name":"Buy","primary":""}})],1)],1)]):_c('div',{staticClass:"ehd-header"},[_c('div',{staticClass:"ehd-details"},[_c('div',{staticClass:"details"},[_c('div',{staticClass:"detail-heading"},[_vm._v("Project Name")]),_c('div',{staticClass:"detail-subheading"},[_vm._v(" "+_vm._s(_vm.EHDDetails.project_name)+" ")])]),_c('div',{staticClass:"details"},[_c('div',{staticClass:"detail-heading"},[_vm._v("Layout Type")]),_c('div',{staticClass:"detail-subheading"},[_vm._v(" "+_vm._s(_vm.EHDDetails.floor_plan)+" "),(_vm.EHDDetails.carpet_area)?_c('span',[_vm._v("("+_vm._s(_vm.EHDDetails.carpet_area)+")")]):_vm._e()])]),_c('div',{staticClass:"details"},[_c('div',{staticClass:"detail-heading"},[_vm._v("Theme")]),_c('div',{staticClass:"detail-subheading"},[_vm._v(" "+_vm._s(_vm.EHDDetails.theme)+" ")])]),_c('div',{staticClass:"details"},[_c('div',{staticClass:"detail-heading"},[_vm._v("Total Cost")]),_c('div',{staticClass:"detail-subheading",class:_vm.requiredDomain == false ? 'blur' : ''},[(_vm.$route.query.ok)?_c('p',[_vm._v(" ₹ "+_vm._s(_vm.convertToIndianNumberSystem( _vm.kitchenPrice ))+" ")]):_c('p',[_vm._v(" ₹ "+_vm._s(_vm.convertToIndianNumberSystem( _vm.EHDDetails.total_cost ))+" ")])])]),_vm._m(1)]),_c('div',{staticClass:"ehd-actions-mobile"},[_c('router-link',{attrs:{"to":{
                            name: 'MyDesignIdeas',
                        }}},[_c('Button',{attrs:{"name":"Explore designs","outline":""}})],1),_c('router-link',{attrs:{"to":{
                            name: 'FinaliseAndPlaceOrderPage',
                            query: { ehd_id: _vm.EHDDetails.id },
                        }}},[_c('Button',{attrs:{"name":"Buy","primary":""}})],1)],1)]),_c('div',{staticClass:"ehd-body"},[_c('Tabs',{key:_vm.EHDDetails.id,attrs:{"isselected":_vm.currentState},on:{"selectedTab":_vm.updateCurrentState}},[_vm._l((_vm.EHDDetails.scene_data),function(scene,index){return _c('Tab',{key:((scene.id) + "_" + index),attrs:{"title":scene.room_type,"img":scene.scene_image_data.length > 0
                                    ? scene.scene_image_data[0]
                                          .scene_image_url
                                    : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg',"blur":!_vm.roomsAvailable[index].room_available}},[_c('div',{staticClass:"scene-container"},[_c('div',{staticClass:"scene-body"},[(_vm.windowWidth > 1100)?_c('div',{staticClass:"scene-img-wrapper"},[_c('img',{attrs:{"src":scene.scene_image_data
                                                    .length !== 0
                                                    ? scene
                                                          .scene_image_data[0]
                                                          .scene_image_url
                                                    : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg',"alt":scene.room_type}}),_c('div',{staticClass:"ehd-scene-button"},[(scene.vt_link)?_c('a',{attrs:{"href":scene.vt_link,"target":"_blank"}},[_c('div',{staticClass:"virtual-button"},[_c('span',{staticClass:"button-text"},[_vm._v("Virtual Tour")]),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"19.867","height":"19.867","viewBox":"0 0 19.867 19.867"}},[_c('g',{attrs:{"data-name":"Group 1593"}},[_c('g',{attrs:{"data-name":"Group 178"}},[_c('g',{attrs:{"data-name":"Group 177"}},[_c('path',{staticStyle:{"fill":"#ff6100","stroke":"#ff6100","stroke-width":".4px"},attrs:{"data-name":"Path 559","d":"M9.733 0a9.733 9.733 0 1 0 9.733 9.733A9.744 9.744 0 0 0 9.733 0zm0 18.656a8.922 8.922 0 1 1 8.922-8.922 8.932 8.932 0 0 1-8.922 8.922z","transform":"translate(.2 .2)"}}),_c('path',{staticStyle:{"fill":"#ff6100","stroke":"#ff6100","stroke-width":".4px"},attrs:{"data-name":"Path 560","d":"m198.3 153.046-5.678-3.65a.406.406 0 0 0-.625.341v7.3a.405.405 0 0 0 .625.341l5.678-3.65a.405.405 0 0 0 0-.682zm-5.492 3.248v-5.814l4.522 2.907z","transform":"translate(-184.5 -143.453)"}})])])])])])]):_vm._e(),(
                                                    _vm.scene_thumbnails[index]
                                                        .length > 1
                                                )?[(!_vm.showThumbnails)?_c('div',{staticClass:"image-button",attrs:{"tabindex":"0"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleSceneThumbnails($event)},"focus":function($event){return _vm.hideThumbnails(
                                                            false
                                                        )},"blur":function($event){return _vm.hideThumbnails(true)}}},[_c('span',{staticClass:"button-text"},[_vm._v("More Image")]),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"22.205","height":"19","viewBox":"0 0 22.205 19"}},[_c('path',{staticStyle:{"fill":"none","stroke":"#ff6100","stroke-miterlimit":"10"},attrs:{"data-name":"Path 2444","d":"M29.964 68.874a1.426 1.426 0 0 1-1.426 1.426H13.175a1.426 1.426 0 0 1-1.426-1.426v-12.16a1.426 1.426 0 0 1 1.426-1.426h15.363a1.426 1.426 0 0 1 1.426 1.426zm-1.9-1.176-3.3-7.006-4.1 5.1-3-3.3-4 5.2zm-10.71-8.707a1.1 1.1 0 1 1-1.1-1.1 1.1 1.1 0 0 1 1.1 1.1zm14.174 8.322a1.426 1.426 0 0 0 1.426-1.426V53.726a1.426 1.426 0 0 0-1.426-1.426H16.165a1.426 1.426 0 0 0-1.426 1.426","transform":"translate(-11.249 -51.8)"}})])]):_c('div',{staticClass:"nocursor-view",attrs:{"tabindex":"0"},on:{"focus":function($event){return _vm.hideThumbnails(
                                                            false
                                                        )},"blur":function($event){return _vm.hideThumbnails(true)}}},[_c('span',{staticClass:"more-text"},[_vm._v("More Image")]),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"22.205","height":"19","viewBox":"0 0 22.205 19"}},[_c('path',{staticStyle:{"fill":"none","stroke":"#ff6100","stroke-miterlimit":"10"},attrs:{"data-name":"Path 2444","d":"M29.964 68.874a1.426 1.426 0 0 1-1.426 1.426H13.175a1.426 1.426 0 0 1-1.426-1.426v-12.16a1.426 1.426 0 0 1 1.426-1.426h15.363a1.426 1.426 0 0 1 1.426 1.426zm-1.9-1.176-3.3-7.006-4.1 5.1-3-3.3-4 5.2zm-10.71-8.707a1.1 1.1 0 1 1-1.1-1.1 1.1 1.1 0 0 1 1.1 1.1zm14.174 8.322a1.426 1.426 0 0 0 1.426-1.426V53.726a1.426 1.426 0 0 0-1.426-1.426H16.165a1.426 1.426 0 0 0-1.426 1.426","transform":"translate(-11.249 -51.8)"}})]),_c('div',{staticClass:"dropdown_menu"},[_c('div',{staticClass:"scrollable-menu"},_vm._l((_vm.scene_thumbnails[
                                                                    index
                                                                ]),function(image,i){return _c('div',{key:i,staticClass:"thumbnail-container",on:{"click":function($event){return _vm.changeDefaultSceneImage(
                                                                        image.image
                                                                    )}}},[_c('div',{staticClass:"thumbnail-image"},[_c('img',{class:_vm.default_scene_image ==
                                                                            image.image
                                                                                ? 'activeThumbnail'
                                                                                : '',attrs:{"src":image.image}})]),_c('p',[_vm._v(" "+_vm._s(image.scene_image_type)+" ")])])}),0),_c('svg',{staticClass:"thumbnail-arrow",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"12.906","height":"7.959","viewBox":"0 0 12.906 7.959"},on:{"click":_vm.toggleSceneThumbnails}},[_c('path',{staticStyle:{"fill":"#333"},attrs:{"data-name":"Color","d":"M1.514 0 0 1.516l4.919 4.937L0 11.389l1.514 1.516 6.445-6.452z","transform":"rotate(90 6.453 6.453)"}})])])])]:_vm._e()],2),_c('a',{staticClass:"ehd-full-screen",attrs:{"href":_vm.default_scene_image !== ''
                                                    ? _vm.default_scene_image
                                                    : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg',"target":"_blank"}},[_c('svg',{attrs:{"width":"20px","height":"20px","fill":"#ff6100","viewBox":"0 0 48 48","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"width":"48","height":"48","fill":"white","fill-opacity":"0.01"}}),_c('path',{attrs:{"d":"M6 6L16 15.8995","stroke":"#ff6100","stroke-width":"4","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M6 41.8995L16 32","stroke":"#ff6100","stroke-width":"4","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M42.0001 41.8995L32.1006 32","stroke":"#ff6100","stroke-width":"4","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M41.8995 6L32 15.8995","stroke":"#ff6100","stroke-width":"4","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M33 6H42V15","stroke":"#ff6100","stroke-width":"4","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M42 33V42H33","stroke":"#ff6100","stroke-width":"4","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M15 42H6V33","stroke":"#ff6100","stroke-width":"4","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M6 15V6H15","stroke":"#ff6100","stroke-width":"4","stroke-linecap":"round","stroke-linejoin":"round"}})])])]):_c('div',{staticClass:"mobile-img-wrapper"},[(
                                                _vm.scene_thumbnails[index]
                                                    .length > 0
                                            )?[_c('Carousel',{attrs:{"settings":_vm.settings1}},_vm._l((_vm.scene_thumbnails[
                                                        index
                                                    ]),function(image,i){return _c('img',{key:i,attrs:{"src":image.image}})}),0)]:[_c('img',{attrs:{"src":"https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg"}})],(scene.vt_link)?_c('a',{attrs:{"href":scene.vt_link,"target":"_blank"}},[_c('div',{staticClass:"virtual-tour-button"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"19.867","height":"19.867","viewBox":"0 0 19.867 19.867"}},[_c('g',{attrs:{"data-name":"Group 1593"}},[_c('g',{attrs:{"data-name":"Group 178"}},[_c('g',{attrs:{"data-name":"Group 177"}},[_c('path',{staticStyle:{"fill":"#ff6100","stroke":"#ff6100","stroke-width":".4px"},attrs:{"data-name":"Path 559","d":"M9.733 0a9.733 9.733 0 1 0 9.733 9.733A9.744 9.744 0 0 0 9.733 0zm0 18.656a8.922 8.922 0 1 1 8.922-8.922 8.932 8.932 0 0 1-8.922 8.922z","transform":"translate(.2 .2)"}}),_c('path',{staticStyle:{"fill":"#ff6100","stroke":"#ff6100","stroke-width":".4px"},attrs:{"data-name":"Path 560","d":"m198.3 153.046-5.678-3.65a.406.406 0 0 0-.625.341v7.3a.405.405 0 0 0 .625.341l5.678-3.65a.405.405 0 0 0 0-.682zm-5.492 3.248v-5.814l4.522 2.907z","transform":"translate(-184.5 -143.453)"}})])])])])])]):_vm._e()],2),_c('div',{staticClass:"scene-details"},[_c('div',{staticClass:"designer"},[_c('img',{attrs:{"src":_vm.EHDDetails.designer_image
                                                        ? _vm.EHDDetails.designer_image
                                                        : require('assets/images/default-designer.png'),"alt":_vm.EHDDetails.designer}}),_c('div',{staticClass:"designer-name"},[_c('p',[_vm._v(" Designed by "+_vm._s(_vm.EHDDetails.designer)+" ")])])]),_c('div',{staticClass:"heading"},[_vm._v(" "+_vm._s(scene.room_type)+" | "),_c('span',{class:_vm.requiredDomain == false
                                                        ? 'blur'
                                                        : ''},[_vm._v(" ₹ "+_vm._s(_vm.convertToIndianNumberSystem( scene.price ))+" ")]),_vm._v(" | "+_vm._s(_vm.EHDDetails.theme)+" ")]),(scene.description != null)?[(
                                                    scene.description
                                                        .length < 100
                                                )?[_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(scene.description)+" ")])]:[(!_vm.seeMoreActivated)?[_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(scene.description.slice( 0, 100 ))+"... "),_c('span',{staticClass:"more",on:{"click":function($event){return _vm.activateSeeMore(
                                                                    true
                                                                )}}},[_vm._v("see more")])])]:_vm._e(),(_vm.seeMoreActivated)?[_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(scene.description.slice( 0, 100 ))+"... ")]),_c('div',{staticClass:"seemore-modal"},[_c('div',{staticClass:"seemore"},[_c('span',{staticClass:"close",on:{"click":function($event){return _vm.activateSeeMore(
                                                                        false
                                                                    )}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"25","height":"24","fill":"none","viewBox":"0 0 25 24"}},[_c('path',{attrs:{"fill":"#000","d":"M19.275 6.41L17.865 5l-5.59 5.59L6.685 5l-1.41 1.41 5.59 5.59-5.59 5.59L6.685 19l5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"}})])]),_c('p',{staticClass:"seemore-heading"},[_vm._v(" Description ")]),_c('p',{staticClass:"seemore-text"},[_vm._v(" "+_vm._s(scene.description)+" ")])])])]:_vm._e()]]:_vm._e(),_c('div',{staticClass:"scene-price"},[(scene.price > 0)?_c('div',{staticClass:"space-cost"},[_c('p',[_vm._v("Space Cost")]),_c('p',{class:_vm.requiredDomain ==
                                                        false
                                                            ? 'blur'
                                                            : ''},[_vm._v(" ₹ "+_vm._s(_vm.convertToIndianNumberSystem( scene.price ))+" ")])]):_vm._e(),(
                                                    scene.furnishing_cost >
                                                        0 ||
                                                        scene.decor_cost > 0
                                                )?_c('div',{staticClass:"products"},[_c('p',[_vm._v(" Products "),_c('span',[_vm._v("("+_vm._s(scene.furnishing_products_in_scene.filter( function (value) { return value.is_active === true; } ).length + scene.decor_products_in_scene.filter( function (value) { return value.is_active === true; } ).length)+")")])]),_c('p',{class:_vm.requiredDomain ==
                                                        false
                                                            ? 'blur'
                                                            : ''},[_vm._v(" ₹ "+_vm._s(_vm.convertToIndianNumberSystem( scene.furnishing_cost + scene.decor_cost ))+" ")])]):_vm._e(),(
                                                    scene.total_price_of_services >
                                                        0
                                                )?_c('div',{staticClass:"services"},[_c('p',[_vm._v(" Services "),_c('span',[_vm._v("("+_vm._s(scene.services_data.filter( function (value) { return value.active === true; } ).length)+")")])]),_c('p',{class:_vm.requiredDomain ==
                                                        false
                                                            ? 'blur'
                                                            : ''},[_vm._v(" ₹ "+_vm._s(_vm.convertToIndianNumberSystem( scene.total_price_of_services ))+" ")])]):_vm._e(),(
                                                    scene.custom_product_cost >
                                                        0
                                                )?_c('div',{staticClass:"products"},[_c('p',[_vm._v(" Custom Products "),_c('span',[_vm._v("("+_vm._s(scene.one_time_custom_product.filter( function (value) { return value.active === true; } ).length)+")")])]),_c('p',{class:_vm.requiredDomain ==
                                                        false
                                                            ? 'blur'
                                                            : ''},[_vm._v(" ₹ "+_vm._s(_vm.convertToIndianNumberSystem( scene.custom_product_cost ))+" ")])]):_vm._e(),(
                                                    scene.unavailable_products_count >
                                                        0
                                                )?_c('div',{staticClass:"unavailable"},[_c('p',[_vm._v(" Prod Unavailable ("+_vm._s(scene.unavailable_products_count)+") ")]),_c('p',{class:_vm.requiredDomain ==
                                                        false
                                                            ? 'blur'
                                                            : ''},[_vm._v(" -₹ "+_vm._s(_vm.convertToIndianNumberSystem( scene.unavailable_products_cost ))+" ")])]):_vm._e()]),(
                                                scene.scene_type == 'WHITE'
                                            )?_c('Button',{staticClass:"customisation-button",attrs:{"name":"Customise","primary":"","data-scene-id":scene.id},nativeOn:{"click":function($event){return _vm.checkDraft({
                                                    ehd_id: _vm.EHDDetails.id,
                                                    scene_id: scene.id,
                                                    operation: 'customise',
                                                    scene: scene.room_type,
                                                    scene_img:
                                                        scene
                                                            .scene_image_data[0]
                                                            .scene_image_url !=
                                                        null
                                                            ? scene
                                                                  .scene_image_data[0]
                                                                  .scene_image_url
                                                            : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg',
                                                })}}}):_vm._e()],2)]),_c('Tabs',{attrs:{"isselected":_vm.currentTab},on:{"selectedTab":_vm.updateCurrentTab}},[_c('Tab',{attrs:{"title":("Products (" + (scene.furnishing_products_in_scene.filter(
                                                function (value) { return value.is_active ===
                                                    true; }
                                            ).length +
                                                scene.decor_products_in_scene.filter(
                                                    function (value) { return value.is_active ===
                                                        true; }
                                                ).length) + ")")}},[_c('div',{staticClass:"add-more-products"},[_c('Button',{staticClass:"add-products-button",attrs:{"name":"Add More Products","primary":"","data-scene-id":scene.id},nativeOn:{"click":function($event){return _vm.checkDraft({
                                                        ehd_id:
                                                            _vm.EHDDetails.id,
                                                        scene_id: scene.id,
                                                        operation: 'add',
                                                        scene_type:
                                                            scene.scene_type,
                                                    })}}})],1),(
                                                scene
                                                    .furnishing_products_in_scene
                                                    .length > 0 ||
                                                    scene
                                                        .decor_products_in_scene
                                                        .length > 0
                                            )?_c('div',{staticClass:"scene-products"},[_c('div',{staticClass:"products-grid"},_vm._l((_vm.totalProducts[
                                                        index
                                                    ].active),function(product,indx){return _c('div',{key:indx,staticClass:"product-in-grid"},[(
                                                            product.available ==
                                                                false
                                                        )?_c('NewCard',{attrs:{"id":product.id,"img":product.image_url
                                                                ? product.image_url
                                                                : require('@/assets/images/default-product-img.png'),"unavailable":"","vendor":product.vendor,"cardname":product.name,"price":product.item_price,"requiredDomain":_vm.requiredDomain}}):_c('NewCard',{attrs:{"id":product.id,"img":product.image_url
                                                                ? product.image_url
                                                                : require('@/assets/images/default-product-img.png'),"available":"","threeDavailable":product.is_3d_available,"vendor":product.vendor,"cardname":product.name,"price":product.item_price,"quantity":product.active_quantity,"requiredDomain":_vm.requiredDomain}}),_c('div',{staticClass:"dropdown"},[_c('router-link',{attrs:{"to":{
                                                                name:
                                                                    'ProductDetailsPage',
                                                                params: {
                                                                    id:
                                                                        product.id,
                                                                },
                                                            },"target":"_blank"}},[_c('div',{staticClass:"product-name"},[_c('p',{staticClass:"product-name-text"},[_vm._v(" "+_vm._s(product.name.slice( 0, 22 ))+" "),(
                                                                            product
                                                                                .name
                                                                                .length >
                                                                                22
                                                                        )?_c('span',[_vm._v(" ... ")]):_vm._e()]),_c('svg',{staticClass:"info-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"13","height":"13","viewBox":"0 0 13 13"}},[_c('g',{attrs:{"id":"Group_1750","data-name":"Group 1750","transform":"translate(-827 -350.9)"}},[_c('g',{staticClass:"cls-1",attrs:{"id":"Ellipse_546","data-name":"Ellipse 546","transform":"translate(827 350.9)"}},[_c('circle',{staticClass:"cls-3",attrs:{"cx":"6.5","cy":"6.5","r":"6.5"}}),_c('circle',{staticClass:"cls-4",attrs:{"cx":"6.5","cy":"6.5","r":"6.05"}})]),_c('g',{attrs:{"id":"noun-info-2096291","transform":"translate(832.019 354.103)"}},[_c('path',{staticClass:"cls-2",attrs:{"id":"Path_2036","data-name":"Path 2036","d":"M227.816,3.146l-.1.067h0a.182.182,0,0,0-.051.252l.012.019c.039.059.118.067.172.081a.566.566,0,0,1,.134.023.357.357,0,0,1,.186.148.437.437,0,0,1,.007.323c-.143.586-.289,1.172-.429,1.76a.633.633,0,0,0,.668.84,2.683,2.683,0,0,0,1.531-.5.375.375,0,0,0,.084-.08c.091-.12.056-.211-.09-.244-.043-.01-.087-.017-.129-.029-.279-.075-.351-.2-.283-.478.145-.591.293-1.182.435-1.773a.616.616,0,0,0-.448-.8h0a1.323,1.323,0,0,0-.3-.032,2.834,2.834,0,0,0-.373.04c-.079.015-.152.031-.218.049a3.288,3.288,0,0,0-.378.127c-.075.03-.17.071-.277.124ZM229.561-.02a.935.935,0,0,0-.956.944.954.954,0,1,0,.957-.944Z","transform":"translate(-227.634 0.02)"}})])])])])]),_c('p',{staticClass:"product-price",class:_vm.requiredDomain ==
                                                                false
                                                                    ? 'blur'
                                                                    : ''},[_vm._v(" ₹ "+_vm._s(_vm.convertToIndianNumberSystem( product.item_price ))+" ")]),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"number-of-products"},[(
                                                                        product.active_quantity !=
                                                                            0
                                                                    )?_c('div',{staticClass:"remove-one",on:{"click":function($event){return _vm.checkDraft(
                                                                            {
                                                                                product: product,
                                                                                scene_id:
                                                                                    scene.id,
                                                                                operation:
                                                                                    'count',
                                                                                enable: false,
                                                                                ehd_id:
                                                                                    _vm.EHDDetails.id,
                                                                            }
                                                                        )}}},[_c('svg',{attrs:{"viewbox":"0 0 10 10"}},[_c('line',{attrs:{"x1":"6","y1":"11.5","x2":"16","y2":"11.5","fill":"none","stroke-width":"2","stroke-linecap":"round","vector-effect":"non-scaling-stroke"}})])]):_c('div',{staticClass:"disable-icon"},[_c('svg',{attrs:{"viewbox":"0 0 10 10"}},[_c('line',{attrs:{"x1":"6","y1":"11.5","x2":"16","y2":"11.5","fill":"none","stroke-width":"2","stroke-linecap":"round","vector-effect":"non-scaling-stroke"}})])]),_c('div',{staticClass:"total"},[_vm._v(" "+_vm._s(product.active_quantity)+" ")]),(
                                                                        product.active_quantity !=
                                                                            product.total_quantity
                                                                    )?_c('div',{staticClass:"add-one",on:{"click":function($event){return _vm.checkDraft(
                                                                            {
                                                                                product: product,
                                                                                scene_id:
                                                                                    scene.id,
                                                                                operation:
                                                                                    'count',
                                                                                enable: true,
                                                                                ehd_id:
                                                                                    _vm.EHDDetails.id,
                                                                            }
                                                                        )}}},[_c('svg',{attrs:{"viewbox":"0 0 10 10"}},[_c('line',{attrs:{"x1":"6","y1":"11.5","x2":"15","y2":"11.5","fill":"none","stroke-width":"2","stroke-linecap":"round","vector-effect":"non-scaling-stroke"}}),_c('line',{attrs:{"y1":"7","x1":"10.5","y2":"16","x2":"10.5","fill":"none","stroke-width":"2","stroke-linecap":"round","vector-effect":"non-scaling-stroke"}})])]):_c('div',{staticClass:"disable-icon"},[_c('svg',{attrs:{"viewbox":"0 0 10 10"}},[_c('line',{attrs:{"x1":"6","y1":"11.5","x2":"15","y2":"11.5","fill":"none","stroke-width":"2","stroke-linecap":"round","vector-effect":"non-scaling-stroke"}}),_c('line',{attrs:{"y1":"7","x1":"10.5","y2":"16","x2":"10.5","fill":"none","stroke-width":"2","stroke-linecap":"round","vector-effect":"non-scaling-stroke"}})])])]),_c('div',{staticClass:"change-remove"},[_c('div',{staticClass:"change",on:{"click":function($event){return _vm.checkDraft(
                                                                            {
                                                                                product: product,
                                                                                quantity:
                                                                                    product.total_quantity,
                                                                                scene_id:
                                                                                    scene.id,
                                                                                index: index,
                                                                                operation:
                                                                                    'change',
                                                                                ehd_id:
                                                                                    _vm.EHDDetails.id,
                                                                            }
                                                                        )}}},[_c('svg',{staticClass:"change-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"20.027","height":"14.375"}},[_c('g',{attrs:{"id":"noun_change_435217","transform":"translate(-5 -17.7)"}},[_c('path',{staticClass:"cls-1",attrs:{"id":"Path_846","data-name":"Path 846","d":"M42.453 20.148V17.7L36.4 21.772l6.053 4.072V23.2a22.127 22.127 0 0 1 2.715.134c3.382.4 4.273 2.515 4.273 2.515 0-5.163-6.987-5.7-6.987-5.7","transform":"translate(-24.413)"}}),_c('path',{staticClass:"cls-1",attrs:{"id":"Path_847","data-name":"Path 847","d":"M11.987 48.348a22.126 22.126 0 0 1-2.715-.134C5.89 47.814 5 45.7 5 45.7c0 5.163 6.987 5.7 6.987 5.7v2.448l6.053-4.072-6.053-4.076z","transform":"translate(0 -21.769)"}})])]),_c('p',[_vm._v(" Change ")])]),_c('div',{staticClass:"remove",on:{"click":function($event){return _vm.checkDraft(
                                                                            {
                                                                                product: product,
                                                                                scene_id:
                                                                                    scene.id,
                                                                                operation:
                                                                                    'toggle',
                                                                                enable: false,
                                                                                ehd_id:
                                                                                    _vm.EHDDetails.id,
                                                                            }
                                                                        )}}},[_c('svg',{staticClass:"dustbin-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"11.326","height":"14.375"}},[_c('g',{attrs:{"id":"noun_dustbin_3666639","transform":"translate(-14 -7)"}},[_c('g',{attrs:{"id":"Group_493","data-name":"Group 493","transform":"translate(14 7)"}},[_c('path',{staticClass:"cls-1",attrs:{"id":"Path_844","data-name":"Path 844","d":"m17.35 21 1.2 10.363a1.089 1.089 0 0 0 1.082.963h5.298a1.089 1.089 0 0 0 1.082-.963L27.216 21zm3.191 9.339a.106.106 0 0 1-.026 0 .219.219 0 0 1-.216-.194l-.873-7.793a.218.218 0 0 1 .433-.048l.873 7.793a.217.217 0 0 1-.191.242zm1.96-.316a.218.218 0 1 1-.436 0v-7.84a.218.218 0 0 1 .436 0zm1.766.124a.219.219 0 0 1-.216.194.106.106 0 0 1-.026 0 .217.217 0 0 1-.192-.24l.873-7.793a.218.218 0 0 1 .433.048z","transform":"translate(-16.62 -17.951)"}}),_c('path',{staticClass:"cls-1",attrs:{"id":"Path_845","data-name":"Path 845","d":"M23.8 7.871h-2.2A1.089 1.089 0 0 0 20.534 7h-1.742a1.093 1.093 0 0 0-1.067.871h-2.2A1.526 1.526 0 0 0 14 9.4a.218.218 0 0 0 .218.218h10.89a.218.218 0 0 0 .218-.218A1.526 1.526 0 0 0 23.8 7.871zm-5.009-.436h1.742a.656.656 0 0 1 .616.436h-2.974a.656.656 0 0 1 .617-.435z","transform":"translate(-14 -7)"}})])])]),_c('p',[_vm._v(" Remove ")])])])])],1)],1)}),0),(
                                                    _vm.totalProducts[index]
                                                        .removed.length != 0
                                                )?_c('p',{staticClass:"productState"},[_vm._v(" Unused Products ")]):_vm._e(),_c('div',{staticClass:"products-grid"},_vm._l((_vm.totalProducts[
                                                        index
                                                    ].removed),function(product,index){return _c('div',{key:index,staticClass:"product-in-grid"},[(
                                                            product.available ==
                                                                false
                                                        )?_c('NewCard',{attrs:{"id":product.id,"img":product.image_url
                                                                ? product.image_url
                                                                : require('@/assets/images/default-product-img.png'),"notActive":true,"threeDavailable":product.is_3d_available,"vendor":product.vendor,"unavailable":"","cardname":product.name,"quantity":product.active_quantity,"price":product.item_price,"requiredDomain":_vm.requiredDomain}}):_c('NewCard',{attrs:{"id":product.id,"img":product.image_url
                                                                ? product.image_url
                                                                : require('@/assets/images/default-product-img.png'),"notActive":true,"threeDavailable":product.is_3d_available,"vendor":product.vendor,"available":"","cardname":product.name,"quantity":product.active_quantity,"price":product.item_price,"requiredDomain":_vm.requiredDomain}}),_c('div',{staticClass:"dropdown"},[_c('router-link',{attrs:{"to":{
                                                                name:
                                                                    'ProductDetailsPage',
                                                                params: {
                                                                    id:
                                                                        product.id,
                                                                },
                                                            },"target":"_blank"}},[_c('p',{staticClass:"product-name"},[_vm._v(" "+_vm._s(product.name.slice( 0, 25 ))+" "),(
                                                                        product
                                                                            .name
                                                                            .length >
                                                                            25
                                                                    )?_c('span',[_vm._v("...")]):_vm._e()])]),_c('p',{staticClass:"product-price",class:_vm.requiredDomain ==
                                                                false
                                                                    ? 'blur'
                                                                    : ''},[_vm._v(" ₹ "+_vm._s(_vm.convertToIndianNumberSystem( product.item_price ))+" ")]),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"remove",on:{"click":function($event){return _vm.checkDraft(
                                                                        {
                                                                            product: product,
                                                                            scene_id:
                                                                                scene.id,
                                                                            operation:
                                                                                'toggle',
                                                                            enable: true,
                                                                            ehd_id:
                                                                                _vm.EHDDetails.id,
                                                                        }
                                                                    )}}},[_c('svg',{staticClass:"restore-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"13.846","height":"12"}},[_c('g',{attrs:{"data-name":"Group 733"}},[_c('path',{attrs:{"data-name":"Path 1023","d":"M10.9 11A5.949 5.949 0 0 0 5 17a6.037 6.037 0 0 0 6 6c.012 0 .007-2.309 0-2.308A3.715 3.715 0 0 1 7.051 17a3.88 3.88 0 0 1 3.849-3.913 3.816 3.816 0 0 1 3.767 3.452h-2.282l3.231 3.231 3.231-3.231h-2.075A5.936 5.936 0 0 0 10.9 11z","transform":"translate(-5 -11)"}})])]),_c('p',[_vm._v(" Restore ")])])])],1)],1)}),0)]):_c('div',{staticClass:"no-data-available"},[_vm._v(" No products available. ")])]),_c('Tab',{attrs:{"title":("Services (" + (scene.services_data.filter(
                                                    function (value) { return value.active ===
                                                        true; }
                                                ).length) + ")")}},[(
                                                scene.services_data.length >
                                                    0
                                            )?_c('div',{staticClass:"scene-services"},[_c('div',{staticClass:"services-grid"},_vm._l((_vm.totalServices[
                                                        index
                                                    ].active),function(service,index){return _c('div',{key:index +
                                                            '_' +
                                                            service.id,staticClass:"services-in-grid"},[_c('NewCard',{attrs:{"img":service.service_icon_url
                                                                ? service.service_icon_url
                                                                : require('@/assets/images/enable_service.png'),"description":service.description,"forServices":true,"cardname":service.name,"price":service.price,"requiredDomain":_vm.requiredDomain}}),_c('div',{staticClass:"dropdown-services"},[_c('p',{staticClass:"service-name"},[_vm._v(" "+_vm._s(service.name.slice( 0, 25 ))+" "),(
                                                                    service
                                                                        .name
                                                                        .length >
                                                                        25
                                                                )?_c('span',[_vm._v("...")]):_vm._e()]),_c('p',{staticClass:"service-vendor"},[_vm._v(" "+_vm._s(service.vendor)+" ")]),(
                                                                _vm.windowWidth >
                                                                    1100
                                                            )?_c('p',{staticClass:"service-vendor"},[_vm._v(" "+_vm._s(service.description.slice( 0, 55 ))+" "),(
                                                                    service
                                                                        .description
                                                                        .length >
                                                                        55
                                                                )?_c('span',[_vm._v("...")]):_vm._e()]):_c('p',{staticClass:"service-vendor"},[_vm._v(" "+_vm._s(service.description.slice( 0, 38 ))+" "),(
                                                                    service
                                                                        .description
                                                                        .length >
                                                                        38
                                                                )?_c('span',[_vm._v("...")]):_vm._e()]),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"remove",on:{"click":function($event){return _vm.checkDraft(
                                                                        {
                                                                            operation:
                                                                                'service',
                                                                            service_id:
                                                                                service.id,
                                                                            scene_id:
                                                                                scene.id,
                                                                            enable: false,
                                                                            ehd_id:
                                                                                _vm.EHDDetails.id,
                                                                        }
                                                                    )}}},[_c('svg',{staticClass:"dustbin-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"11.326","height":"14.375"}},[_c('g',{attrs:{"id":"noun_dustbin_3666639","transform":"translate(-14 -7)"}},[_c('g',{attrs:{"id":"Group_493","data-name":"Group 493","transform":"translate(14 7)"}},[_c('path',{staticClass:"cls-1",attrs:{"id":"Path_844","data-name":"Path 844","d":"m17.35 21 1.2 10.363a1.089 1.089 0 0 0 1.082.963h5.298a1.089 1.089 0 0 0 1.082-.963L27.216 21zm3.191 9.339a.106.106 0 0 1-.026 0 .219.219 0 0 1-.216-.194l-.873-7.793a.218.218 0 0 1 .433-.048l.873 7.793a.217.217 0 0 1-.191.242zm1.96-.316a.218.218 0 1 1-.436 0v-7.84a.218.218 0 0 1 .436 0zm1.766.124a.219.219 0 0 1-.216.194.106.106 0 0 1-.026 0 .217.217 0 0 1-.192-.24l.873-7.793a.218.218 0 0 1 .433.048z","transform":"translate(-16.62 -17.951)"}}),_c('path',{staticClass:"cls-1",attrs:{"id":"Path_845","data-name":"Path 845","d":"M23.8 7.871h-2.2A1.089 1.089 0 0 0 20.534 7h-1.742a1.093 1.093 0 0 0-1.067.871h-2.2A1.526 1.526 0 0 0 14 9.4a.218.218 0 0 0 .218.218h10.89a.218.218 0 0 0 .218-.218A1.526 1.526 0 0 0 23.8 7.871zm-5.009-.436h1.742a.656.656 0 0 1 .616.436h-2.974a.656.656 0 0 1 .617-.435z","transform":"translate(-14 -7)"}})])])]),_c('p',[_vm._v(" Remove ")])])])])],1)}),0),(
                                                    _vm.totalServices[index]
                                                        .removed.length != 0
                                                )?_c('p',{staticClass:"serviceState"},[_vm._v(" Unused Services ")]):_vm._e(),_c('div',{staticClass:"services-grid"},_vm._l((_vm.totalServices[
                                                        index
                                                    ].removed),function(service,index){return _c('div',{key:index +
                                                            '_' +
                                                            service.id,staticClass:"services-in-grid"},[_c('NewCard',{attrs:{"img":service.service_icon_url
                                                                ? service.service_icon_url
                                                                : require('@/assets/images/disable_service.png'),"description":service.description,"forServices":true,"notActive":true,"cardname":service.name,"price":service.price,"requiredDomain":_vm.requiredDomain}}),_c('div',{staticClass:"dropdown-services"},[_c('p',{staticClass:"service-name"},[_vm._v(" "+_vm._s(service.name.slice( 0, 25 ))+" "),(
                                                                    service
                                                                        .name
                                                                        .length >
                                                                        25
                                                                )?_c('span',[_vm._v("...")]):_vm._e()]),_c('p',{staticClass:"service-vendor"},[_vm._v(" "+_vm._s(service.vendor)+" ")]),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"remove",on:{"click":function($event){return _vm.checkDraft(
                                                                        {
                                                                            operation:
                                                                                'service',
                                                                            service_id:
                                                                                service.id,
                                                                            scene_id:
                                                                                scene.id,
                                                                            enable: true,
                                                                            ehd_id:
                                                                                _vm.EHDDetails.id,
                                                                        }
                                                                    )}}},[_c('svg',{staticClass:"restore-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"13.846","height":"12"}},[_c('g',{attrs:{"data-name":"Group 733"}},[_c('path',{attrs:{"data-name":"Path 1023","d":"M10.9 11A5.949 5.949 0 0 0 5 17a6.037 6.037 0 0 0 6 6c.012 0 .007-2.309 0-2.308A3.715 3.715 0 0 1 7.051 17a3.88 3.88 0 0 1 3.849-3.913 3.816 3.816 0 0 1 3.767 3.452h-2.282l3.231 3.231 3.231-3.231h-2.075A5.936 5.936 0 0 0 10.9 11z","transform":"translate(-5 -11)"}})])]),_c('p',[_vm._v(" Restore ")])])])])],1)}),0)]):_c('div',{staticClass:"no-data-available"},[_vm._v(" No services available. ")])]),(
                                            scene.one_time_custom_product
                                                .length > 0
                                        )?_c('Tab',{attrs:{"title":("Custom Products(" + (scene.one_time_custom_product.filter(
                                                    function (value) { return value.active ===
                                                        true; }
                                                ).length) + ")")}},[(
                                                scene
                                                    .one_time_custom_product
                                                    .length > 0
                                            )?_c('div',{staticClass:"scene-services"},[_c('div',{staticClass:"services-grid"},_vm._l((_vm.totalCustomProducts[
                                                        index
                                                    ].active),function(customProduct,index){return _c('div',{key:index +
                                                            '_' +
                                                            customProduct.id,staticClass:"services-in-grid"},[_c('NewCard',{attrs:{"img":customProduct.img_url
                                                                ? customProduct.img_url
                                                                : require('@/assets/images/custom-product.png'),"description":customProduct.description,"forServices":true,"cardname":customProduct.name,"price":customProduct.price,"requiredDomain":_vm.requiredDomain}}),_c('div',{staticClass:"dropdown-services"},[_c('p',{staticClass:"service-name"},[_vm._v(" "+_vm._s(customProduct.name.slice( 0, 25 ))+" "),(
                                                                    customProduct
                                                                        .name
                                                                        .length >
                                                                        25
                                                                )?_c('span',[_vm._v("...")]):_vm._e()]),_c('p',{staticClass:"service-vendor"},[_vm._v(" "+_vm._s(customProduct.vendor)+" ")]),(
                                                                _vm.windowWidth >
                                                                    1100
                                                            )?_c('p',{staticClass:"service-vendor"},[_vm._v(" "+_vm._s(customProduct.description.slice( 0, 55 ))+" "),(
                                                                    customProduct
                                                                        .description
                                                                        .length >
                                                                        55
                                                                )?_c('span',[_vm._v("...")]):_vm._e()]):_c('p',{staticClass:"service-vendor"},[_vm._v(" "+_vm._s(customProduct.description.slice( 0, 38 ))+" "),(
                                                                    customProduct
                                                                        .description
                                                                        .length >
                                                                        38
                                                                )?_c('span',[_vm._v("...")]):_vm._e()]),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"remove",on:{"click":function($event){return _vm.checkDraft(
                                                                        {
                                                                            operation:
                                                                                'customProduct',
                                                                            custom_product_id:
                                                                                customProduct.prod_id,
                                                                            scene_id:
                                                                                scene.id,
                                                                            enable: false,
                                                                            ehd_id:
                                                                                _vm.EHDDetails.id,
                                                                        }
                                                                    )}}},[_c('svg',{staticClass:"dustbin-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"11.326","height":"14.375"}},[_c('g',{attrs:{"id":"noun_dustbin_3666639","transform":"translate(-14 -7)"}},[_c('g',{attrs:{"id":"Group_493","data-name":"Group 493","transform":"translate(14 7)"}},[_c('path',{staticClass:"cls-1",attrs:{"id":"Path_844","data-name":"Path 844","d":"m17.35 21 1.2 10.363a1.089 1.089 0 0 0 1.082.963h5.298a1.089 1.089 0 0 0 1.082-.963L27.216 21zm3.191 9.339a.106.106 0 0 1-.026 0 .219.219 0 0 1-.216-.194l-.873-7.793a.218.218 0 0 1 .433-.048l.873 7.793a.217.217 0 0 1-.191.242zm1.96-.316a.218.218 0 1 1-.436 0v-7.84a.218.218 0 0 1 .436 0zm1.766.124a.219.219 0 0 1-.216.194.106.106 0 0 1-.026 0 .217.217 0 0 1-.192-.24l.873-7.793a.218.218 0 0 1 .433.048z","transform":"translate(-16.62 -17.951)"}}),_c('path',{staticClass:"cls-1",attrs:{"id":"Path_845","data-name":"Path 845","d":"M23.8 7.871h-2.2A1.089 1.089 0 0 0 20.534 7h-1.742a1.093 1.093 0 0 0-1.067.871h-2.2A1.526 1.526 0 0 0 14 9.4a.218.218 0 0 0 .218.218h10.89a.218.218 0 0 0 .218-.218A1.526 1.526 0 0 0 23.8 7.871zm-5.009-.436h1.742a.656.656 0 0 1 .616.436h-2.974a.656.656 0 0 1 .617-.435z","transform":"translate(-14 -7)"}})])])]),_c('p',[_vm._v(" Remove ")])])])])],1)}),0),(
                                                    _vm.totalCustomProducts[
                                                        index
                                                    ].removed.length != 0
                                                )?_c('p',{staticClass:"serviceState"},[_vm._v(" Unused Custom Products ")]):_vm._e(),_c('div',{staticClass:"services-grid"},_vm._l((_vm.totalCustomProducts[
                                                        index
                                                    ].removed),function(customProduct,index){return _c('div',{key:index +
                                                            '_' +
                                                            customProduct.id,staticClass:"services-in-grid"},[_c('NewCard',{attrs:{"img":customProduct.img_url
                                                                ? customProduct.img_url
                                                                : require('@/assets/images/custom-product.png'),"description":customProduct.description,"forServices":true,"notActive":true,"cardname":customProduct.name,"price":customProduct.price,"requiredDomain":_vm.requiredDomain}}),_c('div',{staticClass:"dropdown-services"},[_c('p',{staticClass:"service-name"},[_vm._v(" "+_vm._s(customProduct.name.slice( 0, 25 ))+" "),(
                                                                    customProduct
                                                                        .name
                                                                        .length >
                                                                        25
                                                                )?_c('span',[_vm._v("...")]):_vm._e()]),_c('p',{staticClass:"service-vendor"},[_vm._v(" "+_vm._s(customProduct.vendor)+" ")]),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"remove",on:{"click":function($event){return _vm.checkDraft(
                                                                        {
                                                                            operation:
                                                                                'customProduct',
                                                                            custom_product_id:
                                                                                customProduct.prod_id,
                                                                            scene_id:
                                                                                scene.id,
                                                                            enable: true,
                                                                            ehd_id:
                                                                                _vm.EHDDetails.id,
                                                                        }
                                                                    )}}},[_c('svg',{staticClass:"restore-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"13.846","height":"12"}},[_c('g',{attrs:{"data-name":"Group 733"}},[_c('path',{attrs:{"data-name":"Path 1023","d":"M10.9 11A5.949 5.949 0 0 0 5 17a6.037 6.037 0 0 0 6 6c.012 0 .007-2.309 0-2.308A3.715 3.715 0 0 1 7.051 17a3.88 3.88 0 0 1 3.849-3.913 3.816 3.816 0 0 1 3.767 3.452h-2.282l3.231 3.231 3.231-3.231h-2.075A5.936 5.936 0 0 0 10.9 11z","transform":"translate(-5 -11)"}})])]),_c('p',[_vm._v(" Restore ")])])])])],1)}),0)]):_c('div',{staticClass:"no-data-available"},[_vm._v(" No custom products available. ")])]):_vm._e(),(
                                            scene.scene_info != '' &&
                                                scene.scene_info != null
                                        )?_c('Tab',{attrs:{"title":"Info "}},[_c('div',{staticClass:"info-tab"},[_c('div',{domProps:{"innerHTML":_vm._s(scene.scene_info)}})])]):_vm._e()],1),(
                                        _vm.roomsAvailable[index].scene ==
                                            scene.id &&
                                            _vm.roomsAvailable[index]
                                                .room_available == false
                                    )?_c('div',{staticClass:"room-unavailable"},[_c('div',{staticClass:"explore-designs-banner"},[_c('p',[_vm._v("Loved Siloho kitchen?")]),_c('p',[_vm._v("Furnish rest of the house.")]),_c('Button',{staticClass:"add-rooms-btn",attrs:{"name":"Add All Rooms","primary":""},nativeOn:{"click":function($event){return _vm.addAllRooms(_vm.EHDDetails.id)}}})],1)]):_vm._e()],1)])})],2)],1)]:_vm._e(),(_vm.showNotFound == true)?[_c('NotFound',{attrs:{"data":'EHD',"content":_vm.content}})]:_vm._e(),(_vm.showModal)?_c('FullPageNavModal',{attrs:{"sceneId":_vm.currentSceneId,"ehdId":_vm.ehdId,"requiredDomain":_vm.requiredDomain},on:{"close":_vm.toggleFullPageModal,"update":_vm.updateEHD}}):_vm._e(),(_vm.replaceProductModal == true)?_c('FullPageModal',{staticClass:"replace-product-modal",attrs:{"replaceProduct":true},on:{"close":_vm.toggleReplaceProductModal,"loadMoreProducts":_vm.loadMoreProducts}},[_c('div',{staticClass:"productlist-layout"},[_c('div',{staticClass:"sidebar"},[_c('div',{staticClass:"sidebar-item"},[(_vm.currentProductShow == true)?_c('div',{staticClass:"current-product"},[_c('router-link',{attrs:{"to":{
                                    name: 'ProductDetailsPage',
                                    params: {
                                        id: _vm.productID,
                                    },
                                }}},[_c('CurrentProduct',{attrs:{"img":_vm.productImage
                                            ? _vm.productImage
                                            : require('@/assets/images/default-product-img.png'),"productName":_vm.productName,"requiredDomain":_vm.requiredDomain,"productPrice":_vm.productPrice,"description":_vm.productDescription}})],1)],1):_vm._e(),(
                                _vm.add == false &&
                                    _vm.filters[1].options.length != 0
                            )?_c('div',{staticClass:"filters"},[(_vm.windowWidth > 1100)?_c('FiltersBox',{attrs:{"filters":_vm.filters},on:{"filter":_vm.getFilteredProduct}}):_vm._e()],1):_vm._e()])]),_c('div',{staticClass:"main"},[(_vm.relatedProducts)?[_c('div',{staticClass:"productlist-head"},[_c('h1',{},[_vm._v(" "+_vm._s(_vm.add == true ? 'Add product' : 'Replace with')+" ")]),_c('SearchAutoComplete',{attrs:{"suggestionsArr":_vm.suggestions},on:{"search":_vm.fetchSuggestions,"loadMoreSearchOptions":_vm.loadMoreSearchOptions},scopedSlots:_vm._u([{key:"suggestion",fn:function(ref){
                            var suggestion = ref.suggestion;
return [_c('div',{staticClass:"autocomplete-product"},[_c('div',{staticClass:"autocomplete-product-img"},[_c('img',{attrs:{"src":suggestion.image}})]),_c('div',{staticClass:"autocomplete-product-detail"},[_c('p',[_vm._v(_vm._s(suggestion.name))]),_c('div',{staticClass:"autocomplete-category"},[_c('span',[_vm._v(" "+_vm._s(suggestion.item_category)+" ")]),(
                                                        suggestion.item_subcategory
                                                    )?[_c('span',[_c('svg',{attrs:{"width":"25","height":"24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M10.283 6 8.875 7.41 13.449 12l-4.574 4.59L10.283 18l5.992-6-5.992-6z","fill":"#000"}})])]),_c('span',[_vm._v(" "+_vm._s(suggestion.item_subcategory)+" ")])]:_vm._e()],2)]),_c('div',{staticClass:"autocomplete-product-action"},[(_vm.add == true)?[_c('Button',{staticClass:"autocomplete-add",attrs:{"name":"Add","primary":""},nativeOn:{"click":function($event){return _vm.addProductForEHD(
                                                            suggestion
                                                        )}}})]:[_c('Button',{staticClass:"autocomplete-add",attrs:{"name":"Replace","primary":""},nativeOn:{"click":function($event){return _vm.changeProductForEHD(
                                                            suggestion
                                                        )}}})]],2)])]}}],null,false,4190671109)})],1),_c('div',{staticClass:"cards-container"},[(_vm.relatedProducts.payload.prod_data)?_vm._l((_vm.relatedProducts
                                        .payload.prod_data),function(product){return _c('div',{key:product.id},[(_vm.add == true)?_c('ProductCards',{attrs:{"threeDavailable":product.is_3d_available,"product-obj":product,"vendor":product.vendor,"dimRemarks":product.dim_remarks,"product-id":product.product_id,"img":product.image_meta
                                                ? product.image_meta.n_image
                                                : require('@/assets/images/default-product-img.png'),"name":product.name,"current-price":product.item_price,"requiredDomain":_vm.requiredDomain,"bestSeller":product.bestseller,"recommended":product.recommended},on:{"add":function($event){return _vm.addProductForEHD(product)}}}):_c('ProductCards',{attrs:{"threeDavailable":product.is_3d_available,"product-obj":product,"replace":"","vendor":product.vendor,"dimRemarks":product.dim_remarks,"product-id":product.product_id,"img":product.image_meta
                                                ? product.image_meta.n_image
                                                : require('@/assets/images/default-product-img.png'),"name":product.name,"current-price":product.item_price,"requiredDomain":_vm.requiredDomain,"bestSeller":product.bestseller,"recommended":product.recommended},on:{"replace":function($event){return _vm.changeProductForEHD(product)}}})],1)}):_vm._l((_vm.similarProducts),function(product){return _c('div',{key:product.id},[(_vm.add == true)?_c('ProductCards',{attrs:{"threeDavailable":product.is_3d_available,"product-obj":product,"vendor":product.vendor,"product-id":product.product_id,"dimRemarks":product.dim_remarks,"img":product.image_meta
                                                ? product.image_meta.n_image
                                                : require('@/assets/images/default-product-img.png'),"name":product.name,"current-price":product.item_price,"requiredDomain":_vm.requiredDomain,"bestSeller":product.bestseller,"recommended":product.recommended},on:{"add":function($event){return _vm.addProductForEHD(product)}}}):_c('ProductCards',{attrs:{"threeDavailable":product.is_3d_available,"product-obj":product,"replace":"","vendor":product.vendor,"dimRemarks":product.dim_remarks,"product-id":product.product_id,"img":product.image_meta
                                                ? product.image_meta.n_image
                                                : require('@/assets/images/default-product-img.png'),"name":product.name,"current-price":product.item_price,"requiredDomain":_vm.requiredDomain,"bestSeller":product.bestseller,"recommended":product.recommended},on:{"replace":function($event){return _vm.changeProductForEHD(product)}}})],1)})],2),_c('div',{staticClass:"loader-container"})]:[_c('div',{staticClass:"no-products-found",staticStyle:{"text-align":"center"}},[_c('img',{attrs:{"src":require("@/assets/images/no-results-found.png")}}),_c('p',[_vm._v("No Products Available")])])]],2)]),_c('div',{staticClass:"request-product-container"},[_c('RequestProduct')],1)]):_vm._e(),(_vm.draftModal)?_c('Modal',{attrs:{"transparent":""},on:{"close":_vm.closeDraftModal}},[_c('div',{staticClass:"ehdmodal"},[_c('div',{staticClass:"ehdmodal-view"},[_c('p',[_vm._v(_vm._s(_vm.draftModalMsg))]),(_vm.showDraftVersions == true)?_c('div',{staticClass:"ehd-display"},[(_vm.useWorkspaceComp == false)?[_c('div',{staticClass:"draft-ehd"},[_c('p',[_vm._v("My Draft")]),_c('div',{staticClass:"draft-details"},[_c('img',{attrs:{"src":_vm.draftDetails.draft_scene
                                                .scene_image}}),_c('div',{staticClass:"draft-info"},[_c('p',{class:_vm.requiredDomain == false
                                                    ? 'blur'
                                                    : ''},[_vm._v(" ₹"+_vm._s(_vm.convertToIndianNumberSystem( _vm.draftDetails.draft_scene .price ))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.draftDetails.draft_scene .no_of_products)+" Products ")]),_c('p',[_vm._v(" "+_vm._s(_vm.draftDetails.draft_scene .no_of_services)+" Services ")])])])]),_c('div',{staticClass:"original-ehd"},[_c('p',[_vm._v("Original")]),_c('div',{staticClass:"draft-details"},[_c('img',{attrs:{"src":_vm.draftDetails.original_scene
                                                .scene_image}}),_c('div',{staticClass:"draft-info"},[_c('p',{class:_vm.requiredDomain == false
                                                    ? 'blur'
                                                    : ''},[_vm._v(" ₹"+_vm._s(_vm.convertToIndianNumberSystem( _vm.draftDetails .original_scene .price ))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.draftDetails.original_scene .no_of_products)+" Products ")]),_c('p',[_vm._v(" "+_vm._s(_vm.draftDetails.original_scene .no_of_services)+" Services ")])])])])]:[_c('div',[_c('WorkspaceComponent',{attrs:{"draftImage":_vm.draftDetails.draft_scene.scene_image,"theme":_vm.draftDetails.draft_scene.theme_name,"ogDetails":_vm.draftDetails.original_scene,"price":_vm.draftDetails.draft_scene.price,"footerName":_vm.draftDetails.draft_scene
                                            .display_name,"noOfProducts":_vm.draftDetails.draft_scene
                                            .no_of_products,"noOfServices":_vm.draftDetails.draft_scene
                                            .no_of_services,"space":_vm.draftDetails.draft_scene.space_name,"eta":_vm.draftDetails.draft_scene.ETA,"products":_vm.draftDetails.draft_scene
                                            .no_of_products,"timeCreated":_vm.draftDetails.draft_scene.updated_at,"sceneid":_vm.draftDetails.draft_scene.scene_id,"ehdid":_vm.draftDetails.draft_scene
                                            .draft_ehd_id
                                            ? _vm.draftDetails.draft_scene
                                                  .draft_ehd_id
                                            : null,"draftBySpace":true,"hideButtons":true}})],1)]],2):_vm._e(),(_vm.showDraftVersions == true)?_c('div',{staticClass:"result-button",staticStyle:{"margin-top":"20px","display":"flex","justify-content":"center"}},[_c('Button',{staticClass:"delete-draft",attrs:{"name":"Continue","outline":""},nativeOn:{"click":function($event){return _vm.continueWithDraft($event)}}}),_c('Button',{staticClass:"override",staticStyle:{"margin-left":"20px"},attrs:{"name":"Restore","primary":""},nativeOn:{"click":function($event){return _vm.restoreDraft($event)}}})],1):_vm._e()])])]):_vm._e(),(_vm.loaderShow)?[_c('div',{staticClass:"wait"},[_c('Loader',{attrs:{"loaderType":_vm.loaderType}})],1)]:_vm._e()]:_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"details"},[_c('div',{staticClass:"detail-heading"},[_vm._v(" Estimated Completion ")]),_c('div',{staticClass:"detail-subheading"},[_vm._v(" 4 Weeks ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"details"},[_c('div',{staticClass:"detail-heading"},[_vm._v(" Estimated Completion ")]),_c('div',{staticClass:"detail-subheading"},[_vm._v(" 3-5 Weeks ")])])}]

export { render, staticRenderFns }